import { html, css, formCss } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import config from './config.js';
import validationCss from './validation.css.js';
import { Lang, Fetcher, Session } from 'Utils';

import './harfang-threats.js';

class EdrInfo extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      formCss,
      validationCss,
      css`
        .marginbottom {
          margin-bottom:10px;
        }

        .bold {
          font-weight: bold;
        }

        sl-tab {
          position:relative;
        }

        sl-tab m-icon {
          position: absolute;
          right: -4px;
          top: 5px;
        }

        table {
          font-size:0.9em;
        }

        table th {
          text-align:left;
        }

        table m-icon {
          font-size:1.8em;
        }

        table td {
          vertical-align: top;
        }

        label {
          padding-left:10px;
          font-size:0.9em;
        }

        .feature {
          font-size:0.85em;
          margin-left:10px;
          margin-top:5px;
        }

        .title {
        }

        .description {
          margin-bottom:10px;
        }

        .spacer {
          height:5px;
        }
      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            close:'Close',
            tabs:{
              infos:'Informations',
              threats:'Threats',
              history:'History',
              debug:'Debug',
              action:'Actions'
            },
            status:{
              unknow:'Status unknown',
            },
            refresh:'Refresh',
          },
        },
        french:{
          translation: {
            close:'Fermer',
            tabs:{
              infos:'Informations',
              threats:'Threats',
              history:'Historique',
              debug:'Debug',
              action:'Actions'
            },
            status:{
              unknow:'Status inconnu',
            },
            refresh:'Rafraîchir',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.item = null;
    this.viewOnly = true;
    this.urlVar = 'idu';
  }

  getItemTitle(opts = {}) {
    return this.item.name;
  }

  renderStatus() {
    if (!this.item.status) {
      return html`<box-styled variant="warning">${this._tl('status.unknow')}</box-styled>`;
    }

    if (this.item.status.lastStatus === 'error') {
      return html`<box-styled variant="error" micon="sentiment_very_dissatisfied">${this.item.status.lastError}</box-styled>`;
    }

    if (this.item.status.lastStatus === 'warning') {
      return html`<box-styled variant="error" micon="sentiment_dissatisfied">${this.item.status.lastError}</box-styled>`;
    }

    if (this.item.status.lastStatus === 'ok') {
      return html`<box-styled variant="info" micon="sentiment_satisfied">Up, version ${this.item.status.version.version}</box-styled>`;
    }
  }

  renderNotices() {
    if (!this.item.status) {
      return html`<label>N/A</label>`;
    }

    if (!this.item.status.notices) {
      return html`<label>Pas de message de maintenance particulier.</label>`;
    }

    if (Array.isArray(this.item.status.notices)) {
      this.item.status.notices = this.item.status.notices.join('<br/>');
    }

    return html`<box-styled variant="warning">${this.item.status.notices}</box-styled>`;
  }

  renderFeatures() {
    if (!this.item.status) {
      return html`<label>N/A</label>`;
    }
    
    if (!this.item.status.features) {
      return html`<label>Pas de nouvelle fonctionnalités annoncées.</label>`;
    }    

    return html`${this.item.status.features.map(f => html`
      <div class="feature">
        <div class="title"><b>v${f.release} | ${f.title}</b> (${f.creation_date})</div>
        <div class="description">${f.description}</div>
      </div>
    `)}`;
  }

  renderStatusTitle() {
    if (!this.item.status) {
      return html`Status`;
    }

    return html`Status (${Lang.formatDate(this.item.status.lastUpdated, 'fulls')})`;
  }

  showModal(device, action) {
    this.device = device;
    this.action = action;
    this.modalAction = this.modalAction || this.qs('#modal-dialog-validation');

    this.modalAction.qs('p').innerHTML = this._tl(`${action}_${device}_html`);
    this.modalAction.qs('sl-button[slot="bt2"]').textContent = this._tl(action);
    this.modalAction.label = this._tl(`${action}_${device}_label`);
    this.modalAction.show();
  }

  async refreshStatus() {
    const bt = this.qs('#refreshStatus');
    if (bt.loading) return;
    bt.loading = true;
    const url = `private/registry/tree/${this.item._id}/edr/refresh/status`;
    const response = await Fetcher.put(url);
    bt.loading = false;
    if (response && response.ok) {
      this.item = response.data;
      window.dispatchEvent(new CustomEvent(config.eventUpdated, { detail: response.data }));
    }
    
  }

  render() {
    if (!this.item) return '';
    return html`
      <modal-drawer modal large>
        <style type="text/css">${this.constructor.styles}</style>
        <sl-tab-group>
          <sl-tab slot="nav" panel="infos" class="bold">${this._tl('tabs.infos')}</sl-tab>
          <sl-tab slot="nav" panel="threats" class="bold">${this._tl('tabs.threats')}</sl-tab>
          <!--<sl-tab slot="nav" panel="history" class="bold">${this._tl('tabs.history')}</sl-tab> -->
          ${Session.isDev() 
            ? html`<sl-tab slot="nav" panel="debug" class="bold">${this._tl('tabs.debug')}</sl-tab>`
            : ''
          }

          <sl-tab-panel name="infos">
            <section-header size="small" bold>
              ${this.renderStatusTitle()}
              <m-icon slot="right" variant="button" name="sync" id="refreshStatus" title="${this._tl('refresh')}" @click="${this.refreshStatus}"></m-icon>
            </section-header>
            
            <scrollable-component scrollbar-visibility="always">
              <div class="spacer"></div>
              ${this.renderStatus()}<br/>

              <section-header size="small" bold>Message de maintenance</section-header>
              ${this.renderNotices()}<br/><br/>

              <section-header size="small" bold>Annonce de fonctionnalités</section-header>
              ${this.renderFeatures()}<br/><br/>
            </scrollable-component>

          </sl-tab-panel>

          <sl-tab-panel name="threats">
            <harfang-threats url=${this.item.url} nodeId=${this.item._id}></harfang-threats>
          </sl-tab-panel>

          <sl-tab-panel name="history">
            <box-styled variant="warning">Pas encore implémenté</box-styled>
          </sl-tab-panel>

          <sl-tab-panel name="debug">
            <box-json .value=${this.item}></box-json>
          </sl-tab-panel>

        </sl-tab-group>

        <sl-button slot="bt2" variant="text" close="true">${this._tl('close')}</sl-button>
      </modal-drawer>

      <modal-dialog id="modal-dialog-validation">
        <p id="modal-dialog-validation-text">
        </p>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.run}></sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('registry-node-edr-info', EdrInfo);

