import { BaseElement, html, css } from 'Elements';
import { Fetcher } from 'Utils';

class CveResume extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
        }

        cve-score {
          font-size:0.8em;
          margin-top:5px;
        }

        .text {
          margin:10px;
          margin-bottom:15px;
          font-size:0.9em;
        }

        a {
          color:var(--sl-color-primary-800);
          text-decoration:none;
        }

        a:hover {
          text-decoration:underline;
        }
      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      cveId: { type: String, attribute: 'cve-id' },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
          },
        },
        french:{
          translation: {
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.loading = true;
    this.cveId = '';
  }

  updated(changedProperties) {    
    if (changedProperties.has('cveId')) {
      this.loadCveData();
    }
  }

  async loadCveData() {
    this.loading = true;
    const response = await Fetcher.get(`public/data/cve/${this.cveId}`);
    this.loading = false;
    if (!response || !response.data) return;
    this.record = response.data;
  }

  render() {
    if (this.loading) return '';
    if (!this.record) return ''; 
    return html`
      <div>
        <section-header size="small">
          <b><a href="https://helix01fr.sysdream.io/cve/detail?cveId=${this.cveId}" target="_blank">${this.cveId}</a></b>
          <span slot="right" class="flex">
            <cve-score score="${this.record.lastCvssScore}" variant="text" version="${this.record.lastCvssVersion}"></cve-score>
          </span>
        </section-header>
        <div class="text">
          ${this.record.nvd.cve.descriptions[0].value}
        </div>
      </div>
    `;
  }

}

customElements.define('cve-resume', CveResume);