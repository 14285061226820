import { PageElement, html, css, formCss } from 'Elements';
import { Session, UrlParams } from 'Utils';

import './table.js';
import './filters.js';
import 'Components/common/data-search-toolbar.js';

class EventsPage extends PageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        sl-badge {
          font-size:0.5em;
          position:absolute;
          top:5px;
          margin-left:10px;
          cursor:pointer;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Logs',
          }
        },
        french:{
          translation: {
            title:'Logs',
          },
        }
      }
    ]
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      newEvents: { type: Number },
      groupBy: { type: String },
    };
  }

  constructor() {
    super();
    this.layout = 'large';
    this.newEvents = 0;
    this.onNewEvent = this.onNewEvent.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.groupBy = '';
  }
  
  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('system-logs-add', this.onNewEvent);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('system-logs-add', this.onNewEvent);
  }

  onNewEvent(ev) {
    const data = ev.detail;
    const levels = UrlParams.get('level' ,'info,error,warn,debug');
    if (levels.includes(data.level)) {
      this.newEvents+=1;
    }
    this.table = this.table || this.qs('sys-logs-table');
    this.table.pushData(data);
  }

  reloadData() {
    this.newEvents = 0;
    this.table = this.table || this.qs('sys-logs-table');
    this.table.reloadData();
  }

  render() {    
    return html`
      <section-header backroute="../../" micon="calendar_clock">
        <div style="position:relative;">
          ${this._tl('title')}
          ${this.newEvents
            ? html`<sl-badge variant="primary" pill pulse @click=${this.reloadData}>${this.newEvents}</sl-badge>`
            : ''
          }
        </div>
      </section-header>

      <sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar>

      <data-search-toolbar period-filter group-by-icon .parent=${this}>
        <sys-logs-filters .parent=${this}></sys-logs-filters>
      </data-search-toolbar>

      <sys-logs-table groupBy=${this.groupBy} .parent=${this}></sys-logs-table>
    `;
  }
}

customElements.define('page-sys-logs', EventsPage);
