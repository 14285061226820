import { BaseElement, html, css } from 'Elements';
import { Ollama } from 'ollama/browser';
import { Fetcher, Sleep } from 'Utils';

class LlmConversationList extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          display: block;
          padding: 1rem;
          flex: 1;
          max-height:55vh;
          overflow: hidden;
          height:100%;
        }
      `
    ]
  }

  /*
  static get properties() {
    return { messages: { type: Array } }
  }
  */

  constructor() {
    super();
    this.messages = [];
    this.userHasScrolled = false;  // État pour suivre si l'utilisateur a fait défiler manuellement
  }

  async addMessage(msg) {
    this.messages.push(msg);
    await this.requestUpdate();
    return this.qs('llm-chat-message:last-child')
  }

  async reset() {
    this.messages = [];
    await this.requestUpdate();
  }
  
  visibleCallback() {
    super.visibleCallback();
    if (this.scroller) return;

    this.scroller = this.qs('scrollable-component');
    this.scroller.viewport.addEventListener('mousewheel', () => {
      const h = Math.round(this.scroller.viewport.scrollHeight - this.scroller.viewport.scrollTop);
      const max = this.scroller.viewport.clientHeight + 50;
      const isAtBottom = h <= max;
      if (!isAtBottom) {
        this.userHasScrolled = true;
      } else if (isAtBottom) {
        this.userHasScrolled = false;
      }
    });
  }

  updateScrollPosition() {
    this.scroller = this.scroller || this.qs('scrollable-component');
    //const isAtBottom = this.scroller.viewport.scrollHeight - this.scroller.viewport.scrollTop <= this.scroller.viewport.clientHeight;

    //if (!this.userHasScrolled || isAtBottom) {
      this.scroller.viewport.scrollTo({
        top: this.scroller.viewport.scrollHeight,
        behavior: 'smooth'
      });
    //}
  }

  render() {
    return html`
      <scrollable-component style="height:100%;">
      ${this.messages.map(
        (msg) => html`
          <llm-chat-message
            .role=${msg.role}
            .content=${msg.content}
            .timestamp=${msg.timestamp ?? Date.now()}
            .status=${msg.status}
          ></llm-chat-message>
        `
      )}
      </scrollable-component>
    `;
  }
}

customElements.define('llm-conversation-list', LlmConversationList);


class LlmChatMessage extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
          margin: 0.5rem 0;
          margin-right:1rem;
          font-size: 0.9em;
          position:relative;
        }
        
        .message {
          padding: 0.75rem 1rem;
          border-radius: 0.5rem;
          max-width: 80%;
          word-wrap: break-word;
          position: relative;
          overflow: hidden;
          height:auto;
          transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
        }

        .user {
          background-color: var(--sl-color-neutral-0);  
          margin-left: auto;
        }

        .assistant {
          background-color: var(--sl-color-neutral-100);  
          margin-right: auto;
        }

        .system {
          font-style: italic;
          color: #666;
          text-align: center;
        }

        .timestamp {
          font-size: 0.8rem;
          color: #999;
          margin-top: 0.25rem;
          position: absolute;
          right: 9px;
          top: 1px;
        }

        .thinking {
          color:gray;
        }

        .thinking::before {
          position: absolute;
          top: 50%;  /* Centre l'élément verticalement */
          left: 50%; /* Centre l'élément horizontalement */
          transform: translate(-50%, -50%) rotate(0turn); /* Ajuste le centre et prépare pour la rotation */
          font-family: "Material Symbols Sharp";
          font-weight: normal;
          font-style: normal;
          content: "\\e9d0";
          font-size: 24px;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          font-feature-settings: "liga";
          user-select:none;
          transition:opacity 2s;
          opacity:1;
          animation: spin 1s infinite linear;
          transform-origin: center center; /* Explicitement centrer le point d'origine pour la rotation */
        }

        @keyframes spin {
          from {
            transform: translate(-50%, -50%) rotate(0turn);
          }
          to {
            transform: translate(-50%, -50%) rotate(1turn);
          }
        }

      }
      `
    ]
  }

  static get properties() {
    return {
      role: { type: String },
      content: { type: String },
      status: { type: String },
      timestamp: { type: Number },
    }
  }

  constructor() {
    super();
    this.role = 'user'; // 'user' | 'assistant' | 'system'
    this.content = '';
    this.status = '';
    this.timestamp = null;
  }

  async updateMessage(content) {
    this.content = content;
    this.status = '';
    await this.requestUpdate();
    await Sleep(20);
  }

  render() {
    if (!this.status) this.status = '';

    const classes = `message ${this.role} ${this.status}`;
    return html`
      <div class=${classes} >
        ${this.timestamp 
          ? html`<div class="timestamp">${new Date(this.timestamp).toLocaleTimeString()}</div>` 
          : ''
        }
        <div><toast-viewer .value=${this.content}></toast-viewer></div>
      </div>
    `;
  }
}

customElements.define('llm-chat-message', LlmChatMessage);

class LlmChatInput extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: flex;
          gap: 0.5rem;
          padding: 0.5rem;
          position: relative;
        }

        textarea {
          flex: 1;
          padding: 0.5rem;
          max-height:30vh;
          height: 3rem;
          border: 1px solid transparent;
          border-radius: 0.5rem;
          outline:0px;
          font-size: 0.9em;
          resize: none;
          font-family: Calibri, sans-serif;
          transition: height 0.5s ease-out;
        }

        textarea:focus {
          border: 1px solid var(--sl-color-neutral-500);
          outline:0px;
        }

        .buttons {
          position: absolute;
          right:6px;
          bottom:6px;
          height:30px;
          display: flex;
          justify-content: space-between;          
          background-color: var(--sl-color-neutral-200);
          border-radius: 4px;
          gap:10px;
          align-items: center;
          padding-left:2px;
          padding-top:2px;
          overflow: hidden;
          white-space: nowrap;
        }

        .buttons m-icon {
          border-radius: 50%;
          height:16px;
          padding: 0.3rem;
          cursor:pointer;
        }

        .buttons div {
          font-size: 0.7rem;
          color: #666;
          line-height: 20px;
        }

        sl-option::part(label) {
          font-size: 0.8em;
          line-height:initial;
        }

        sl-select::part(display-input) {
          font-size: 0.9em;
        }

        .dropdown {
          background-color:var(--panel-background-color);
          padding:10px;
          box-shadow:var(--sl-shadow-medium);
          min-width:300px;
        }

        .dropdown label {
          font-size:0.8rem;
          padding-left:3px;
        }
      `
    ]
  }

  static get properties() {
    return {
      placeholder: { type: String },
      tps: { type: Number },
      playing: { type: Boolean },
      ollama: { type: Object },
      models: { type: Array },
      model: { type: String },
    }
  }

  constructor() {
    super();
    this.placeholder = 'Entrez votre message...';
    this.onInput = this.onInput.bind(this);
    this.tps = 0;
    this.model = localStorage.getItem('model') || 'granite3.1-dense:latest';
  }

  async updated(changedProperties) {
    if (changedProperties.has('ollama') && this.ollama) {
      try {
        const response = await this.ollama.list();
        this.models = response.data;
      } catch (err) {      
        console.error('Erreur Ollama:', typeof err, err.message);
        const msg = {
          role: 'assistant',
          content: `Aîe, je ne suis pas disponible actuellement 😵 (${err.message.toString().toLowerCase()})`,
          timestamp: Date.now(),
        };
        await this.conversationListEl.reset();
        await this.conversationListEl.addMessage(msg);
      }
    }
  }

  onSend() {
    if (this.playing) {
      // stop ollama
      this.dispatchEvent(new CustomEvent('stop-chat', { bubbles: true, composed: true }));
      return;
    }

    this.textareaEl = this.textareaEl || this.qs('textarea');
    const trimmed = this.textareaEl.value.trim();
    if (trimmed === '') return;

    this.dispatchEvent(new CustomEvent('message-send', { detail: { content: trimmed, hide:this.hide }, bubbles: true, composed: true }));
    this.textareaEl.value = '';
    this.textareaEl.focus();
    this.playing = true;
    this.hide = false;
  }

  onInput(ev) {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.stopPropagation();
      ev.preventDefault();
      this.onSend();
    }

    // compte le nombre de lignes
    const lines = ev.target.value.split('\n').length;

    // ajuste la hauteur du textarea
    if (lines > 5) {
      ev.target.style.height = `${lines * 1.5}rem`;
    } else {
      ev.target.style.height = '3rem';
    }
  }

  onChangeModel(ev) {
    this.model = ev.target.value;
    localStorage.setItem('model', this.model);
  }

  setPrompt(prompt, send) {
    this.textareaEl = this.textareaEl || this.qs('textarea');
    this.textareaEl.value = prompt;
    if (send) {
      this.hide = true;
      this.onSend();
    }
  }

  render() {
    return html`
      <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" placeholder="${this.placeholder}" @keydown=${this.onInput} @click=${this.onInput}></textarea>
      <div class="buttons">
        <sl-dropdown hoist  placement="top-start">
          <m-icon slot="trigger" name="settings"></m-icon>
          <div class="dropdown">
            <label>Modèle<label>
            <sl-select hoist size="small" value="${this.model}" @sl-change=${this.onChangeModel}>
              ${this.models?.map( model => html`<sl-option value="${model.name}">${model.name.replace(/:.*/, '')}</sl-option>`)}
            </sl-select>
          </div>
        </sl-dropdown>
        <div style="min-width:50px;text-align:right">${this.tps} TPS</div>
        <m-icon name="${this.playing ? 'stop_circle' : 'play_circle'}" @click=${this.onSend}></m-icon>
      </div>
    `;
  }
}

customElements.define('llm-chat-input', LlmChatInput);

class LLMChat extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-direction: column;
          border-radius: 8px;
          min-height:30vh;
          height:100%;
          align-item:center;
          width:100%;
          background-color: var(--sl-color-neutral-200);
          position:relative;
          overflow:hidden;
        }

        .loading {
          text-align: center;
          font-style: italic;
          margin: 0.5rem;
        }

        ::slotted([slot="header"]) {
          padding-left:10px;
          padding-right:10px;
        }
      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      apiUrl: { type: String, attribute: 'api-url' },
    };
  }

  constructor() {
    super();
    this.loading = false;
    this.apiUrl = window.location.origin + '/api/v2/private/ia/agents/soc';
    //this.apiUrl = window.location.origin + '/chatbot';
    this.ollama = new Ollama({ host: this.apiUrl });
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener('message-send', this.onUserMessage);
    this.addEventListener('stop-chat', this.onStopChat);
    // On ne lance la boucle "tick()" qu’au moment où on a un assistant en cours
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('message-send', this.onUserMessage);
    this.removeEventListener('stop-chat', this.onStopChat);
  }

  visibleCallback() {
    this.inputEl = this.qs('llm-chat-input');
    this.inputEl.ollama = this.ollama;
    this.conversationListEl = this.conversationListEl || this.qs('llm-conversation-list');
    this.inputEl.conversationListEl = this.conversationListEl;
  }

  onStopChat() {
    this.aborting = true;
    this.ollama.abort();
  }

  async onUserMessage(e) {
    this.conversationListEl = this.conversationListEl || this.qs('llm-conversation-list');

    // Ajout du message de l'utilisateur
    const userMsg = {
      role: 'user',
      content: e.detail.content,
      timestamp: Date.now(),
    };

    if (!e.detail.hide) {
      await this.conversationListEl.addMessage(userMsg);
      setTimeout(() => {
        this.conversationListEl && this.conversationListEl.updateScrollPosition();
      }, 100);
    }

    // Création d'un message pour l'assistant, initialement vide
    const newAssistantMsg = {
      role: 'assistant',
      content: '',
      status:'thinking',
      timestamp: Date.now(),
    };

    const currentAssistantMessage = await this.conversationListEl.addMessage(newAssistantMsg);

    try {
      const responseToken = await Fetcher.get('/api/v2/public/token');
      this.response = await this.ollama.generate({
        prompt: userMsg.content,
        model: this.inputEl.model,
        stream: true,
        csrfToken:responseToken.tok
      });
      let message = '';
      for await (const part of this.response) {
        
        if (part.status) {
          console.log(part.status);
          continue;
        }

        if (part.done) break;
        message += part.response;
        this.inputEl.tps = part.avgTPS;
        
        // lol
        // window.dispatchEvent(new CustomEvent('playsound', { detail:'info' }));

        await currentAssistantMessage.updateMessage(message);
        this.conversationListEl.updateScrollPosition();
      }

      this.inputEl.playing = false;
      setTimeout(() => {
        this.inputEl.tps = 0;
      }, 10000);
      
    } catch (err) {
      if (this.aborting) {
        this.aborting = false;
        return;
      }

      console.error('Erreur Ollama:', typeof err, err.message);
      // En cas d'erreur, on insère un message system
      const message = {
        role: 'system',
        content: 'Erreur de conversation avec Ollama.',
        timestamp: Date.now()
      }

      await this.conversationListEl.addMessage(message);

    }
  }

  async setPrompt(prompt, send) {
    this.inputEl = this.inputEl || this.qs('llm-chat-input');
    this.conversationListEl = this.conversationListEl || this.qs('llm-conversation-list');
    this.inputEl.setPrompt(prompt, send);
  }

  async focus() {
    this.inputEl = this.inputEl || this.qs('llm-chat-input');
    this.inputEl.textareaEl = this.inputEl.textareaEl || this.inputEl.qs('textarea');
    this.inputEl.textareaEl.focus();
  }

  render() {
    return html`
      <llm-conversation-list></llm-conversation-list>
      ${this.loading ? html`<div class="loading">Chargement...</div>` : null}
      <slot name="header" class="header"></slot>
      <llm-chat-input></llm-chat-input>
      <slot name="footer"></slot>
    `;
  }
}


customElements.define('llm-chat', LLMChat);