import { PageElement, html } from 'Elements';
import App from 'App';

class PageTicketsList extends PageElement {
  async connectedCallback() {
    super.connectedCallback();
    await import(/* webpackChunkName: "tiplist" */ 'Components/ticket/list/page.js');
  }

  render() {
    return html`<tickets-list-page></tickets-list-page>`
  }
}

class PageTicketsView extends PageElement {
  async connectedCallback() {
    super.connectedCallback();
    await import(/* webpackChunkName: "tippage" */ 'Components/ticket/ticket-page.js');
  }

  render() {
    const ticketHash = App.config.router.location.params.hash || '';
    return html`<ticket-page ticketHash=${ticketHash}></ticket-page>`
  }
}

customElements.define('page-my-tickets-list', PageTicketsList);
customElements.define('page-my-tickets-view', PageTicketsView);

