import { html, css, formCss } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import config from './config.js';
import validationCss from './validation.css.js';
import { Fetcher } from 'Utils';

class ContactValidation extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      formCss,
      validationCss,
      css`
        .marginbottom {
          margin-bottom:10px;
        }

        .bold {
          font-weight: bold;
        }

        sl-tab {
          position:relative;
        }

        sl-tab m-icon {
          position: absolute;
          right: -4px;
          top: 5px;
        }

        table {
          font-size:0.9em;
        }

        table th {
          text-align:left;
        }

        table m-icon {
          font-size:1.8em;
        }

        table td {
          vertical-align: top;
        }
      `
    ]
  }

  static get properties() {
    return {
      item: { type: Object }
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            close:'Close',
            tabs:{
              notifications:'Notifications',
              validation:'Validation',
              history:'History',
              action:'Actions'
            },
            status:{
              waiting:'Pending',
              validated:'Validated',
              not_validated:'Not validated',
              none:'N/A',
              error:'Error: ',
              undefined:'N/A'
            },
            reset:'Reset',
            trigger:'Trigger',

            reset_email_html:'Reset the validation status of the email addresses of this user ?',
            reset_mobile_html:'Reset the validation status of the mobiles of this user ?',
            trigger_email_html:'Trigger the validation of the email addresses of this user ?',
            trigger_mobile_html:'Trigger the validation of the mobiles of this user ?',

            reset_email_label:'Confirmation',
            reset_mobile_label:'Confirmation',
            trigger_email_label:'Confirmation',
            trigger_mobile_label:'Confirmation',

            send_account_access_html: 'Send a notification for account access to this user ?',
            send_account_access_label: 'Confirmation',

          },
        },
        french:{
          translation: {
            close:'Fermer',
            tabs:{
              notifications:'Notifications',
              validation:'Validation',
              history:'Historique',
              action:'Actions'
            },
            status:{
              waiting:'En attente',
              validated:'Validé',
              not_validated:'Non validé',
              none:'N/A',
              error:'Erreur: ',
              undefined:'N/A'
            },
            reset:'Réinitialiser',
            trigger:'Déclencher',
            send:'Envoyer',

            reset_email_html:'Réinitialiser l\'état de la validation des adresses email de cet utilisateur ?',
            reset_mobile_html:'Réinitialiser l\'état de la validation des mobiles de cet utilisateur ?',           
            trigger_email_html:'Lancer la validation des adresses email de cet utilisateur ?',
            trigger_mobile_html:'Lancer la validation des mobiles de cet utilisateur ?',

            reset_email_label:'Confirmation',
            reset_mobile_label:'Confirmation',
            trigger_email_label:'Confirmation',
            trigger_mobile_label:'Confirmation',

            send_account_access_html: 'Envoyer une notification d\'accès au compte utilisateur ?',
            send_account_access_label: 'Confirmation',

          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.item = null;
    this.viewOnly = true;
    this.urlVar = 'idu';
  }

  getParentName(item) {
    return item.parentId ? this.parent.items.find(i => i._id === item.parentId).name : '';
  }

  getItemTitle(opts = {}) {
    return this.item.firstname + ' ' + this.item.lastname;
  }

  renderStatusIcon(device, level) {
    /*
    const statusField = `validation_${device}_${level}_status`;
    const icon = config.VALIDATION_STATUS_ICON[device][this.item[statusField]];
    return html`<m-icon name="${icon}" color="green"></m-icon>`;
    */

    const field = `validation_${device}_${level}_status`;
    const statusIcon = config.VALIDATION_STATUS_ICON[device];
    const value = this.item[`${device}_${level}`];

    let icon = statusIcon.undefined;
    let css = 'undefined';

    if (value) {
      icon = statusIcon[this.item[field] || 'not_validated'];
      css = 'not_validated';
    }

    css = 'validation ' + (this.item[field] || css);
    return html`<m-icon name="${icon}" class="${css}"></m-icon>`;
  }

  renderTableTd(device, level) {
    const value = this.item[`${device}_${level}`];
    const statusField = `validation_${device}_${level}_status`;
    const status = this.item[statusField] || 'not_validated';
    let statusComment = '';

    if (status === 'error') {
      statusComment=html`${this.item[`validation_${device}_${level}_error`]}`;
    }

    return html`
      <tr>
        <td align="center">${this.renderStatusIcon(device, level)}</td>
        <td>${value || 'N/A'}</td>
        ${value 
          ? html`<td>${this._tl(`status.${status}`)} ${statusComment}</td>`
          : html`<td></td>`
        }
      </tr>
    `;
  }

  renderTableHeader() {
    return html`
      <thead>
        <tr>
          <th width="30"></th>
          <th width="250"></th>
          <th></th>
        </tr>
      </thead>
    `;
  }

  showModalValidation(device, action) {
    this.device = device;
    this.action = action;
    this.modalValidation = this.modalValidation || this.qs('#modal-dialog-validation');

    this.modalValidation.qs('p').innerHTML = this._tl(`${action}_${device}_html`);
    this.modalValidation.qs('sl-button[slot="bt2"]').textContent = this._tl(action);
    this.modalValidation.label = this._tl(`${action}_${device}_label`);
    this.modalValidation.show();
  }

  showModalNotification(type, action) {
    this.type = type;
    this.action = action;
    this.modalNotification = this.modalNotification || this.qs('#modal-dialog-notification');

    this.modalNotification.qs('p').innerHTML = this._tl(`${action}_${type}_html`);
    this.modalNotification.qs('sl-button[slot="bt2"]').textContent = this._tl(action);
    this.modalNotification.label = this._tl(`${action}_${type}_label`);
    this.modalNotification.show();
  }

  async runValidation() {
    this.modalValidation.loadingButtons();
    const url = `private/registry/tree/${this.item._id}/validation/${this.action}`;
    const response = await Fetcher.put(url, { device: this.device });
    this.modalValidation.resetButtons();
    this.modalValidation.hide();
    if (!response || !response.ok) {
      return;
    }

    this.item = response.data;
    window.dispatchEvent(new CustomEvent(config.eventUpdated, { detail: response.data }));
  }

  async runNotification() {
    this.modalNotification.loadingButtons();
    const url = `private/registry/tree/${this.item._id}/notify/${this.type}/${this.action}`;
    console.log(url);
    const response = await Fetcher.put(url, { device: this.device });
    this.modalNotification.resetButtons();
    this.modalNotification.hide();
    if (!response || !response.ok) {
      return;
    }

    this.item = response.data;
    window.dispatchEvent(new CustomEvent(config.eventUpdated, { detail: response.data }));
  }

  render() {
    if (!this.item) return '';
    return html`
      <modal-drawer>
        <style type="text/css">${this.constructor.styles}</style>
        <sl-tab-group>
          <sl-tab slot="nav" panel="notifications" class="bold">${this._tl('tabs.notifications')}</sl-tab>
          <sl-tab slot="nav" panel="validation" class="bold">${this._tl('tabs.validation')}</sl-tab>
          <sl-tab slot="nav" panel="history" class="bold">${this._tl('tabs.history')}</sl-tab>
          
          <sl-tab-panel name="notifications">
            <section-header size="small" bold>
              Accès compte utilisateur (adresse email principale)
              <m-icon title="Envoyer" variant="button" name="play_circle" slot="right" @click="${ev => this.showModalNotification('account_access', 'send')}"></m-icon>
            </section-header>
          </sl-tab-panel>

          <sl-tab-panel name="validation">
            <section-header size="small" bold>
              Adresses email
              <m-icon title="Réinitialiser" variant="button" name="stop_circle" slot="right" @click="${ev => this.showModalValidation('email','reset')}"></m-icon>
              <m-icon title="Rejouer" variant="button" name="play_circle" slot="right" @click="${ev => this.showModalValidation('email','trigger')}"></m-icon>
            </section-header>
            <table>
              ${this.renderTableHeader()}
              <tbody>
                ${this.renderTableTd('email','primary')}
                ${this.renderTableTd('email','secondary')}
              </tbody>
            </table>
            <br/>
            <section-header size="small" bold>
              Mobiles
              <m-icon title="Réinitialiser" variant="button" name="stop_circle" slot="right" @click="${ev => this.showModalValidation('mobile','reset')}"></m-icon>
              <m-icon title="Rejouer" variant="button" name="play_circle" slot="right" @click="${ev => this.showModalValidation('mobile','trigger')}"></m-icon>
            </section-header>
            <table>
              ${this.renderTableHeader()}
              <tbody>
                ${this.renderTableTd('mobile','primary')}
                ${this.renderTableTd('mobile','secondary')}
              </tbody>
            </table>
            <br/><br/>
          </sl-tab-panel>
          <sl-tab-panel name="history">
            <box-styled variant="warning">Pas encore implémenté</box-styled>
          </sl-tab-panel>
        </sl-tab-group>

        <sl-button slot="bt2" variant="text" close="true">${this._tl('close')}</sl-button>
      </modal-drawer>

      <modal-dialog id="modal-dialog-validation">
        <p id="modal-dialog-validation-text"></p>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.runValidation}></sl-button>
      </modal-dialog>

      <modal-dialog id="modal-dialog-notification">
        <p id="modal-dialog-validation-text"></p>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" @click=${this.runNotification}></sl-button>
      </modal-dialog>
    `;
  }

}

customElements.define('registry-node-contact-validation', ContactValidation);

