import { html, css } from 'Elements';
import TableBasic from 'Components/abstract/TableBasic.js'

import config from './config.js';
import './edit.js';

class RedisPage extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        table tr {
          padding:0;
        }

        table td {
          padding:0px;
          padding-left: 7px;
          padding-bottom: 0px;
        }

        .center {
          padding:0px;
        }

        m-icon[name="check"] {
          color:var(--sl-color-success-700);
          font-size:1.6em;
          margin-top:2px;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Redis',
            empty:'No keys available',
            key:'Key',
            ttl:'TTL (seconds)',
          }
        },
        french:{
          translation: {
            title:'Redis',
            empty:'Aucune clé disponible',
            key:'Clé',
            ttl:'TTL (secondes)',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = 'large';
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.itemTitleField = config.itemTitleField;
    this.eventUpdated = config.eventUpdated;
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >= 1 ? `${this._tl('key')}s` : this._tl('key');
    return `${totalCount} ${totalText}`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th>${this._tl('key')}</th>
            <th width="70" align="center">${this._tl('ttl')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${this._getItemTitle(item)}</td>
            <td align="center">${item.ttl}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}">
                    <m-icon slot="prefix" name="edit"></m-icon>
                    ${this._tl('modify')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._tl('remove')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderEditComponent() {
    return html`<admin-redis-edit id="modal-edit"></admin-redis-edit>`;
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          Êtes-vous sûr de vouloir supprimer &laquo;<span class="itemTitle"></span>&raquo;?
          <br/><br/>
          <ul>
            <li>Cette action est irréversible, réservée aux développeurs et administrateurs.</li>
          </ul>
        </div>

        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="danger" @click=${this._deleteItem}>${this._tl('delete')}</sl-button>
      </modal-dialog>
    `;
  }

  render() {    
    return html`
      <section-header backroute="../../" micon="database">${this._tl('title')}</section-header>
      <br/>
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderEditComponent()}
      ${this._renderDeleteModal()}
      <br/><br/><br/>
    `;
  }
}

customElements.define('page-sys-srv-redis', RedisPage);
