import { PageElement, html, css } from 'Elements';

import './alerts/dashboard';
import './settings';
import './tickets';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }
      `
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }
  
  render() {
    return html`
      <section-header size="big" backroute="../" micon="arming_countdown">Espace SOC</section-header>
      <br/>
      <div class="grid">
        <!-- <box-styled layout="${this.layout}" micon="bar_chart_4_bars" aroute="./alert/dashboard">Tableau de bord</box-styled> -->
        <box-styled layout="${this.layout}" micon="event_note" aroute="./tickets?is=taken%2Cnew">Alertes & Incidents</box-styled>
        <box-styled layout="${this.layout}" micon="tune" aroute="./settings">Paramètres</box-styled>
      </div>
      <br/>
    `;
  }

}

customElements.define('page-soc-home', Page);