import { Fetcher } from 'Utils';
import dayjs from 'dayjs';
import 'dayjs/locale/fr'; 
import 'dayjs/locale/en'; 
import weekOfYear from 'dayjs/plugin/weekOfYear';
import i18next from 'i18next';
import App from 'App';
import { Random } from 'Utils';

// P1 rouge
// P2 orange
// P3 jaune
// P4 bleu

dayjs.extend(weekOfYear);

class TicketsDataManager {

  constructor(opts) {
    this.parent = opts.parent;
    this._lang = i18next.language;
    this.loading = true;
    
    this._i18nResources = {
      english: {
        translation: {
          title:'Alerts & Incidents',
          graphDistributionStatus: 'Alerts & Incidents Status',
          graphDistributionClassification:'Incidents Classification',
          graphDistributionSubClassification:'Incidents Subclassification',
          until:'until',
          from:'from',
          to:'to',
          ticket_status:{
            'new': 'New',
            'open': 'In Progress',
            'resolved': 'Resolved',
            'false-positive': 'False Positive'
          }
        },
      },
      french: {
        translation: {
          title:'Alertes & Incidents',
          graphDistributionStatus: 'Statuts des alertes & incidents',
          graphDistributionClassification: 'Classification des incidents',
          graphDistributionSubClassification:'Sous Classification des incidents',
          until:'jusqu\'au',
          from:'du',
          to:'au',
          ticket_status:{
            'new': 'Alertes',
            'open': 'Incidents',
            'resolved': 'Résolus',
            'false-positive': 'Faux Positif'
          }
        }
      }
    };

    window.addEventListener('language-changed', this._onLanguageChanged.bind(this));
    this._i18init();
    this.setDayJsLocale();
    this.settings = { period:'' };
    this.reset();
  }

  _i18init() {
    this._i18nInstance = i18next.createInstance();
    this._i18nInstance.init({
      resources: this._i18nResources,
      fallbackLng: App.config.language,
      interpolation: {
        escapeValue: false
      }
    });
  }

  _onLanguageChanged(ev) {
    this._lang = ev.detail.language;
    this._i18nInstance.changeLanguage(this._lang);
  }

  _tl(key) {
    if (!this._i18nInstance) return key;
    const txt = this._i18nInstance.t(key);
    if (txt === key) {
      console.warn('Missing translation for key:', key);
    }
    return txt;
  }

  setDayJsLocale() {
    dayjs.locale(App.config.lg);
  }

  getCSSVariableValue(variableName) {
    const hsl = getComputedStyle(this.parent).getPropertyValue(variableName).trim();
    return hsl ? hsl : null;
  }

  hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0'); // Convertir en hex et ajouter des zéros si nécessaire
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  reset() {
    this.ticketsFiltered = [];
    this.statsByStatus = { 'new': 0, 'open': 0, 'resolved': 0 };
    this.statsByClassification = {};
    this.statsBySeverity = {};
    this.statsBySubClassification = {};
    this.dates = { 'new': {}, 'open': {}, 'resolved': {} };
    this.statusColors = {
      'new': this.getCSSVariableValue('--sl-color-red-dark'),
      'open': this.getCSSVariableValue('--sl-color-orange-dark'),
      'resolved': this.getCSSVariableValue('--sl-color-green-dark')
    };
    this.severityLevels = {
      'S0':{ label: 'S0 - Indéterminé', color: '#999999' },
      'S1':{ label: 'S1 - Critique', color: '#bf2320' },
      'S2':{ label: 'S2 - Majeur',color: '#c84612' },
      'S3':{ label: 'S3 - Modéré',color: '#cf8f0e' },
      'S4':{ label: 'S4 - Mineur',color: '#197995' },
      'S5':{ label: 'S5 - Sans incidence',color: '#2a77fa' },
    };
    this.graphDataBlocks = { 'new': [], 'open': [], 'resolved': [] };
    this.graphDataDistribution = { data: [], labels: [], colors: [] };
    this.graphDataBlocksTime = { data: [], labels: [], colors: [
        this.getCSSVariableValue('--sl-color-red-dark'),
        this.getCSSVariableValue('--sl-color-orange-dark'),
        this.getCSSVariableValue('--sl-color-green-dark'),
      ] };
    //this.graphDataSeverity = { data: [], labels: [], colors: [] };
    this.graphDataSeverity = {};
    this.graphDataClassification = { data: [], labels: [] };
    this.graphDataSubClassification = { data: [], labels: [] };
    this.dateFieldPerStatus = { 'new': 'Created', 'open': 'Started', 'resolved': 'Resolved' };
    this.dateFormats = {
      month: { english: 'MMMM', french: 'MMMM' },
      day: { english: 'MM/DD', french: 'DD/MM' },
      hour: { english: 'HH:mm', french: 'HH:mm' },
      date: { english: 'MM/DD/YYYY HH:mm', french: 'DD/MM/YYYY HH:mm' }
    };
  }

  async refresh(settings) {
    this.settings = { ...this.settings, ...settings };
    //console.log('refresh', JSON.stringify(this.settings));
    
    this.loading = true;

    try {
      const response = await Fetcher.post('private/soc/dashboard', settings);
      if (response && response.data) {
        this.tickets = response.data;
      }
    } catch(e) {
      console.error(e);
    }

    this.loading = false;
    this.prepareData();
  }

  prepareData() {
    if (!this.tickets) return;

    this.reset();
    let minDate, maxDate;

    // Traitement des tickets
    for (const ticket of this.tickets) {
      let severity = '';
      let classification = '';
      let subClassification = '';

      for (const customField of ticket.CustomFields) {
        if (customField.name.match(/sévérité/i)) {
          severity = customField.values[0];
          if (severity) severity = severity[0]+severity[1];
        }
        if (customField.name === 'Classification') classification = customField.values[0];
        if (customField.name === 'Sous Classification') subClassification = customField.values[0];
      }

      if (!this.dateFieldPerStatus[ticket.Status] || ticket.Queue.id === '4') continue;

      ticket.LastUpdated = new Date(ticket.LastUpdated);
      this.ticketsFiltered.push(ticket);
      this.statsByStatus[ticket.Status]++;

      if (classification) this.statsByClassification[classification] = (this.statsByClassification[classification] || 0) + 1;
      if (subClassification) this.statsBySubClassification[subClassification] = (this.statsBySubClassification[subClassification] || 0) + 1;
      if (severity) this.statsBySeverity[severity] = (this.statsBySeverity[severity] || 0) + 1;

      const date = ticket[this.dateFieldPerStatus[ticket.Status]].split('T')[0];
      this.dates[ticket.Status][date] = (this.dates[ticket.Status][date] || 0) + 1;

      console.log('ticket.Status', ticket.Status);
      console.log('this.dateFieldPerStatus[ticket.Status]', this.dateFieldPerStatus[ticket.Status]);
      console.log('date', date);

      const currentDate = new Date(date);
      if (!minDate || currentDate < minDate) minDate = currentDate;
      if (!maxDate || currentDate > maxDate) maxDate = currentDate;
    }

    // Uniformiser les dates pour chaque statut
    for (const status in this.dates) {
      const currentDate = new Date(minDate);
      while (currentDate <= maxDate) {
        const dateStr = currentDate.toISOString().split('T')[0];
        if (!this.dates[status][dateStr]) this.dates[status][dateStr] = 0;
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    for (const tmp in this.statsByStatus) {
      this.graphDataDistribution.data.push(this.statsByStatus[tmp]);
      this.graphDataDistribution.labels.push(this._tl(`ticket_status.${tmp}`));
    }

    for (const tmp in this.statsBySeverity) {
      this.graphDataSeverity[tmp] = this.statsBySeverity[tmp];
    }

    for (const tmp in this.statsByClassification) {
      this.graphDataClassification.data.push(this.statsByClassification[tmp]);
      this.graphDataClassification.labels.push(tmp);
    }

    for (const tmp in this.statsBySubClassification) {
      this.graphDataSubClassification.data.push(this.statsBySubClassification[tmp]);
      this.graphDataSubClassification.labels.push(tmp);
    }

    // order this.ticketsFiltered by date
    this.ticketsFiltered.sort((a, b) => b.LastUpdated - a.LastUpdated);
    
    // order this.ticketsFiltered by status: new, open, resolved, then by date
    this.ticketsFiltered.sort((a, b) => {
      if (a.Status === 'new' && b.Status !== 'new') return -1;
      if (a.Status === 'open' && b.Status === 'resolved') return -1;
      return 1;
    });

    // Préparer les données pour le graphique
    this.prepareGraphDataByPeriod();
  }

  capitalizeFirstLetter(word) {
    if (typeof word !== 'string' || word.length === 0) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  prepareGraphDataByPeriod() {
    const periodMap = {
      '1y': { length: 12, getIndex: date => date.getMonth(), labelFormatter: this.formatMonth },
      //'1m': { length: 31, getIndex: (date) => date.getDate() - 1, labelFormatter: this.formatDay },
      //'1m': { length: 5, getIndex: date => Math.floor((date.getDate() - 1) / 6), labelFormatter: this.formatWeek }, // 5 semaines
      '1m': { length: 5, getIndex: date => dayjs(date).week(), labelFormatter: this.formatWeek }, // 5 semaines
      '1w': { length: 7, getIndex: date => date.getDay() - 1, labelFormatter: this.formatDay },
      '1d': { length: 24, getIndex: date => date.getHours(), labelFormatter: this.formatHour }
    };

    const currentPeriod = Object.keys(periodMap).find(period => this.settings.period.match(new RegExp(`^${period}`)));
    if (!currentPeriod) return;

    const { length, getIndex, labelFormatter } = periodMap[currentPeriod];

    let i = 0;
    for (const status in this.dates) {
      this.graphDataBlocksTime.data[i] = {
        name: this._tl(`ticket_status.${status}`),
        data: Array(length).fill(0)
      };

      for (const date in this.dates[status]) {
        const datex = new Date(date);
        const index = getIndex(datex);
        this.graphDataBlocksTime.data[i].data[index] += this.dates[status][date];
        this.graphDataBlocksTime.labels[index] = this.capitalizeFirstLetter(labelFormatter.call(this, date));
      }

      i++;
    }

    // Compléter les labels et données manquantes pour chaque période
    this.completeMissingData(length, labelFormatter);
  }

  completeMissingData(length, labelFormatter) {
    for (let i = 0; i < length; i++) {
      if (!this.graphDataBlocksTime.labels[i]) {
        let defaultDate;

        // Gérer la génération correcte de la date par rapport à l'index et à la période actuelle
        if (this.settings.period.match(/^1y/)) {
          // Période d'une année : chaque `i` correspond à un mois
          defaultDate = new Date(new Date().getFullYear(), i, 1); // Mois `i` de l'année actuelle
        } else if (this.settings.period.match(/^1m/)) {
          // Période d'un mois : chaque `i` correspond à un jour du mois
          //defaultDate = new Date(new Date().getFullYear(), new Date().getMonth(), i + 1); // Jour `i` du mois courant

          // Correction pour générer chaque semaine du mois correctement
          const startOfMonth = dayjs().startOf('month');
          defaultDate = startOfMonth.add(i * 7, 'day').toDate(); // Avancer par tranche de 7 jours pour chaque semaine

        } else if (this.settings.period.match(/^1w/)) {
          // Période d'une semaine : chaque `i` correspond à un jour de la semaine
          const startOfWeek = dayjs().startOf('week').toDate();
          defaultDate = new Date(startOfWeek);
          defaultDate.setDate(startOfWeek.getDate() + i); // Jour `i` de la semaine courante
        } else if (this.settings.period.match(/^1d/)) {
          // Période d'une journée : chaque `i` correspond à une heure de la journée
          defaultDate = new Date(new Date().setHours(i, 0, 0, 0)); // Heure `i` du jour courant
        }

        // Appliquer le label formaté à l'indice `i`
        this.graphDataBlocksTime.labels[i] = this.capitalizeFirstLetter(labelFormatter.call(this, defaultDate));
      }

      const min = 0;
      let max = 10;
      if (this.settings.period.match(/^1y/)) max = 20;
      if (this.settings.period.match(/^1m/)) max = 5;
      if (this.settings.period.match(/^1w/)) max = 3;
      if (this.settings.period.match(/^1d/)) max = 2;
      const val = Random.between(min, max);

      // Remplir les données manquantes avec une valeur aléatoire entre 2 et 50
      for (let j = 0; j < this.graphDataBlocksTime.data.length; j++) {
        if (!this.graphDataBlocksTime.data[j].data[i]) {
          this.graphDataBlocksTime.data[j].data[i] = val;
        }
      }
    }
  }



  formatHour(date) {
    return dayjs(date).format(this.dateFormats.hour[App.config.language]);
  }

  formatMonth(date) {
    return dayjs(date).format(this.dateFormats.month[App.config.language]);
  }

  /*
  formatWeek(date) {
    const weekStart = dayjs(date).startOf('week').format(this.dateFormats.day[App.config.language]);
    const weekEnd = dayjs(date).add(5, 'day').format(this.dateFormats.day[App.config.language]); // 6 jours par semaine
    return `${weekStart} - ${weekEnd}`;
  }
  */
  formatWeek(date) {
    console.log(date);
    // Utilisation de la fonction dayjs().week() pour obtenir le numéro de la semaine
    const weekNumber = dayjs(date).week(); 
    return `Semaine ${weekNumber}`;
  }

  formatDay(date) {
    return dayjs(date).format(this.dateFormats.day[App.config.language]);
  }

  formatDate(date) {
    const str = dayjs(date).format(this.dateFormats.date[App.config.language]);
    if (str.endsWith('00:00')) return str.split(' ')[0];
  }
}

export default TicketsDataManager;
