import { BaseElement, html, css } from 'Elements';
import { Lang } from 'Utils';

class InputRangeNumbers extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
          --color: #007bff;
          --size: 1em;
        }

        label {
          white-space:nowrap;
          font-size:0.9em;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display: block;
          position: relative;
          min-width:200px;
          padding-left:35px;
          padding-right:35px;
        }

        :host([disabled]) {
          pointer-events: none;
        }

        .container {
          display: flex;
          align-items: center;
          position: relative;
          gap: 20px;
        }

        .container_range {
          position: relative;
          flex: 1;
        }
        
        .button {
          position: absolute;
          width: var(--size);
          height: var(--size);
          margin-top: -10px;
          display: flex;
          justify-content: center;
          transform: scaleY(1.01); /* Prevent subpixel rendering */
        }

        button {
          display: block;
          cursor: pointer;
          border: none;
          padding: 0;
          width:1px;
          height: var(--size);
          outline:1px solid var(--color);
          transition:all 0.2s;
          transform: scaleY(1.01); /* Prevent subpixel rendering */
        }

        :host([disabled]) button {
          border-radius: 0px;
          width:1px;
        }

        .time-label {
          position: absolute;
          top: -8px;
          font-size: 0.8em;
          white-space: nowrap;
          padding: 2px 4px;
          cursor: pointer;
          border: 1px solid var(--sl-color-gray-300);
          background: var(--sl-panel-background-color);
          opacity: 0.9;
          cursor:col-resize	
        }

        .time-label.left {
          transform: translateX(-103%);
        }

        .time-label.left.disabled {
          transform: translateX(-103%);
        }

        .time-label.right {
          transform: translateX(2%);
        }

        .time-label.right.disabled {
          transform: translateX(2%);
        }

        [part="track"],
        [part="range"] {
          height:6px;
          transform: scaleY(1.01); /* Prevent subpixel rendering */
          top: 0px;
          position: absolute;
          cursor:default;
        }

        [part="track"] {
          background: var(--sl-color-danger-200);
          position: relative;
          border-radius:5px;
        }

        [part="range"] {
          background: var(--color);
          pointer-events: auto;
          cursor:move;
        }
        
        :host([disabled]) .disabled [part="range"] {
          pointer-events: none;
          cursor:default;
        }

        .cursor-bar {
          position: absolute;
          display: block;
          width: 10px;
          height: 32px;
          background: linear-gradient(to right, transparent 40%, red 50%, transparent 60%);
          top: -13px;
          transform: scaleY(1.01);
        }
      `
    ];
  }

  static get properties() {
    return {
      min: { type: Number },
      max: { type: Number },
      lower: { type: Number },
      upper: { type: Number },
      color: { type: String },
      step: { type: Number },
      name: { type: String },
      units: { type: String },
      value: { type: String },
      disabled: { type: Boolean, reflect: true },
      cursor: { type: Number },
      showCursor: { type: Boolean },
      label: { type: String },
    };
  }

  constructor() {
    super();
    this.min = 0;
    this.max = 24;
    this.lower = 0;
    this.upper = 24;
    this.color = '#007bff';
    this.step = 1;
    this.units = ''; // or "hourminute"
    this.value = '';
    this.disabled = false;
    this.cursor = null;
    this.showCursor = false;

    this.updateCursor = this.updateCursor.bind(this);
  }

  firstUpdated() {
    this.initializeValues(true);
    this.style.setProperty('--color', this.color);
    if (this.units === 'hourminute') {
      this.min = this.convertToMinutes(this.min);
      this.max = this.convertToMinutes(this.max);
      this.lower = this.convertToMinutes(this.lower);
      this.upper = this.convertToMinutes(this.upper);
      this.step = this.step * 60; // Pas en minutes
      this.initCursor();
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    clearInterval(this.timer);
  }

  updateCursor() {
    const now = new Date();
    const minutesSinceMidnight = now.getHours() * 60 + now.getMinutes();
    this.cursor = minutesSinceMidnight;
  }
  
  initCursor() {
    if (!this.showCursor) return;
    this.updateCursor();
    this.timer = setInterval(this.updateCursor, 1000);
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.initializeValues(false);
    }
  }  

  initializeValues(firstTime) {
    if (this.value) {
      const [lowerStr, upperStr] = this.value.split('_');
      const lower = parseFloat(lowerStr);
      const upper = parseFloat(upperStr);

      if (!isNaN(lower) && !isNaN(upper)) {
        if (this.units === 'hourminute' && firstTime) {
          this.lower = lower/60;
          this.upper = upper/60;
        } else {
          this.lower = lower;
          this.upper = upper;
        }
      }

      if (this.lower < this.min) {
        this.lower = this.min;
      }

      if (this.upper > this.max) {
        this.upper = this.max;
      }
    }
  }

  convertToMinutes(value) {
    return value * 60;
  }

  convertToHours(value) {
    return value / 60;
  }

  getcursorPosition() {
    if (this.cursor !== null) {
      const range = this.max - this.min;
      const position = ((this.cursor - this.min) / range) * 100;
      return `${position}%`;
    }
    return null;
  }

  startDrag(event, slider) {
    event.preventDefault();
    const startValue = this[slider];
    const startX = event.touches ? event.touches[0].clientX : event.clientX;

    const move = (moveEvent) => {
      const clientX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const offset = ((this.max - this.min) * (clientX - startX)) / this.offsetWidth;
      let newValue = startValue + offset;

      newValue = Math.round(newValue / this.step) * this.step;

      if (slider === 'lower') {
        this.lower = Math.min(Math.max(newValue, this.min), this.upper);
      } else {
        this.upper = Math.max(Math.min(newValue, this.max), this.lower);
      }
      this.sendEvent();
    };

    const endDrag = () => {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchmove', move);
      document.removeEventListener('touchend', endDrag);
    };

    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchmove', move);
    document.addEventListener('touchend', endDrag);
  }

  startRangeDrag(event) {
    if (this.hasAttribute('disabled')) return;

    event.preventDefault();
    const startLower = this.lower;
    const startUpper = this.upper;
    const startX = event.touches ? event.touches[0].clientX : event.clientX;

    const move = (moveEvent) => {
      const clientX = moveEvent.touches ? moveEvent.touches[0].clientX : moveEvent.clientX;
      const offset = ((this.max - this.min) * (clientX - startX)) / this.offsetWidth;
      let newLower = startLower + offset;
      let newUpper = startUpper + offset;

      if (newLower >= this.min && newUpper <= this.max) {
        this.lower = Math.round(newLower / this.step) * this.step;
        this.upper = Math.round(newUpper / this.step) * this.step;
        this.requestUpdate();
        this.sendEvent();
      }
    };

    const endDrag = () => {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchmove', move);
      document.removeEventListener('touchend', endDrag);
    };

    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchmove', move);
    document.addEventListener('touchend', endDrag);
  }

  sendEvent() {
    this.value = `${this.lower}_${this.upper}`;
    if (this.value !== this.previousValue) {
      this.dispatchEvent(new CustomEvent('change', { detail: `${this.lower}_${this.upper}` }));
    }
    this.previousValue = this.value;
    
  }

  formatLabel(value) {
    if (this.units === 'hourminute') {
      let hours = Math.floor(value / 60);
      if (hours === 24) hours = 0;
      const minutes = value % 60;
      return `${hours}h${minutes.toString().padStart(2, '0')}`;
    } else {
      return value;
    }
  }

  renderRange() {
    return html`<span part="range" style="left: ${this.lowerPercentage}%; width: ${this.upperPercentage - this.lowerPercentage}%;" @mousedown="${this.startRangeDrag}" @touchstart="${this.startRangeDrag}"></span>`
  }

  renderCursor() {
    if (!this.showCursor) return;
    return html`<div class="cursor-bar" title="${Lang.getHourMinute()}" style="left: ${this.cursorPosition};"></div>`
  }

  renderLeft() {
    return html`
      <span class="time-label left" style="left: ${this.lowerPercentage}%;" @mousedown="${(e) => this.startDrag(e, 'lower')}" @touchstart="${(e) => this.startDrag(e, 'lower')}">${this.formatLabel(this.lower)}</span>
      <div class="button" style="left: calc(${this.lowerPercentage}% - 7px);">
        <button part="lower" @mousedown="${(e) => this.startDrag(e, 'lower')}" @touchstart="${(e) => this.startDrag(e, 'lower')}"></button>
      </div>
    `;
  }

  renderRight() {
    return html`
      <span class="time-label right" style="left: ${this.upperPercentage}%;" @mousedown="${(e) => this.startDrag(e, 'upper')}" @touchstart="${(e) => this.startDrag(e, 'upper')}">${this.formatLabel(this.upper)}</span>
      <div class="button" style="left: calc(${this.upperPercentage}% - 8px);">
        <button part="upper" @mousedown="${(e) => this.startDrag(e, 'upper')}" @touchstart="${(e) => this.startDrag(e, 'upper')}"></button>
      </div>
    `;
  }

  render() {
    this.lowerPercentage = ((this.lower - this.min) / (this.max - this.min)) * 100;
    this.upperPercentage = ((this.upper - this.min) / (this.max - this.min)) * 100;
    this.cursorPosition = this.getcursorPosition();

    return html`
    <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <input type="hidden" name="${this.name}" value="${this.value}">
          <div class="container" class="${this.disabled ? 'disabled' : ''}">
            <div class="container_range">
              <div part="track"></div>
              ${this.renderRange()}
              ${this.renderCursor()}
              ${this.renderLeft()}
              ${this.renderRight()}  
            </div>
          </div>
        </div>
      </div>
    `;
  }
}

customElements.define('input-range-numbers', InputRangeNumbers);
