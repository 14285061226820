import { FullPageElement, html, css } from 'Elements';
import { Router } from 'Router';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { Lang } from 'Utils';
import dayjs from 'dayjs';

class PageHome extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      css`        

      .background_home {
        background-color:#164366;
        min-height:45vh;
        display:flex;
        position:relative;
      }

      .background_home_img {
        position:absolute;
        display:flex;
        min-height:45vh;
        width:100%;
        background-size: cover;
        background-image: url('assets/img/visuals/csirt3.webp');
        background-position: center;
        opacity: 0;
        animation: fadeIn 5s forwards;
      }/*  */

      .big_title {
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: linear-gradient(to right, transparent 0%, #111111bb 20%, #111111bb 80%, transparent 100%);
        padding:10px;
        width:100%;
        color:white;
        text-transform:uppercase;
        text-shadow: 1px 1px 1px #000000;
        font-size:2em;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:top;
        flex-direction:column;
      }

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .big_title div {
        overflow: hidden;
        margin: 0 auto;
      }

      .imground {
        background-color: white;
        border-radius: 50%;
        width:60px;
        margin-left:5px;
      }

      .comment {
        text-align:right;
        font-size:0.9em;
        display:inline-block;
      }

      .grid {
        display:grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 100px), 1fr));
        margin:20px;
        gap:20px;
      }

      p {
        margin:20px;
      }

      .grid_big {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 500px), 1fr));
        gap: 20px;
        padding:20px;
      }

      .grid_big > *  {
        border: 1px solid var(--sl-color-neutral-300);
        padding:10px;
      }

      m-icon[name="open_in_new"] {
        font-size:0.7em;
        margin-right:20px;
      }


      .img_icon {
        min-height: 30px;
      }


      @media (max-width: 670px) {

        .grid {
          grid-template-columns: repeat(2, 1fr);
          gap:20px;
          padding-left:0px;
          padding-right:0px;
        }

        .img_icon {
          min-height: 43px;
          margin-top:-5px;
        }

        .carousel_item {
          position:relative;
        }

        .carousel_item_text {
          position:absolute;
          top:0px;
          background-color:red;
        }

        .big_title {
          font-size:1.7em;
        }
      }

      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            main:{
              title: 'Center for the Fight<br/> Against Cybercrime',
              text:'Declare or follow a security incident, make a request for assistance or follow a doubt removal.',
              button:'Access my folder'
            },
            carousel:{
              cti:'Cybersecurity incidents',
              files_analysis:'Suspicious files analysis',
              vulnerabilities_encyclopedia:'Vulnerabilities encyclopedia',
              publications:'Security notices & alerts',
              blog: 'Publications from our analysts'
            },
            soc:{
              title:'SOC – Operational Security',
              text:`With increasingly mobile users and the growing use of the Cloud, exposure to cyber threats is skyrocketing.
              To ensure the protection of their data, companies must implement measures that allow them to better anticipate,
              prevent, detect, and respond to any type of cyberattack on their information system (IS).<br/><br/>
              <ul>
                <li><b>Proactive 24/7 monitoring</b> to detect, analyze, notify, and respond in real time to cyber threats.</li>
                <li>A SOC <b>qualified PDIS</b> in compliance with the strict requirements of ANSSI, ensuring you a high level of service security.</li>
                <li>A <b>360-degree view of cybersecurity</b> to cover all aspects of cyber defense that you need.</li>
                <li>Cybersecurity experts continuously trained, <b>with training being at the core of our DNA</b>.</li>
              </ul>`,
              blog: 'Publications from our analysts',
              pgp_info: 'PGP Informations'
            }
          }
        },
        french:{
          translation: {
            main:{
              title:'Centre de Lutte<br/>Contre la Cybercriminalite',
              text:'Déclarer ou suivre un incident de sécurité, effectuer une demande d\'assistance ou suivre une levée de doute.',
              button:'Accéder à mon dossier'
            },
            carousel:{
              cti:'Traitement des incidents',
              files_analysis:'Analyse de fichiers suspicieux',
              vulnerabilities_encyclopedia:'Encyclopédie des vulnérabilités',
              publications:'Avis & alertes de sécurité',
              blog:'Les publications de nos analystes'
            },
            soc:{
              title:'SOC – Sécurité Opérationnelle',
              text:`Avec des utilisateurs de plus en plus mobiles et l’utilisation croissante du Cloud,
                l’exposition aux menaces cyber explose. Pour assurer la protection de leurs données,
                les entreprises doivent mettre en place des mesures permettant de mieux prévoir,
                prévenir, détecter et réagir à tout type de cyberattaques de leur système d’information (SI).<br/><br/>
                <ul>
                  <li><b>Une surveillance proactive 24/7</b> pour détecter, analyser, notifier et réagir en temps réel face aux cyber-menaces.</li>
                  <li>Un SOC <b>qualifié PDIS</b> en respect des exigences fortes de l’ANSSI, pour vous garantir un haut niveau de sécurité du service. </li>
                  <li>Une <b>vision à 360 degrés de la cybersécurité</b> pour couvrir tous les aspects de cyberdéfense dont vous avez besoin. </li>
                  <li>Des experts en cybersécurité formés en continue, <b>la formation étant au cœur de notre ADN</b></li>
                </ul>
                `,
              pgp_info: 'Informations PGP',
              blog:'Les publications de nos analystes'
            }
          }
        }
      }
    ]
  }

  connectedCallback() {
    super.connectedCallback();
    document.body.classList.remove('caf_bg');
  }


  visibleCallback() {
    setTimeout(() => {
      this.shadowRoot.querySelector('.background_home').classList.add('img');
    }, 1000);
  }


  render_blog() {
    const articles = [
      {
        link:'https://sysdream.com/geoint-identifier-une-localisation-precise-a-partir-de-sources-ouvertes/',
        img:'https://sysdream.com/wp-content/uploads/2024/11/geoint-identifier-une-localisation-audit-et-formations-en-securite-informatique-1732004805-scaled.webp',
        title:'GEOINT : Identifier une localisation précise à partir de sources ouvertes',
        date:'2024-11-28T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/testez-et-evaluez-votre-securite-avec-la-methode-red-team/',
        img:'https://sysdream.com/wp-content/uploads/2024/11/testez-et-evaluez-votre-securite-audit-et-formations-en-securite-informatique-1731572909.webp',
        title:'Testez et évaluez votre sécurité avec la méthode RED Team',
        date:'2024-11-14T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/la-traque-dun-phisheur-grace-a-losint/',
        img:'https://sysdream.com/wp-content/uploads/2024/10/traque-dun-phisheur-grace-a-audit-et-formations-en-securite-informatique-1730127352-scaled.webp',
        title:'La traque d’un phisheur grâce à l’OSINT',
        date:'2024-11-07T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/blog/paris-2024-cybermenaces-sous-controle-ou-anticipation-reussie-le-bilan-de-la-securite-numerique-des-jeux-olympiques/',
        img:'https://sysdream.com/wp-content/uploads/2024/10/paris-2024-cybermenaces-sous-audit-et-formations-en-securite-informatique-1730132074-e1730654674493.webp',
        title:'Paris 2024 : Cybermenaces sous contrôle ou anticipation réussie ? Le bilan de la sécurité numérique des Jeux Olympiques',
        date:'2024-10-31T00:00:00.000Z'
      },
      {
        link: 'https://sysdream.com/blog/comment-rapidement-trouver-des-panels-de-malware-c2/',
        img: 'https://sysdream.com/wp-content/uploads/2024/10/comment-rapidement-trouver-des-panels-audit-et-formations-en-securite-informatique-1728898071.webp',
        title: 'Comment rapidement trouver des panels de malware (C2) ?',
        date: '2024-10-17T00:00:00.000Z',
      },
      {
        link: 'https://sysdream.com/blog/nis-2-quelles-sont-les-cles-de-la-conformite/',
        img: 'https://sysdream.com/wp-content/uploads/2024/06/nis-2-quelles-sont-audit-et-formations-en-securite-informatique-1718377010.webp',
        title: 'NIS 2 :<br/>Quelles sont les clés de la conformité ?',
        date: '2024-06-20T00:00:00.000Z',
      },
      {
        link: 'https://sysdream.com/blog/cyber-activisme-russe-le-groupe-user_sec-un-collectif-uni-contre-lennemi/',
        img: 'https://sysdream.com/wp-content/uploads/2024/06/cyber-activisme-russe-le-groupe-audit-et-formations-en-securite-informatique-1717248043.webp',
        title: 'Cyber-activisme Russe :<br/>Le groupe User_Sec « un collectif uni contre l’ennemi »',
        date: '2024-06-13T00:00:00.000Z',
      },
      {
        link:'https://sysdream.com/le-groupe-de-la-menace-62ix-une-ombre-de-jeunes-hacktivistes-dans-le-paysage-cyber/',
        img:'https://sysdream.com/wp-content/uploads/2024/06/le-groupe-de-la-menace-audit-et-formations-en-securite-informatique-1717247763.webp',
        title:'Le groupe de la menace 62IX :<br/>Une ombre de jeunes hacktivistes dans le paysage cyber',
        date:'2024-05-30T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/cyberattaques-en-france-pourquoi-autant-de-reussites/',
        img:'https://sysdream.com/wp-content/uploads/2024/06/cyberattaques-en-france-pourquoi-audit-et-formations-en-securite-informatique-1717681891.webp',
        title:'Cyberattaques en France :<br/>Pourquoi autant de réussites ?',
        date:'2024-05-25T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/de-lex-luthor-a-la-cybersecurite-lheritage-de-la-legion-of-doom/',
        img:'https://sysdream.com/wp-content/uploads/2024/06/de-lex-luthor-a-la-audit-et-formations-en-securite-informatique-1717682016.webp',
        title:'De Lex Luthor à la cybersécurité :<br/>L\'héritage de la Légion of Doom',
        date:'2024-04-25T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/breachforums-la-fugace-ascension-dun-marche-occulte-de-donnees/',
        img:'https://sysdream.com/wp-content/uploads/2024/04/audit-et-formations-en-securite-informatique-1713262630.webp',
        title:'BreachForums :<br/>La fugace ascension d\'un marché occulte de données',
        date:'2024-04-04T00:00:00.000Z'
      },
      {
        link:'https://sysdream.com/dive-into-thre-cyber-threat-landscape-in-the-aviation-industry-2023/',
        img:'https://sysdream.com/wp-content/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259518.webp',
        title:'Dive into the Cyber threat landscape :<br/>in the aviation industry 2023',
        date:'2024-03-07T00:00:00.000Z'
      },{
        link:'https://sysdream.com/de-lombre-a-la-lumiere-limpact-des-attaques-de-darkside/',
        img:'https://sysdream.com/wp-content/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259527.webp',
        title:'De l\'ombre à la lumière :<br/>L\'impact des attaques de DarkSide',
        date:'2024-02-22T00:00:00.000Z'
      },{
        link:'https://sysdream.com/decryptage-de-conti-lascension-dune-menace-cyber-globale/',
        img:'https://sysdream.com/wp-content/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259519.webp',
        title:'Décryptage de Conti :<br/>L\'ascension d\'une menace cyber globale',
        date:'2024-02-08T00:00:00.000Z'
      },{
        link:'https://sysdream.com/dragonfly-le-cyberpredateur-qui-secoue-le-secteur-de-lenergie/',
        img:'https://sysdream.com/wp-content/uploads/2024/01/dragonfly-le-cyberpredateur-qui-sysdream-1704288925.webp',
        title:'Dragonfly :<br/>Le cyberprédateur qui secoue le secteur de l’énergie',
        date:'2024-01-25T00:00:00.000Z'
      },{
        link:'https://sysdream.com/cybersecurite-des-cyber-attaquants-aux-rssi-a-qui-lia-va-t-elle-vraiment-profiter/',
        img:'https://sysdream.com/wp-content/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259523.webp',
        title:'Cybersécurité :<br/>Des cyber-attaquants aux RSSI, :<br/>à qui l’IA va-t-elle vraiment profiter ?',
        date:'2024-01-18T00:00:00.000Z'
      },{
        link:'https://sysdream.com/ia-generative-les-entreprises-ont-elles-raison-de-se-mefier-de-leur-securite/',
        img:'https://sysdream.com/wp-content/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259517.webp',
        title:'IA générative :<br/>Les entreprises ont-elles raison de se méfier de leur sécurité ?',
        date:'2024-01-04T00:00:00.000Z'
      }/*
      ,{
        link:'https://sysdream.com/cyberattaques-les-vecteurs-dattaques-informatiques/',
        img:'https://sysdream.com/sysdream-media/uploads/2023/08/cyberattaques-les-vecteurs-dattaques-sysdream-1693241001.webp',
        title:'Cyberattaques :<br/>les vecteurs d’attaques informatiques',
        date:'novembre 30, 2023'
      },{
        link:'https://sysdream.com/cybersecurite-en-france-analyse-du-radar-wavestone-2023/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259528.webp',
        title:'Cybersécurité en France :<br/>analyse du radar Wavestone 2023',
        date:'novembre 23, 2023'
      },{
        link:'https://sysdream.com/deep-web-que-se-cache-t-il-derriere-la-face-visible-dinternet/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259521.webp',
        title:'Deep Web :<br/>que se cache-t-il derrière la face visible d\'Internet ?',
        date:'novembre 16, 2023'
      },{
        link:'https://sysdream.com/les-enjeux-de-la-cyberguerre-entre-le-hamas-et-israel-un-regard-sur-les-acteurs-les-soutiens-et-les-risques/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259522.webp',
        title:'Les enjeux de la cyberguerre entre le Hamas et Israël :<br/>Un regard sur les acteurs, les soutiens et les risques',
        date:'octobre 17, 2023'
      },{
        link:'https://sysdream.com/noname05716-lexpansion-rapide-dun-collectif-hacktiviste-pro-russe/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259526.webp',
        title:'NoName057(16) :<br/>l\'expansion rapide d\'un collectif hacktiviste pro-russe',
        date:'septembre 21, 2023'
      },{
        link:'https://sysdream.com/laisser-son-navigateur-enregistrer-ses-mots-de-passe-une-question-de-securite/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259536.webp',
        title:'Laisser son navigateur enregistrer ses mots de passe :<br/>une question de sécurité',
        date:'juillet 18, 2023'
      },{
        link:'https://sysdream.com/alphv-les-nombreuses-vies-de-blackcat/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259535.webp',
        title:'AlphV, les nombreuses vies de BlackCat !',
        date:'juillet 18, 2023'
      },{
        link:'https://sysdream.com/qui-est-killnet-le-groupe-de-hackers-pro-russe/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259524.webp',
        title:'Qui est KILLNET, le groupe de hackers pro-russe ?',
        date:'juillet 18, 2023'
      },{
        link:'https://sysdream.com/ransomhouse-cybercriminels-et-atypiques/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259533.webp',
        title:'RANSOMHOUSE, cybercriminels et atypiques !',
        date:'juillet 18, 2023'
      },{
        link:'https://sysdream.com/qui-sont-les-cybercriminels-lockbit-3-0/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259532.webp',
        title:'Qui sont les cybercriminels LOCKBIT 3.0 ?',
        date:'juillet 18, 2023'
      },{
        link:'https://sysdream.com/cybersecurite-quen-est-il-du-niveau-de-formation-et-de-sensibilisation-des-francais/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259531.webp',
        title:'Cybersécurité :<br/>qu’en est-il du niveau de formation et de sensibilisation des Français ?',
        date:'juillet 10, 2023'
      },{
        link:'https://sysdream.com/cybersecurite-en-quoi-consiste-les-renseignements-sur-les-cybermenaces/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259520.webp',
        title:'Cybersécurité :<br/>en quoi consiste les renseignements sur les cybermenaces ?',
        date:'juillet 10, 2023'
      },{
        link:'https://sysdream.com/phishing-scams-3-0-le-nouveau-visage-de-lhameconnage/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259530.webp',
        title:'Phishing Scams 3.0 : <br/>le nouveau visage de l’hameçonnage',
        date:'juin 30, 2023'
      },{
        link:'https://sysdream.com/cyber-entrainement-dans-les-coulisses-dun-exercice/',
        img:'https://sysdream.com/sysdream-media/uploads/2024/04/audit-et-formations-en-securite-informatique-1713259529.webp',
        title:'Cyber-entraînement :<br/>dans les coulisses d’un exercice',
        date:'juin 28, 2023'
      }
      */
    ]

    return articles.map(article => unsafeHTML(`
      <sl-carousel-item class="carousel_item" style="position:relative">
        <img src="${article.img}"/>
        <a style="position:absolute;top:0px;left:0px;right:0px;background-color:#000000aa;backdrop-filter:blur(3px);color:white;text-align:center;padding:10px;font-size:1.2em;" href="${article.link}" target="blog">
          ${article.title}
        </a>
        <div style="position:absolute;bottom:0px;background-color:#00000099;color:white;left:0px;right:0px;text-align:center;padding:5px;font-size:0.8em;">${Lang.formatDate(article.date, 'hday')}</div>
      </sl-carousel-item>
    `));
  }

  formatDate(date) {
    return dayjs(date).format('DD/MM/YYYY');
  }

  render() {
    return html`
      <div class="background_home">
        <div class="background_home_img"></div>
        <div class="big_title">
            <div>${unsafeHTML(this._tl('main.title'))}</div>
        </div>
      </div>
      <div class="grid">
        <box-styled height="100px" aroute="/public/cti" img="assets/img/soc.png">${unsafeHTML(this._tl('carousel.cti'))}</box-styled>
        <box-styled height="100px" href="https://helix01fr.sysdream.io/analyse" target="helix" img="assets/img/files.png">${unsafeHTML(this._tl('carousel.files_analysis'))}</box-styled>
        <box-styled height="100px" href="https://helix01fr.sysdream.io/vulnerabilities" target="helix" img="assets/img/clopedie.png">${unsafeHTML(this._tl('carousel.vulnerabilities_encyclopedia'))}</box-styled>
        <box-styled height="100px" href="https://helix01fr.sysdream.io/publication" target="helix" img="assets/img/publication.png">${unsafeHTML(this._tl('carousel.publications'))}</box-styled>
      </div>

      <div class="grid_big">
        <div>
          <section-header>
            <img slot="left" class="img_icon" src="assets/img/blog.png" height="30"/>
            <a href="https://sysdream.com/propos/blog/" target="sysdream">${this._tl('soc.blog')}</a>
            <a href="https://sysdream.com/propos/blog/" target="sysdream" slot="right"><m-icon name="open_in_new"></m-icon></a>
          </section-header>
          <br/>
          <sl-carousel pagination mouse-dragging loop autoplay autoplay-interval="10000" >${this.render_blog()}</sl-carousel>
        </div>
        <div>
          <section-header>
            <img slot="left" class="img_icon" src="assets/img/cert.png" height="30"/>
            ${this._tl('soc.title')}
          </section-header>
          <br/>
          <span style="text-align:justify">
            <a href="https://www.trusted-introducer.org/directory/teams/cert-sysdream-fr.html" target="_blank"><img class="imground" src="assets/img/accredit-ti-trans.png" style="float:right"/></a>
            ${unsafeHTML(this._tl('soc.text'))}
            <br/>
            <span class="comment">
              <br/>
              <a href="#" @click="${this.showSecInfo}">${unsafeHTML(this._tl('soc.pgp_info'))}</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
        </div>
      </div>

      <br/><br/>

      <modal-dialog label="Informations clé publique PGP SysDream" class="pgp-info-dialog" style="--width: 60vw;">
        <ul>
          <li>Identifiant de la clé : 0x109652FC</li>
          <li>Empreinte de la clé : 527A 82E4 5ECE 100B 569B 2C9E 1C26 0B3F 1096 52FC</li>
          <li><a href="https://sysdream.com/files/cert/cert-sysdream-public_key.asc" target=_"blank">Télécharger la clé publique</a></li>
        </ul>
      </modal-dialog>
      
    `;
  }

  showSecInfo(ev) {
    ev.preventDefault();
    this.shadowRoot.querySelector('.pgp-info-dialog').show();
  }

  hideSecInfo() {
    this.shadowRoot.querySelector('.pgp-info-dialog').hide();
  }

  goToServices() {
    Router.go('/public/services');
  }

  goToFolder() {
    Router.go('/public/mondossier/etape1');
  }

}

customElements.define('page-home', PageHome);