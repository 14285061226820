import { html, css } from 'Elements';
import TableBasic from 'Components/abstract/TableBasic.js'

import './edit.js';

class AdminLanguagesListPage extends TableBasic {
  static get styles() {
    return [
      super.styles,
      css`
        table tr {
          padding:0;
        }

        table td {
          padding:0px;
          padding-left: 7px;
          padding-bottom: 0px;
        }

        .center {
          padding:0px;
        }

        m-icon[name="check"] {
          color:var(--sl-color-success-700);
          font-size:1.6em;
          margin-top:2px;
        }
      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'Website languages',
            empty:'No language available',
            language:'Language',
            enable:'Enabled',
            main:'Main',
          }
        },
        french:{
          translation: {
            title:'Langues du site',
            empty:'Aucune langue disponible',
            language:'Langue',
            enable:'Activée',
            main:'Principale',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.layout = 'large';
    this.apiEndpoint = 'private/admin/system/languages';
    this.urlVar = 'idl';
    this.itemTitleField = 'title';
    this.eventUpdated = 'language-updated';
  }

  _getTableHeader() {
    const totalCount = this.items?.length || 0;
    const totalText = totalCount >= 1 ? `${this._tl('language')}s` : this._tl('language');
    return `${totalCount} ${totalText}`;
  }

  _renderItems() {
    if (!this.items?.length) return;

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <thead>
          <tr>
            <th width="20"></th>
            <th>${this._tl('language')}</th>
            <th width="70" align="center">${this._tl('enable')}</th>
            <th width="70" align="center">${this._tl('main')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>      
        ${this.items.map((item) => html`
          <tr @click="${(ev) => this._editItem(ev, item)}">
            <td>${item.flag}</td>
            <td>${this._getItemTitle(item)}</td>
            <td align="center">${item.enable ? html`<m-icon name="check"></m-icon>` : ''}</td>
            <td align="center">${item.default ? html`<m-icon name="check"></m-icon>` : ''}</td>
            <td class="icon">
              <sl-dropdown>
                <m-icon slot="trigger" name="more_vert"></m-icon>
                <sl-menu>
                  <sl-menu-item @click="${(ev) => this._editItem(ev, item)}">
                    <m-icon slot="prefix" name="edit"></m-icon>
                    ${this._tl('modify')}
                  </sl-menu-item>
                  <sl-divider></sl-divider>
                  <sl-menu-item @click="${(ev) => this._deleteItemConfirm(ev, item)}">
                    <m-icon slot="prefix" name="delete"></m-icon>
                    ${this._tl('remove')}
                  </sl-menu-item>
                </sl-menu>
              </sl-dropdown>
            </td>
          </tr>
        `)}
        </tbody>
      </table>
    `;
  }

  _renderEditComponent() {
    return html`<admin-language-edit id="modal-edit"></admin-language-edit>`;
  }

  _renderDeleteModal() {
    return html`
      <modal-dialog id="modal-delete" label="Confirmation demandée">
        <div>
          Êtes-vous sûr de vouloir supprimer &laquo;<span class="itemTitle"></span>&raquo;?
          <br/><br/>
          <ul>
            <li>Cette action est irréversible, réservée aux développeurs et administrateurs.</li>
            <li>Les transactions clients liées à ce produit ainsi que les images seront également supprimées.</li>
            <li>Il est préférable de désactiver le produit si vous souhaitez le rendre invisible plutôt que de le supprimer.</li>
          </ul>
        </div>

        <sl-button slot="bt1" variant="danger" @click=${this._deleteItem}>${this._tl('delete')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
      </modal-dialog>
    `;
  }

  render() {    
    return html`
      <section-header backroute="../" micon="captive_portal">${this._tl('title')}</section-header>
      <br/>
      ${this._renderHeader()}  
      ${this._renderNoItem()}
      ${this._renderItems()}
      ${this._renderEditComponent()}
      ${this._renderDeleteModal()}
      <br/><br/><br/>
    `;
  }
}

customElements.define('page-sys-languages-list', AdminLanguagesListPage);
