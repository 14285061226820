import App from 'App';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

dayjs.locale(App.config.lg);

window.addEventListener('language-changed', () => {
  dayjs.locale(App.config.lg);
});

function getCurrentLanguage() {
  return App.config.lg || App.config.lgMain;
}

function pick(from, s) {
  if (!s) return;
  return s
    .replace(/\[([^\[\]]*)\]/g, ".$1.")
    .split(".")
    .filter((t) => t !== "")
    .reduce((prev, cur) => prev && prev[cur], from)
}

function lookup(record, fieldname, lg, fallback = true) {
  lg = lg || getCurrentLanguage();
  if (!record) return '';
  if (pick(record, fieldname) === undefined) {
    //console.log('lookup', fieldname,' return empty');
    return '';
  }
  let value = pick(record, fieldname);
  if (typeof value === 'string') {
    //console.log('lookup', fieldname,' return', value  );
    return value;
  }

  let fieldnameLg = `${fieldname}.${lg}`;
  value = pick(record, fieldnameLg);
  if (!value && fallback) {
    fieldnameLg = `${fieldname}.${App.config.lgMain}`;
    value = pick(record, fieldnameLg) || '';
  }

  //console.log('lookup', fieldnameLg,' return', value  );
  return value;
}

function lookup_deprecated(record, fieldname, lg, fallback = true) {
  lg = lg || getCurrentLanguage();
  if (!record) return '';
  if (!record[fieldname]) return '';
  
  if (typeof record[fieldname] === 'string') return record[fieldname];

  let value = record[fieldname][lg] || '';
  if (!value && fallback) value =record[fieldname][App.config.lgMain] || '';
  //console.log('lookup', value);
  return value;
}

function formatDate(v, format = 'full', lg) {
  if (!v) return '';

  lg = lg || getCurrentLanguage();

  if (lg !== 'en' && lg !== 'fr') {
    throw new Error('Date format for current language not implemented');
  }

  if (format === 'day') {
    if (lg === 'en') return dayjs(v).format('YYYY/MM/DD');
    return dayjs(v).format('DD/MM/YYYY');
  }

  if (format === 'hday') {
    if (lg === 'en') return dayjs(v).format('dddd, MMMM D, YYYY');
    if (lg === 'fr') return dayjs(v).format('dddd D MMMM YYYY');
  }

  if (format === 'hdaym') {
    if (lg === 'en') return dayjs(v).format('dddd, MMMM D, YYYY HH:mm');
    if (lg === 'fr') return dayjs(v).format('dddd D MMMM YYYY HH:mm');
  }

  if (format === 'hdays') {
    if (lg === 'en') return dayjs(v).format('dddd, MMMM D, YYYY HH:mm:ss');
    if (lg === 'fr') return dayjs(v).format('dddd D MMMM YYYY HH:mm:ss');
  }

  if (format === 'hour') {
    return dayjs(v).format('HH:mm');
  }

  if (format === 'hours') {
    return dayjs(v).format('HH:mm:ss');
  }

  if (format === 'full') {
    if (lg === 'en') return dayjs(v).format('MM/DD/YYYY HH:mm');
    return dayjs(v).format('DD/MM/YYYY HH:mm');
  }

  if (format === 'fulls') {
    if (lg === 'en') return dayjs(v).format('MM/DD/YYYY HH:mm:ss');
    return dayjs(v).format('DD/MM/YYYY HH:mm:ss');
  }

  throw new Error('Invalid format');
}

function getDayOfWeek(day) {
  return dayjs().day(day).format('dddd');
}

function getHourMinute(day) {
  return dayjs(day).format('HH:mm');
}


export default {
  lookup,
  formatDate,
  getDayOfWeek,
  getHourMinute,
  mainlg:'fr'
};
