import { BaseElement, html, css, highlight } from 'Elements';
import { Lang, Session } from 'Utils';

class SelectRegistry extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          flex-direction:column;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

        .form-control__help-text {
          font-size:0.9em;
          color:var(--sl-color-neutral-500);
        }

      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
      filter: { type: String },
      selection: { type: String },
      maxOptionsVisible: { type: Number, attribute: 'max-options-visible' },
      hideOnChange: { type: Boolean, attribute: 'hide-on-change' },
      nodeId: { type: String },
      excludeRoot: { type: Boolean, attribute: 'exclude-root' },
      helpText: { type: String, attribute: 'help-text' },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.filter = 'peoples';
    this.selection = 'multiple';
    this.maxOptionsVisible = 2;
    this.leafOnly = true;
    this.placeholder = '';
    this.apiEndPoint = '';
    this.nodeId = '';
    this.hideOnChange = false;
    this.excludeRoot = false;

    this._renderTag = this._renderTag.bind(this);
    this._renderTreeItems = this._renderTreeItems.bind(this);
  }

  
  async updated(changedProperties) {
    if (changedProperties.has('value') && this.value) {
      this.qs('select-search-tree').value = this.value;
      await this.updateComplete;
    }
    if (changedProperties.has('selection') && this.selection) {
      if (this.selection==='single-all') {
        this.selection = 'single';
        this.leafOnly = false;
      }
      if (this.selection==='single-leaf') {
        this.selection = 'single';
        this.leafOnly = true;
      }
      if (this.selection === 'multiple-all') {
        this.selection = 'multiple';
        this.leafOnly = false;
      }
      if (this.selection === 'multiple-leaf') {
        this.selection = 'multiple';
        this.leafOnly = true;
      }
    }

    if (changedProperties.has('nodeId') && this.nodeId) {
      // this will update the apiEndPoint
      this.requestUpdate();
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search-tree').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search-tree').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  _renderTag(item, self) {

    const getTagLabel = (item) => {
      if (item.nodeType === 'contact' || item.nodeType === 'structure') {
        let currentItem = item;
        let parents = '';
        if (!this.excludeRoot) {
          while (currentItem && currentItem.parentId) {
            currentItem = self.itemsById[currentItem.parentId];
            if (currentItem && currentItem.nodeType === 'structure') {
              parents = `${Lang.lookup(currentItem, 'name')} > ${parents}`;
            }
          }
          parents+= ' ';
        }

        if (item.nodeType === 'contact') {
          return `${parents}${item.firstname} ${item.lastname} (${item.email_primary})`;
        } else {
          return `${parents}${Lang.lookup(item, 'name')}`;
        }
      }
      return Lang.lookup(item, 'name');
    }

    return html`
      <sl-tag 
        type="primary" 
        data-id=${item.id} 
        ${this.multiple ? 'removable ': ''}
        size="small" 
        @sl-remove=${this.onRemoveTag}
      >
       ${getTagLabel(self.itemsById[item.id])}
      </sl-tag>
    `;
  }
  
  _getLabel(item) {
    if (item.nodeType === 'contact') {
      return `${item.firstname} ${item.lastname} (${item.email_primary})`;
    }
    return Lang.lookup(item, 'name') || '';
  }

  _renderTreeItems(items, self) {

    return items
      .map(item => {
        if (this.excludeRoot && item.nodeType === 'structure') {
          return self.renderTreeItems(item.children, self);
        }
        
        //if (item.nodeType === 'contact' || item.nodeType === 'structure') {
        if (true) {
          return html`
            <sl-tree-item
              expanded
              class="tree-item ${item.hidden ? 'hidden' : ''}"
              ?selected=${self.isSelected(item)}
              value="${item[self.primaryKey]}"
              .item=${item}
            >
              <span class="tree_label">
                <span>${highlight(self.getLabel(item), self.q)}</span>
                <span class="suffix">
                  ${item.job 
                    ? item.job.split(',').map(j => html`<sl-badge variant="primary" size="small">${j.toUpperCase()}</sl-badge>`)
                    : ''
                  }
                </span>
              </span>
              
              ${item.children?.length ? self.renderTreeItems(item.children, self) : ''}
            </sl-tree-item>
          `
        } else {
          return html`${item.children?.length ? self.renderTreeItems(item.children, self) : ''}`
        }
      });
  }

  addToSelection(id) {
    return this.qs('select-search-tree').addToSelection(id);
  }

  removeFromSelection(id) {
    return this.qs('select-search-tree').removeFromSelection(id);
  }

  render() {

    this.apiEndPoint = `private/registry/search/${this.filter}`;

    if (!this.apiEndPoint) {
      throw new Error('no apiEndPoint available for user role');
    }

    if (this.nodeId) {
      this.apiEndPoint += `?nodeId=${this.nodeId}`;
    }

    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search-tree
            value="${this.value}"
            display-key="title"
            separator=","
            name="${this.name}"
            hoist
            selection="${this.selection}"
            ?leaf-only=${this.leafOnly}
            ?hide-on-change=${this.hideOnChange}
            clearable
            max-options-visible="${this.maxOptionsVisible}"
            placeholder="${this.placeholder}"
            nodeId="${this.nodeId}"
            api=${this.apiEndPoint}
            .renderTreeItems=${this._renderTreeItems}
            .renderTag=${this._renderTag}
            .getLabel=${this._getLabel}
            primary-key="id"
            @change=${this.onChange}
          >
          </select-search-tree>

          ${this.helpText
            ? html`
              <div part="form-control-help-text" class="form-control__help-text" aria-hidden="false">
                <slot name="help-text">${this.helpText}</slot>
              </div>
            `
            : ''
          }

        </div>
      </div>
    `;
  }

}

customElements.define('select-registry', SelectRegistry);