import { LitElement, html, css } from 'lit-element';
import { Router } from 'Router';
import RoutesManager from './routes.js';
import App from 'App';
import { Session, Fetcher, UrlParams } from 'Utils';
import 'web-particles';
import menu from './menu.js';
import './footer.js';

import './styles/main.scss';

import '@shoelace-style/shoelace';
import '@shoelace-style/shoelace/dist/themes/light.css';
import '@shoelace-style/shoelace/dist/themes/dark.css';

import './components/public/index.js';

class MainApp extends LitElement {
  static get styles() {
    return css`
      :host {
        display:block;
        --deco-border-color: #2581bf66;
        --deco-border-size: 1px;
      }

      #outlet {
        width:100%;
        height:100%;
      }

      .bar {
        background-color:#2c2c2c36;
        display:flex;
        flex-grow:1;
      }

      .bar.left {
        flex-grow:1;
        border-right:var(--layout-border);
        box-shadow:var(--layout-border-box-shadow);
      }

      .bar.right {
        flex-grow:1;
        border-left:var(--layout-border);
        box-shadow:var(--layout-border-box-shadow);
      }

      .bar.center {
        max-width:var(--layout-width);
        width:var(--layout-width);
        background-color:var(--page-background-color);
        /*backdrop-filter:blur(10px);*/
        display:block;
        min-height:90vh;
        overflow:hidden;
        transition:var(--layout-transition);
      }

      .spacer {
        display:block;
        height:var(--bar-margin-top);
        width:100%;
      }

      @media print {

        .bar {
          background-color:initial;
        }

        .bar.left {
          border-right:0px;
          display:none;
        }

        .bar.right {
          border-left:0px;
          display:none;
        }

        .bar.center {
          background-color:initial;
        }

        scrollable-component {
          height:initial;
        }

        .bar {
          height:initial;
        }

        .bar.center {
          height:initial;
          min-height:initial;
          overflow:visible;
        }

        #outlet {
          margin-top:0px;
        }

        web-particles {
          display:none;
        }

        web-particles canvas {
          display:none;
        }

      }

      scrollable-component {
        --viewport-overflow-x: hidden;
      }

      web-particles canvas {
        opacity:0 !important;
        transition:opacity 3s ease-in-out;
      }

      web-particles.visible canvas {
        opacity:0.5 !important;
      }

      /* iphone 8 */
      @media (max-width: 670px) {
        .bar.left,
        .bar.right {
          display:none;
        }
      }

      @media (max-width: 900px) {
        .bar.center {
          min-width:initial;
        }
      }

    `;
  }

  constructor() {
    super();
    this.print = false;
    this.reverseAnimation = false;
    this.handleNavigationChange = this.handleNavigationChange.bind(this);
    this.onBeforePrint = this.onBeforePrint.bind(this);
    this.onAfterPrint = this.onAfterPrint.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.hideScrollable = localStorage.getItem('hs') ? true : false;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener('beforeprint', this.onBeforePrint);
    window.addEventListener('afterprint', this.onAfterPrint);
    window.addEventListener('layout-change', this.onLayoutChange);
    //this._storeScrollbarWidth();
  }

  calculateMaxA4HeightInPixels(heightInPixels, dpi = 96) {
    const mmToInch = 25.4;
    const a4HeightMm = 297;
    const a4HeightInPixels = (a4HeightMm * dpi) / mmToInch;
    const numberOfPages = Math.ceil(heightInPixels / a4HeightInPixels)+1;
    const totalHeightInPixels = numberOfPages * a4HeightInPixels;
    return Math.round(totalHeightInPixels);// - (numberOfPages * 60); // Retourner la hauteur totale en pixels en supprimant les marges d'impressions
  }

  async onBeforePrint() {
    let scroller = document.querySelector('main-app').shadowRoot.querySelector('scrollable-component');
    if (!scroller) return;

    // reset scroller height
    scroller.previousStyle = scroller.getAttribute('style');
    scroller.style = '';

    // calculate max height for A4
    let h = this.calculateMaxA4HeightInPixels(scroller.clientHeight);
    const str = `height:${h}px;--viewport-height:${h}px;`;

    // adjust scroller height
    scroller.style = str;
  }

  onAfterPrint() {
    const el = document.querySelector('main-app').shadowRoot.querySelector('scrollable-component');
    if (!el) return;
    //el.setAttribute('style', el.previousStyle);
  }

  onLayoutChange(e) {
    if (e.detail.layout === 'center') {
      this.dontLoadParticles = false;
      this.addParticles();
    } else {
      if (!this.particles) {
        this.dontLoadParticles = true;
      } else {
        this.particles.remove();
      }
    }
  }

  async firstUpdated() {
    this.router = App.config.router = new Router(this.shadowRoot.getElementById('outlet'));
    this.routesManager = new RoutesManager(this.router);
    window.addEventListener('vaadin-router-location-changed', this.handleNavigationChange);
    this.scroller = this.scroller || this.shadowRoot.querySelector('scrollable-component');
    if (this.scroller) {
      this.scroller.viewport.addEventListener('scroll', (event) => { 
        // emit window scroll event with the event
        window.dispatchEvent(new CustomEvent('scroll', { detail: event }));
      });
    }

    document.addEventListener('keydown', async (e) => {
      if (e.key === 'S' && e.ctrlKey) {
        if (this.hideScrollable) {
          localStorage.removeItem('hs');
        } else {
          localStorage.setItem('hs', '1');
        }
        document.location.reload();
      }
    });
    //this.addParticles();
  }

  async addParticles() {
    setTimeout(async () => {
      if (this.dontLoadParticles) return;
      await this.loadTSParticles();
      this.particles = document.createElement('web-particles');
      this.particles.url = 'assets/webparticles.json';

      const appHeader = this.shadowRoot.querySelector('app-header');
      appHeader.insertAdjacentElement('afterend', this.particles);

      setTimeout(() => {
        this.particles?.classList?.add('visible');
      }, 200);
    }, 1000);
  }

  loadTSParticles() {
    return new Promise((resolve, reject) => {
      if (window.tsParticles) {
        resolve();
      } else {
        const script = document.createElement('script');
        script.src = 'assets/tsparticles/2.5.3/tsparticles.bundle.min.js';
        script.onload = async () => {
          resolve();
        }
        script.onerror = () => {
          console.error('Failed to load tsparticles');
          reject();
        }
        document.head.appendChild(script);
      }
    });
  }

  performUpdate() {
    (async () => {
      await Session.get();
      super.performUpdate();
    })();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('vaadin-router-location-changed', this.handleNavigationChange);
  }

  handleNavigationChange(e) {
    this.scroller = this.scroller || this.shadowRoot.querySelector('scrollable-component');
    if (this.scroller) {
      this.scroller.viewport.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
    }
    
    document.title = e.detail.location.route.title;

    let path = e.detail.location.route.path;
    if (path === '(.*)') path = document.location.pathname;

    clearTimeout(this.timeoutPageInform);
    this.timeoutPageInform = setTimeout(() => {
      Fetcher.sendBeacon('beacon/status', { path });
    }, 300);
  }

  _getScrollbarWidth() {
    const outer = document.createElement('div');
    outer.style.position= 'fixed';
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    outer.style.top = '70px';
    outer.style.left = '10px';
    outer.style.width = '100px';
    outer.style.height = '100px';
    outer.style.border = '1px solid red';
    outer.style.backgroundColor = 'green';
    outer.style.zIndex = '100000';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    inner.style.width = '100%';
    outer.appendChild(inner);

    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  _storeScrollbarWidth() {
    this._scrollbarWidth = this._scrollbarWidth || this._getScrollbarWidth();
    document.body.style.setProperty('--doc-scrollbar-width', `${this._scrollbarWidth-2}px`);
  }


  // https://github.com/Julien-Marcou/Scrollable-Component

  renderPrintOk() {
    return html`
      <div class="bar">
        <div id="outlet"></div>
      </div>
    `;
  }

  renderPrint() {
    return html`
      <div class="bar">
        <div class="bar left"></div>
        <div class="bar center">
          <div class="spacer"></div>
          <div id="outlet"></div>
        </div>
        <div class="bar right"></div>
      </div>
    `;
  }

  renderScreen() {
    return html`
      <scrollable-component style="height:100vh;">
        <style>
          scrollable-component {
            --viewport-overflow-x: hidden;
            --scrollbar-width: var(--scrollable-width);
            --scrollbar-thumb-border-radius: var(--scrollable-radius);
            --scrollbar-padding:var(--scrollable-padding);
            --scrollbar-thumb-border-width:var(--scrollable-thumb-border-width);
            --scrollbar-thumb-border-style:var(--scrollable-thumb-border-style);
            --scrollbar-thumb-fill-color:var(--scrollable-bar-thumb-fill-color);
            --scrollbar-track-fill-color:var(--scrollable-track-fill-color);
            --scrollbar-thumb-border-color:var(--scrollable-thumb-border-color);
          }
        </style>

        <app-header .menu=${menu}></app-header>     

        <div class="bar">
          <div class="bar left"></div>
          <div class="bar center">
            <div class="spacer"></div>
            <div id="outlet"></div>
          </div>
          <div class="bar right"></div>
        </div>
        <app-footer-custom></app-footer-custom>
        <modal-overlay></modal-overlay>
      </scrollable-component>
      <sound-player></sound-player>
      <!-- <monitor-clipboard></monitor-clipboard> -->
    `;
  }  

  renderScreenNoScroll() {
    return html`
      <app-header .menu=${menu}></app-header>     

      <div class="bar">
        <div class="bar left"></div>
        <div class="bar center">
          <div class="spacer"></div>
          <div id="outlet"></div>
        </div>
        <div class="bar right"></div>
      </div>
      <app-footer-custom></app-footer-custom>
      <modal-overlay></modal-overlay>
      <sound-player></sound-player>
      <!-- <monitor-clipboard></monitor-clipboard> -->
    `;
  }
  

  render() {
    this.hideScrollable = this.hideScrollable || UrlParams.get('print');
    if (this.hideScrollable) {
      return this.renderScreenNoScroll();
    }
    return this.renderScreen();
  }
}

customElements.define('main-app', MainApp);

window.addEventListener('load', () => {
  document.fonts.ready.then(() => {
    const app = document.createElement('main-app');
    document.body.appendChild(app);
  });
});
