import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

import 'Components/business/llm-chat';

class LLMChatTestPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .center {
          display: flex;
          justify-content: center;
        }

        llm-chat {
          width: 100%;
          max-width:900px;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: Object },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = null;
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;llm-chat&gt;</h3>
        <div>Un composant pour dialoguer avec ollama.</div>
      </div>

      <sl-tab-group>
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div class="center">
            <llm-chat></llm-chat>
          </div>
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-llm-chat', LLMChatTestPage);