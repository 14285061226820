import { Lang } from 'Utils';
import { BaseElement, html, css } from 'Elements';

class SelectJobs extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

        select-search {
        }


      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
      help: { type: String },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.placeholder = '';
    this.help = '';
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search').value = this.value;
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  renderItems(item) {
    return html`
      <sl-menu-item type="checkbox" ?checked=${this.isSelected(item)} value="${this.getKey(item)}">
        ${this.highlight(Lang.lookup(item, 'name'), this.q)}
        <sl-tag variant="neutral" size="small" style="float:right;zoom:0.8;" part="suffix">${item.id.toUpperCase()}</sl-tag>
      </sl-menu-item>
    `
  }

  render() {
    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search
            value="${this.value}"
            display-key="name"
            separator=","
            name="${this.name}"
            hoist
            multiple
            clearable
            max-options-visible="1"
            placeholder="${this.placeholder}"
            api="private/admin/system/jobs"
            help="${this.help}"
            primary-key="id"
            @sl-change=${this.onChange}
            .renderItems=${this.renderItems}
          >         
          </select-search>
        </div>
      </div>
    `;
  }

}

customElements.define('select-jobs', SelectJobs);