import { FullPageElement, html, css, formCss } from 'Elements';
import { UrlParams } from 'Utils';

import 'Components/common/data-periods-group.js';
import 'Components/common/select-date-periods.js';

class Toolbar extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        :host {
          margin-top:10px;
        }

        sl-badge {
          font-size:0.5em;
          position:absolute;
          cursor:pointer;
        }

        .events {
          display:flex;
          flex-wrap:wrap;
          gap:5px;
          padding:5px;
        }

        sl-option::part(base) {
          line-height:initial;
          padding:0px;
          font-size:0.95em;
        }

        sl-option m-icon {
          font-size:1.2em;
          float:left;
          margin-right:5px;
        }

        .search {
          display:flex;
          flex-direction:row;
          align-items:center;
          width:100%;
          gap:10px;
          margin-bottom:2px;
        }

        .search sl-input {
          width:100%;
        }

        .filters {
          height:40px;
          min-height:40px;
          overflow:hidden;
          transition: all 0.5s;
        }

        select-date-periods {
          min-width:160px;
          min-width:160px;
        }

        select-registry {
          max-width:300px;
        }

        .filters.collapsed {
          height:0px;
          min-height:0px;
          transition: all 0.5s;
        }

        .periods {
          display:flex;
          flex-direction:row;
          gap:5px;
        }

        @media (max-width: 670px) {
          .search {
            flex-direction: column;
            gap: 5px;
          }

          select-registry {
            max-width:100%;
          }

          select-date-periods {
            min-width:initial;
            max-width:initial;
            flex:1;
          }

          .periods {
            width:100%;
            max-width:100%;
            white-space:nowrap;
            justify-content:space-between;
          }

          .header {
            flex-direction: column;
            gap: 10px;
          }
        }

      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            search_placeholder:'Search ...',
          }
        },
        french:{
          translation: {
            search_placeholder:'Rechercher ...',
          },
        }
      }
    ]
  }

  static get properties() {
    return {
      groupBy: { type: String },
      periodFilter: { type: Boolean, attribute:'period-filter' },
      victimFilter: { type: Boolean, attribute:'victim-filter' },
      groupByIcon: { type: Boolean, attribute:'group-by-icon' },
    };
  }

  constructor() {
    super();
    this.groupBy = '';
    this.groupByIcon = false;
    this.periodFilter = false;
    this.victimFilter = false;
    this.period = UrlParams.get('dp');
    this.victimId = UrlParams.get('vid');
    //this.displayFilter = this.period || (events && events.length) ? true : false;
    this.q = UrlParams.get('q');
  }
  
  visibleCallback() {
    super.visibleCallback();
    if (this.initialized) return;
    this.initialized = true;
    
    this.groupBy = this.parent?.groupBy || this.groupBy;
    
    const hasFilters = this.qs('slot').assignedElements().length;
    if (!hasFilters) return;

    const iconFilters = this.qs('m-icon[name="page_info"]');
    hasFilters ? iconFilters.style.display = 'block' : iconFilters.style.display = 'none';
    this.displayFilter = !hasFilters;
  }
   
  onSearchInputDelayed(ev) {
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.q = this.qs('sl-input[name="q"]').value;
      UrlParams.set('q', this.q);
      UrlParams.del('p');
      UrlParams.del('ds');
      UrlParams.del('de');
      UrlParams.del('dp');
      this.reloadData();
    }, 500);
  }

  onSearchInput(ev) {
    this.q = this.qs('sl-input[name="q"]').value;
    UrlParams.set('q', this.q);
    UrlParams.del('p');
    UrlParams.del('ds');
    UrlParams.del('de');
    UrlParams.del('dp');
    this.reloadData();
  }

  onPeriodChange(ev) {
    this.dateStart = ev.detail.start;
    this.dateEnd = ev.detail.end;
    this.period = ev.detail.value;
    this.dateStart ? UrlParams.set('ds', this.dateStart) : UrlParams.del('ds');
    this.dateEnd ? UrlParams.set('de', this.dateEnd) : UrlParams.del('de');
    this.period ? UrlParams.set('dp', this.period) : UrlParams.del('dp');
    this.reloadData();
  }

  onVictimChange(ev) {
    this.victimId = ev.detail;
    this.victimId ? UrlParams.set('vid', this.victimId) : UrlParams.del('vid');
    this.reloadData();
  }

  changeGrouping(ev) {
    this.groupBy = this.parent.groupBy = ev.target.value;
  }

  reloadData() {
    this.parent.reloadData();
  }
  
  toggleFilters(ev) {
    const slotFilters = this.qs('.filters');
    if (slotFilters) {
      slotFilters.classList.toggle('collapsed');
    } else {
      console.error('filters not found');
    }

    //console.log('toggleFilters', this.displayFilter);

    this.displayFilter = !this.displayFilter;
    this.qs('m-icon[name="page_info"]').pressed = this.displayFilter;
  }

  renderPeriodFilter() {
    if (!this.periodFilter) return;
    return html`<select-date-periods value=${this.period} clearable @change="${this.onPeriodChange}"></select-date-periods>`;
  }

  renderVictimFilter() {
    if (!this.victimFilter) return;
    return html`<select-registry value="${this.victimId}" hide-on-change selection="single-all" placeholder="Client" filter="structures" @change=${this.onVictimChange}></select-registry>`
  }

  renderGroupByIcon() {
    if (this.groupByIcon) {
      return html`<data-periods-group value="${this.groupBy}" @change="${this.changeGrouping}"></data-periods-group>`;
    }
  }

  render() {
    return html`
      <div class="search">
        <sl-input 
          size="small" 
          placeholder="${this._tl('search_placeholder')}" 
          name="q" 
          @sl-change=${this.onSearchInput}
          @sl-clear=${this.onSearchInput}
          clearable
          value="${this.q || ''}"
        >
          <m-icon name="search" slot="suffix"></m-icon>
        </sl-input>
        ${this.renderVictimFilter()}
        <div class="periods">
          ${this.renderPeriodFilter()}
          ${this.renderGroupByIcon()}
        </div>
        <m-icon name="page_info" ?pressed=${this.displayFilter} variant="button" style="display:none" @click="${this.toggleFilters}"></m-icon>
      </div>
      <div class="filters ${this.displayFilter ? '': 'collapsed'}"><slot></slot></div>
    `;
  }
}

customElements.define('data-search-toolbar', Toolbar);
