import { PageElement, html, css } from 'Elements';
import App from 'App';

class Error404 extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .container {
          padding:20px;
          text-align:center;
          font-size:1em;
        }

        .container m-icon {
          display:block;
          text-align:center;
          font-size:60px;
          color:var(--sl-color-danger-500);
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            title:'404 - Page not found',
            text:'The page you are looking for does not exist or no longer exists.',
            back:'Back',
          }
        },
        french:{
          translation: {
            title:'404 - Page introuvable',
            text:'La page que vous cherchez n\'existe pas ou n\'existe plus.',
            back:'Retour',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    const loc = window.location.pathname;
    this.tryToViewTicket = loc.includes('/tickets/view') || loc.includes('/ticket/view');
  }

  goLogin() {
    window.location.href = `/auth/login?kc_locale=${App.config.lg}&dark=${App.config.isDark}&url=${window.location.pathname}`;
  }

  goBack() {
    window.history.back();
  }


  render404() {
    return html`
      <section-header micon="error">${this._tl('title')}</section-header>
      <br/>
      <div class="container">
        <m-icon name="scan_delete"></m-icon>
        <p>${this._tl('text')}</p>
        <br/>
        <sl-button @click="${this.goBack}" size="medium">${this._tl('back')}</sl-button>
      </div>
    `;
  }

  renderNeedAuth() {
    return html`
      <section-header micon="lock">Authentication requise</section-header>
      <br/>
      <div class="container">
        <m-icon name="lock"></m-icon>
        <p>Vous devez être identifié pour accéder à cette page.</p>
        <br/>
        <sl-button @click="${this.goLogin}" pill variant="primary" size="medium">Connexion</sl-button>
      </div>
    `;
  }

  render() {
    return html`
      ${this.tryToViewTicket
        ? this.renderNeedAuth()
        : this.render404()
      }
    `
  }

}

customElements.define('error-not-found', Error404);