import { BaseElement, html, css, formCss } from 'Elements';
import { Fetcher } from 'Utils';

import './tree.js';

class KeycloakSystemInfo extends BaseElement {

  constructor() {
    super();
    this.data = undefined;
  }

  async load() {
    const response = await Fetcher.get('private/admin/system/keycloak/system');
    this.data = response.data;
    this.requestUpdate();
  }

  render() {
    if (!this.data) return;
    return html`<pretty-json .jsonData=${this.data}></pretty-json>`;
  }
}

customElements.define('keycloak-system-info', KeycloakSystemInfo);

class Page extends BaseElement {

  static get styles() {
    return [
      formCss,
      css`
        scrollable-component {
          height:calc(100vh - 100px);
          --scrollbar-width: 10px;
          --content-padding: 0 20px 0 0;
          --viewport-overflow-x: hidden;
        }
      `
    ]
  }

  onTabShow(e) {
    if (e.detail.name === 'system') {
      this.qs('keycloak-system-info').load();
    } else {
      this.qs('keycloak-tree').load();
    }
  }

  render() {
    return html`
      <tab-group level="k" class="main" @sl-tab-show=${this.onTabShow}>
        <sl-tab slot="nav" panel="tree">Arbre</sl-tab>
        <sl-tab slot="nav" panel="system">Système</sl-tab>

        <sl-tab-panel name="tree">
          <scrollable-component scrollbar-visibility="always">
            <keycloak-tree></keycloak-tree>
          </scrollable-component>
        </sl-tab-panel>

        <sl-tab-panel name="system">
          <scrollable-component scrollbar-visibility="always" >
            <keycloak-system-info></keycloak-system-info>
          </scrollable-component>
        </sl-tab-panel>
      </tab-group>
    `;
  }

}

customElements.define('keycloak-browser', Page);