import { BaseElement, html, css } from 'Elements';
import { Lang } from 'Utils';

class SelectExternalSos extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

        select-search {
        }


      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
      maxOptionsVisible: { type: Number, attribute: 'max-options-visible' },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.maxOptionsVisible = 2;
    this.placeholder = '';    
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search-tree').value = this.value;
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search-tree').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search-tree').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }


  _renderTag(item) {

    const getTagLabel = (item) => {
      if (item.nodeType === 'authority' || item.nodeType === 'csirt') {
        let currentItem = item;
        let parents = '';
        while (currentItem && currentItem.parentId) {
          currentItem = this.itemsById[currentItem.parentId];
          if (currentItem) {
            parents = `${Lang.lookup(currentItem, 'name')} > ${parents}`;
          }
        }
        return `${parents} ${Lang.lookup(item, 'name')}`;
      }
      return Lang.lookup(item, 'name');
    }

    return html`
      <sl-tag 
        type="primary" 
        data-id=${item.id} 
        ${this.multiple ? 'removable ': ''}
        size="small" 
        @sl-remove=${this.onRemoveTag}
      >
       ${getTagLabel(this.itemsById[item.id])}
      </sl-tag>
    `;
  }

  render() {
    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search-tree
            value="${this.value}"
            display-key="name"
            separator=","
            name="${this.name}"
            hoist
            selection="multiple"
            leaf-only
            clearable
            max-options-visible="${this.maxOptionsVisible}"
            placeholder="${this.placeholder}"
            api="public/tickets/external_sos"
            .renderTag=${this._renderTag}
            primary-key="id"
            @change=${this.onChange}
          >         
          </select-search-tree>
        </div>
      </div>
    `;
  }

}

customElements.define('select-external-sos', SelectExternalSos);