//import { Lang } from 'Utils';
import { BaseElement, html, css } from 'Elements';
import { CpeParser } from '@thefaultvault/tfv-cpe-parser';
import 'Components/business/cve-score.js';

const cpeParser = new CpeParser();


// type = vendor or product
function getCpeInfos(type, record, max = 10) {
  if (!record.nvd.cve.configurations || !record.nvd.cve.configurations.length) return '';
  let conf, node, matcher, criteria, wfn;
  //let operator, negate, nodeOperator, nodeNegate,, vunerable;
  let r = [];
  const vendorCatalog = {};

  for (conf of record.nvd.cve.configurations) {
    //operator = conf.operator; // AND
    //negate = conf.negate; // false

    for (node of conf.nodes) {
      //nodeOperator = node.operator; // OR
      //nodeNegate = node.negate; // false

      if (!node.cpeMatch) continue;

      for (matcher of node.cpeMatch) {

        if (matcher.vulnerable) {

          criteria = matcher.criteria;
          wfn = cpeParser.parse(criteria);
          const value = wfn[type];
          if (!vendorCatalog[value]) {
            vendorCatalog[value] = true;
            const valuetxt = value.replace(/\\/g,'');
            r.push({
              text:valuetxt,
              type
            });
          }
        }
      }
    }
  }

  if (r && r.length) {
    r = r.filter((item, index) => r.indexOf(item) === index);
    return r;
  }

  return [];
}

class SelectCveId extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }

        sl-tag {
          padding:2px;        
        }


      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.placeholder = '';    
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search').value = this.value;
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  renderItems(item) {
    let cpes = getCpeInfos('vendor', item) || [];
    //cpes = cpes.concat(getCpeInfos('product', item));
    
    return html`
      <sl-menu-item type="checkbox" ?checked=${this.isSelected(item)} value="${this.getKey(item)}">
        <span class="item_label">
          <span>${this.highlight(item.cveId, this.q)}</span>
          <span class="suffix">
            ${cpes.map(cpe => html`
              <sl-tag variant="${cpe.type === 'vendor' ? 'primary' : 'neutral'}" size="small" style="float:right;zoom:0.9;" part="prefix">${cpe.text}</sl-tag>
            `)}

            <cve-score score="${item.lastCvssScore}" version="${item.lastCvssVersion}" style="float:right"></cve-score>
          </span>
        </span>

      </sl-menu-item>
    `
  }

  render() {
    this.apiEndPoint = 'public/data/cve';

    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search
            value="${this.value}"
            display-key="cveId"
            separator=","
            name="${this.name}"
            hoist
            multiple
            clearable
            search-remote
            max-options-visible="10"
            placeholder="${this.placeholder}"
            api=${this.apiEndPoint}
            primary-key="cveId"
            @sl-change=${this.onChange}
            .renderItems=${this.renderItems}
          >         
          </select-search>
        </div>
      </div>
    `;
  }

}

customElements.define('select-cve-id', SelectCveId);