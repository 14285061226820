import { BaseElement, html, css } from 'Elements';

class GroupPeriod extends BaseElement {
  static get styles() {
    return [
      css`

        :host {
          padding-top:4px;
        }

        sl-radio-group {
          background-color:var(--sl-panel-background-color);
          padding:10px;
        }

        sl-radio-group::part(form-control-label) {
          font-size:0.8rem;
          padding-bottom:5px;
        }

        sl-radio {
          padding-top:4px;
          padding-bottom:4px;
          cursor:pointer;
        }

        sl-radio::part(label) {
          font-size:0.8rem;
        }

      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            none:'do not group',
            day: 'day',
            week: 'week',
            month: 'month',
            group_by: 'Group by'
          }
        },
        french:{
          translation: {
            none:'ne pas regrouper',
            day: 'jour',
            week: 'semaine',
            month: 'mois',
            group_by: 'Regrouper par'
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.value = '';
  }

  change(e) {
    this.value = e.target.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }

  render() {

    let title = this._tl('none');
    if (this.value) {
      title = `${this._tl('group_by')} ${this._tl(this.value)}`;
    }
            
    return html`
      <sl-dropdown hoist title="${title}">

        <m-icon
          name="calendar_view_day"
          variant="button"
          slot="trigger"
          ?pressed=${this.value}
        ></m-icon>

        <sl-radio-group size="small" value="${this.value}" @sl-change=${this.change} label="${this._tl('group_by')} ...">
          <sl-radio value="" ?checked=${this.value === ''}>${this._tl('none')}</sl-radio>
          <sl-radio value="day" ?checked=${this.value === 'day'}>${this._tl('day')}</sl-radio>
          <sl-radio value="week" ?checked=${this.value === 'week'}>${this._tl('week')}</sl-radio>
          <sl-radio value="month" ?checked=${this.value === 'month'}>${this._tl('month')}</sl-radio>
        </sl-radio-group>
        
      </sl-dropdown>
    `;
  }
}

customElements.define('data-periods-group', GroupPeriod);
