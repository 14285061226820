import SelectSimple from 'Components/common/select-simple.js';

class SelectCustomersNodetypes extends SelectSimple {

  static get properties() {
    return {
      ntype: { type: String },
      displayFields: { type: Function },
      search: { type: Boolean },
    }
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            no_item:'No human available, please add some.',
          },
        },
        french:{
          translation: {
            no_item:'Aucun humain disponible, veuillez en ajouter.',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiPrefix = 'private/registry/ntype';
    this.itemTitleField = 'title';
    this.noItemMessage = 'Aucun disponible, veuillez en ajouter.';
  }

  async updated(changedProperties) {
    if (changedProperties.has('ntype')) {
      this.apiEndpoint = `${this.apiPrefix}/${this.ntype}`;
    }
  }
}

customElements.define('select-customers-nodetype', SelectCustomersNodetypes);