import { Session } from 'Utils';

class RoutesManager {
  constructor(router) {

    this.router = router;
    this.buildRoutes = this.buildRoutes.bind(this);

    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isBoss = this.isBoss.bind(this);
    this.isSoc = this.isSoc.bind(this);

    if (document.location.pathname === '/p/v/e') {
      document.location.href = '/public/validation/email'+document.location.search;
      return;
    }

    if (document.location.pathname === '/p/v/m') {
      document.location.href = '/public/validation/mobile'+document.location.search;
      return;
    }

    this.publicRoutes = {
      '/public':                                    { component: 'page-home',                         title:'SysDream CSIRT/CERT' },
      '/public/cti':                                { component: 'page-cti',                          title:'Traitement des incidents Cybersécurité' },
      '/public/cti/create':                         { component: 'page-cti-edit',                     title:'Traitement des incidents Cybersécurité' },
      '/public/maps':                               { component: 'page-maps',                         title:'Maps' },
      '/public/maps/ddosia':                        { component: 'page-map-ddosia',                   title:'NoName057(16) DDoSia' },
      '/public/mondossier/etape1':                  { component: 'page-dossier-etape1',               title:'Mon dossier' },
      '/public/mondossier/etape2':                  { component: 'page-dossier-etape2',               title:'Mon dossier' },
      '/public/mondossier/etape3':                  { component: 'page-dossier-etape3',               title:'Mon dossier' },
      '/public/mondossier/etape4':                  { component: 'page-dossier-etape4',               title:'Vérification de votre email' },
      '/public/mondossier/etape5':                  { component: 'page-dossier-etape5',               title:'Accès à votre dossier autorisé' },
      '/public/mondossier/consulter/:hash':         { component: 'page-dossier-consulter',            title:'Votre dossier' },
      '/public/notifications':                      { component: 'page-notifications',                title:'Notifications' },
      '/public/user/logout':                        { component: 'page-user-logout',                  title:'Déconnexion' },
      '/public/forbidden':                          { component: 'error-forbidden',                   title:'Accès refusé' },
      '/public/errors/503':                         { component: 'error-maintenance',                 title:'Service indisponible' },
      '/public/validation/email':                   { component: 'page-validation-email',             title:'Validation email' },
      '/public/validation/mobile':                  { component: 'page-validation-mobile',            title:'Validation mobile' },
    };

    this.authenticatedRoutes = {
      '/private':                                   { component: 'page-user-home',                    title:'Mon espace',                   action:this.isAuthenticated },
      '/private/user/account':                      { component: 'page-user-account',                 title:'Mon compte',                   action:this.isAuthenticated },

      '/private/doc':                               { component: 'page-doc',                          title:'Documentations',               action:this.isAuthenticated },
      '/private/doc/faq':                           { component: 'page-doc-faq',                      title:'FAQ' },

      '/private/my/tickets/draft':                  { component: 'page-tickets-list',                 title:'Mes tickets',                  action:this.isAuthenticated },
      '/private/my/tickets/draft/edit/:hash':       { component: 'page-tickets-edit',                 title:'Editer le ticket',             action:this.isAuthenticated },
      '/private/my/tickets/draft/create':           { component: 'page-tickets-edit',                 title:'Créer ...',                    action:this.isAuthenticated },
      '/private/my/tickets':                        { component: 'page-my-tickets-list',              title:'Mes tickets',                  action:this.isAuthenticated },
      '/private/my/tickets/view/:hash':             { component: 'page-my-tickets-view',             title:'Ticket',                       action:this.isAuthenticated },
      '/private/my/tickets/edit/:hash':             { component: 'page-my-tickets-view',             title:'Ticket',                       action:this.isAuthenticated },
    }

    this.adminRoutes = {
      '/private/admin':                             { component: 'page-admin-home',                   title:'Administration',               action:this.isBoss },
      '/private/admin/registry':                    { component: 'page-admin-registry',               title:'Gestion clients',              action:this.isBoss },
      '/private/admin/registry/tree':               { component: 'page-admin-registry-tree',          title:'Arborescence',                 action:this.isBoss },
      '/private/admin/sys/grants_profiles':         { component: 'page-sys-grants-profiles',          title:'Profils de permissions',       action:this.isBoss },

      '/private/admin/sys/languages':               { component: 'page-sys-languages',                title:'Gestion des langues',          action:this.isBoss },
      '/private/admin/sys/languages/list':          { component: 'page-sys-languages-list',           title:'Langues du site',              action:this.isBoss },
      '/private/admin/sys/holidays':                { component: 'page-sys-holidays-list',            title:'Jours fériés',                 action:this.isBoss },
      '/private/admin/sys/naf':                     { component: 'page-sys-naf',                      title:'Codes NAF',                    action:this.isBoss },
      '/private/admin/sys/jobs':                    { component: 'page-sys-jobs',                     title:'Métiers',                      action:this.isBoss },
      '/private/admin/sys/external_sos':            { component: 'page-sys-external-sos',             title:'Organisations externes',       action:this.isBoss },
      '/private/admin/sys/templates':               { component: 'page-sys-tpl',                      title:'Modèles',                      action:this.isBoss },
      '/private/admin/sys/templates/email':         { component: 'page-sys-tpl-email',                title:'Email templates',              action:this.isBoss },
      '/private/admin/sys/templates/sms':           { component: 'page-sys-tpl-sms',                  title:'SMS templates',                action:this.isBoss },
      '/private/admin/sys/languages/translate':     { component: 'page-sys-mass-translate',           title:'Traduction',                   action:this.isBoss },
      '/private/admin/sys/events':                  { component: 'page-sys-events',                   title:'Evènements',                   action:this.isBoss },
      '/private/admin/sys/logs':                    { component: 'page-sys-logs',                     title:'Logs',                         action:this.isBoss },
      '/private/admin/sys/sessions':                { component: 'page-sys-sessions',                 title:'Sessions',                     action:this.isBoss },
      '/private/admin/sys/backup_restore':          { component: 'page-sys-backup-restore',           title:'Sauvegarde & restauration',    action:this.isBoss },
      '/private/admin/sys/notifications':           { component: 'page-sys-notifications',            title:'Notifications',                action:this.isBoss },
      '/private/admin/sys/monitor':                 { component: 'page-sys-srv-monitor',              title:'Santé du serveur',             action:this.isBoss },
      '/private/admin/sys/mongodb':                 { component: 'page-sys-srv-mongodb',              title:'MongoDB',                      action:this.isBoss },
      '/private/admin/sys/opensearch':              { component: 'page-sys-srv-opensearch',           title:'Opensearch',                   action:this.isBoss },
      '/private/admin/sys/redis':                   { component: 'page-sys-srv-redis',                title:'Redis',                        action:this.isBoss },

      '/private/dev':                               { component: 'page-dev-home',                     title:'Espace développeur',           action:this.isDev },
      '/private/dev/entities':                      { component: 'page-entities',                     title:'Entités',                      action:this.isBoss },
      '/private/dev/rt':                            { component: 'page-request-tracker',              title:'Request Tracker',              action:this.isBoss },
      '/private/dev/conference':                    { component: 'page-conference',                   title:'Conference',                   action:this.isDev },
      '/private/dev/conference/:uid':               { component: 'page-conference-live',              title:'Conference',                   action:this.isDev },

    }

    this.socRoutes = {
      '/private/soc':                               { component: 'page-soc-home',                     title:'Espace SOC',                   action:this.isSoc },
      /* old */
      '/private/soc/alert/:hash/edit':              { component: 'page-dossier-consulter',            title:'Alerte',                       action:this.isSoc },
      '/private/soc/alert/dashboard':               { component: 'page-alerts-dashboard',             title:'Dashboard',                    action:this.isSoc },
      /* new */
      '/private/soc/tickets':                       { component: 'page-soc-tickets-list',             title:'Dashboard',                    action:this.isSoc },
      '/private/soc/tickets/view/:hash':            { component: 'page-soc-tickets-view',             title:'Ticket',                       action:this.isSoc },
      '/private/soc/tickets/edit/:hash':            { component: 'page-soc-tickets-view',             title:'Ticket',                       action:this.isSoc },
      '/private/soc/settings':                      { component: 'page-soc-settings-home',            title:'Paramètres',                   action:this.isSoc },
      '/private/soc/settings/countermeasures':      { component: 'page-soc-countermeasures',          title:'Contre mesures',               action:this.isSoc },
      '/private/soc/settings/classifications':      { component: 'page-soc-classifications',          title:'Classifications',              action:this.isSoc },
      '/private/probe':                             { component: 'page-probe-home',                   title:'Investigations',               action:this.isSoc },
      '/private/probe/afnic':                       { component: 'page-probe-afnic',                  title:'AFNIC',                        action:this.isSoc },
    }

    this.devRoutes = {
      '/private/dev/api':                             { component: 'page-doc-api',                      title:'API',                          action:this.isDev },
      '/private/dev/errors':                          { component: 'error-list',                        title:'Messages d\'erreur',           action:this.isDev },
      '/private/dev/components':                      { component: 'page-tests-ux',                     title:'Composants UX',                action:this.isDev },
      '/private/dev/components/modal-dialog':         { component: 'page-tests-modal-dialog',           title:'modal-dialog',                 action:this.isDev },
      '/private/dev/components/modal-drawer':         { component: 'page-tests-modal-drawer',           title:'modal-drawer',                 action:this.isDev },
      '/private/dev/components/reveal':               { component: 'page-tests-reveal',                 title:'RevealJS',                     action:this.isDev },
      '/private/dev/components/section-header':       { component: 'page-tests-section-header',         title:'section-header',               action:this.isDev },
      '/private/dev/components/sl-tab-group':         { component: 'page-tests-sl-tab-group',           title:'sl-tab-group',                 action:this.isDev },
      '/private/dev/components/tab-group':            { component: 'page-tests-tab-group',              title:'tab-group',                    action:this.isDev },
      '/private/dev/components/i18n':                 { component: 'page-tests-i18n',                   title:'i18n',                         action:this.isDev },
      '/private/dev/components/pretty-json':          { component: 'page-tests-pretty-json',            title:'pretty-json',                  action:this.isDev },
      '/private/dev/components/context-menu':         { component: 'page-tests-context-menu',           title:'context-menu',                 action:this.isDev },
      '/private/dev/components/apex-charts':          { component: 'page-tests-apexcharts',             title:'apex-charts',                  action:this.isDev },
      '/private/dev/components/cherry-editor':        { component: 'page-tests-cherry-editor',          title:'cherry-editor',                action:this.isDev },
      '/private/dev/components/select-enabler':       { component: 'page-tests-select-enabler',         title:'select-enabler',               action:this.isDev },
      '/private/dev/components/select-permissions':   { component: 'page-tests-select-permissions',     title:'select-permissions',           action:this.isDev },
      '/private/dev/components/select-date-periods':  { component: 'page-tests-select-date-periods',    title:'select-date-periods',          action:this.isDev },
      '/private/dev/components/select-search':        { component: 'page-tests-select-search',          title:'select-search',                action:this.isDev },
      '/private/dev/components/select-search-tree':   { component: 'page-tests-select-search-tree',     title:'select-search-tree',           action:this.isDev },
      '/private/dev/components/select-severity':      { component: 'page-tests-select-severity',        title:'select-severity',              action:this.isDev },
      '/private/dev/components/select-jobs':          { component: 'page-tests-select-jobs',            title:'select-jobs',                  action:this.isDev },
      '/private/dev/components/select-classification':{ component: 'page-tests-select-classification',  title:'select-classification',        action:this.isDev },
      '/private/dev/components/select-external-sos':  { component: 'page-tests-select-external-sos',    title:'select-external-sos',          action:this.isDev },
      '/private/dev/components/select-registry':      { component: 'page-tests-select-registry',        title:'select-registry',              action:this.isDev },
      '/private/dev/components/select-naf':           { component: 'page-tests-select-naf',             title:'select-naf',                   action:this.isDev },
      '/private/dev/components/select-cve-id':        { component: 'page-tests-select-cve-id',          title:'select-cve-id',                action:this.isDev },
      '/private/dev/components/data-periods-group':   { component: 'page-tests-data-periods-group',     title:'data-periods-group',           action:this.isDev },
      '/private/dev/components/data-paginator':       { component: 'page-tests-data-paginator',         title:'data-paginator',               action:this.isDev },
      '/private/dev/components/input-business-hours': { component: 'page-tests-input-business-hours',   title:'input-business-hours',         action:this.isDev },
      '/private/dev/components/range-numbers':        { component: 'page-tests-range-numbers',          title:'range-numbers',                action:this.isDev },
      '/private/dev/components/contract-sla':         { component: 'page-tests-contract-sla',           title:'contract-sla',                 action:this.isDev },
      '/private/dev/components/toast-editor':         { component: 'page-tests-toast-editor',           title:'toast-editor',                 action:this.isDev },
      '/private/dev/components/image-bank':           { component: 'page-tests-image-bank',             title:'image-cropperbank',            action:this.isDev },
      '/private/dev/components/cve-score':            { component: 'page-tests-cve-score',              title:'cve-score',                    action:this.isDev },
      '/private/dev/components/llm-chat':             { component: 'page-tests-llm-chat',               title:'llm-chat',                     action:this.isDev },
      '/private/dev/components/radial-progress':      { component: 'page-tests-radial-progress',        title:'radial-progress',              action:this.isDev },
    }

    this.comRoutes = {
      '/private/com':                                 { component: 'page-com-home',                     title:'Communication',                action:this.isCom },
      '/private/com/newsletters':                     { component: 'page-com-newsletters',              title:'Newsletters',                  action:this.isCom },
      '/private/com/images':                          { component: 'page-com-images',                   title:'Images',                       action:this.isCom },
    }

    this.cusRoutes = {
    }

    // because we can not specify title of the webapp while installing it on IOS
    if (ENV === 'dev') {
      this.authenticatedRoutes['/private/doc/faq'].title = 'SysDreamIO DD';
    } else if (ENV === 'preprod') {
      this.authenticatedRoutes['/private/doc/faq'].title = 'SysDreamIO PP';
    } else {
      this.authenticatedRoutes['/private/doc/faq'].title = 'SysDreamIO';
    }

    this.buildRoutes();
    window.addEventListener('session-refreshed', this.buildRoutes);
  }

  async buildRoutes() {

    if (this.routes) return;

    let routes = this.menuToRoutes(this.publicRoutes);
    routes.animate = true;

    await import(/* webpackChunkName: "common" */ 'Components/common/index.js');

    if (Session.isAuthenticated()) {
      await import(/* webpackChunkName: "authed" */ 'Components/authenticated/index.js');
      routes = routes.concat(this.menuToRoutes(this.authenticatedRoutes));
    }

    if (Session.isBoss()) {
      await import(/* webpackChunkName: "boss" */ 'Components/boss/index.js');
      routes = routes.concat(this.menuToRoutes(this.adminRoutes));
    }

    if (Session.isSoc()) {
      await import(/* webpackChunkName: "soc" */ 'Components/boss/index.js');
      routes = routes.concat(this.menuToRoutes(this.socRoutes));
    }

    if (Session.isDev()) {
      await import(/* webpackChunkName: "dev" */ 'Components/boss/index.js');
      routes = routes.concat(this.menuToRoutes(this.devRoutes));
    }

    if (Session.isCom()) {
      await import(/* webpackChunkName: "dev" */ 'Components/boss/index.js');
      await import(/* webpackChunkName: "com" */ './pages/private/com/index.js');
      routes = routes.concat(this.menuToRoutes(this.comRoutes));
    }

    if (Session.isCus()) {
      await import(/* webpackChunkName: "cus" */ 'Components/boss/index.js');
      routes = routes.concat(this.menuToRoutes(this.cusRoutes));
    }

    // redirect / to /public
    routes.unshift({ path: '/', redirect: '/public' });

    // handle 404
    routes.push({ path: '(.*)', title: 'Page non trouvée', action: (ctx, cmd) => {
      if (ctx.pathname.includes('admin')) {
        return cmd.component('error-forbidden');
      }
      return cmd.component('error-not-found');
    } });

    this.routes = routes;
    this.router.setRoutes(this.routes);
  }


  menuToRoutes(menu, parentPath  = '') {
    return Object.entries(menu).flatMap(([path, item]) => {
      const fullPath = parentPath+path;
      const route = { path: fullPath };
      if (item.title) route.title = item.title;
      if (item.component) route.component = item.component;
      if (item.redirect) route.redirect = item.redirect;
      if (item.action) route.action = item.action;
      route.animate = true;

      if (item.pages) {
        const childRoutes = this.menuToRoutes(item.pages, fullPath);
        return [route, ...childRoutes];
      } else {
        return [route];
      }
    });
  }

  isAuthenticated(ctx, cmd) {
    if (!Session.isAuthenticated()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isBoss(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isBoss()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }


  isSoc(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isSoc()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isDev(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isDev()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isCom(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isCom()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isCus(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isCus()) {
      return cmd.component('error-forbidden');
    }
    return true;
  }

  isSocOrCus(ctx, cmd) {
    if (!Session.isAuthenticated() || !Session.isCus() || !Session.isSoc() || !Session.isBoss()) {
      console.log(ctx, cmd);
      return cmd.component('error-forbidden');
    }
    return true;
  }

}

export default RoutesManager;
