import { FullPageElement, html, css, formCss } from 'Elements';
import { UrlParams } from 'Utils';
import config from './config.js';

class Filters extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        :host {
          margin-top:5px;
        }

        sl-option::part(base) {
          line-height:initial;
          padding:0px;
          font-size:0.95em;
        }

        sl-option m-icon {
          font-size:1.2em;
          float:left;
          margin-right:5px;
        }

        .filters {
          display:flex;
          align-items:center;
          justify-content:space-around;
          gap:5px;
          width:100%;
        }

        .filters .left {
          display:flex;
          justify-content: space-between;
          align-items:baseline;
          width:100%;
        }

        .filters .right {
          display:flex;
          align-items:center;
          justify-content:flex-end;
          gap:5px;
          width:100%;
        }

        @media (max-width: 670px) {
          .filters {
            flex-direction: column;
            gap: 10px;
          }
        }

      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      config.translations,
      {
        english:{
          translation: {
            placeholder_from:'From',
            placeholder_level:'Level',
          }
        },
        french:{
          translation: {
            placeholder_from:'Provenance',
            placeholder_level:'Niveau',
          },
        }
      }
    ]
  }

  constructor() {
    super();
    const levels = UrlParams.get('level');
    this.selectedLevels = levels ? levels.replace(/,/g, ' ') : '';
    if (!this.selectedLevels) {
      this.selectedLevels = 'info warn error';
      UrlParams.set('level', this.selectedLevels.replace(/ /g, ','));
    }

    const froms = UrlParams.get('from');
    this.selectedFroms = froms ? froms.replace(',', ' ') : '';   
  }

  onLevelsChange(ev) {
    this.selectedLevels = this.qs('sl-select[name="levels"]').value.join(',');
    this.selectedLevels.length ? UrlParams.set('level', this.selectedLevels) : UrlParams.del('level');
    UrlParams.del('p');
    this.reloadData();
  }

  onFromsChange(ev) {
    this.selectedFroms = this.qs('sl-select[name="froms"]').value.join(',');
    this.selectedFroms.length ? UrlParams.set('from', this.selectedFroms) : UrlParams.del('from');
    UrlParams.del('p');
    this.reloadData();
  }

  reloadData() {
    this.parent.reloadData();
  }

  render() {    
    return html`
      <div class="filters">
        <div class="right">
          <sl-select 
            hoist 
            max-options-visible="1" 
            placeholder="${this._tl('placeholder_from')}" 
            style="width:190px" 
            multiple 
            clearable 
            size="small" 
            name="froms" 
            value="${this.selectedFroms}"
            @sl-change="${this.onFromsChange}"
          >
            ${Object.keys(config.froms).map(key => {
              const from = config.froms[key];
              return html`
                <sl-option size="small" value="${key}">
                  <sl-tag size="small" variant="${config.froms[key].color}">${key.toUpperCase()}</sl-tag>
                </sl-option>
              `})}
          </sl-select>

          <sl-select 
            hoist 
            max-options-visible="0" 
            placeholder="${this._tl('placeholder_level')}" 
            style="width:400px" 
            multiple 
            clearable 
            size="small" 
            name="levels" 
            value="${this.selectedLevels}"
            @sl-change="${this.onLevelsChange}"
          >
            ${Object.keys(config.levels).map(key => {
              const level = config.levels[key];
              return html`
                <sl-option size="small" value="${key}">
                  <sl-tag size="small" variant="${config.levels[key].color}">${key.toUpperCase()}</sl-tag>
                </sl-option>
              `})}
          </sl-select>

        </div>
      </div>
    `;
  }
}

customElements.define('sys-logs-filters', Filters);
