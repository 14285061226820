
const VALIDATION_STATUS_ICON = {
  email:{
    validated: 'mark_email_read',
    waiting: 'mark_email_unread',
    none: 'mail_off',
    error:'unsubscribe',
    not_validated:'mail_off',
    undefined:'mail_off'
  },
  mobile:{
    validated: 'mobile_friendly',
    waiting: 'devices_wearables',
    none: 'mobile_off',
    expired: 'phonelink_lock', // phonelink_erase // phonelink_setup
    error:'security_update_warning',
    not_validated:'mobile_off',
    undefined:'mobile_off'
  }
};

const VALIDATION_FIELDS = [
  'validation_email_primary_status',
  'validation_email_secondary_status',
  'validation_mobile_primary_status',
  'validation_mobile_secondary_status'
];

const apiEndPoint = 'private/registry/tree';
const urlVar = 'idn';
const eventUpdated = 'registry-updated';

export default {
  VALIDATION_STATUS_ICON,
  VALIDATION_FIELDS,
  apiEndPoint,
  urlVar,
  eventUpdated
}