import { BaseElement, PageElement, html, css } from 'Elements';
import styles from './styles.css.js';

class RadialProgressPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        .diag {
          display:flex;
          justify-content:center;
          align-items:center;
          gap:10px;
        }

        .diag div {
          font-size:0.9rem;
          min-width:200px;
        }

        radial-progress {
          width:50px;
          height:50px;
        }
      `
    ]
  }

  constructor() {
    super();
    this.debug = false;
  }

  visibleCallback() {
    this.qs('.random radial-progress', true).forEach((el) => {
      this.timer = setInterval(() => {
        el.value1 = Math.floor(Math.random() * 110);
        el.value2 = Math.floor(Math.random() * 110);
      }, 1000);
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    clearInterval(this.timer);
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;radial-progress&gt;</h3>
        <div>Afficheur status et limite temporelle (%)</div>
      </div>

      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>

        <sl-tab-panel name="component">
          <section-header size="medium">En attente de prise en charge</section-header>
          <br/>
          <div class="flex">
            <div class="diag">
              <radial-progress value1=20 value2=0></radial-progress>
              <div>reste %80 du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=51 value2=0></radial-progress>
              <div>reste %49 du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=76 value2=0></radial-progress>
              <div>reste 24% du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=110 value2=0></radial-progress>
              <div>Dépassement prise en charge</div>
            </div>
            <div class="diag random">
              <radial-progress value1=-10 value2=0></radial-progress>
              <div>Aléatoire</div>
            </div>
          </div>
          <br/><br/>

          <section-header size="medium">En attente première réponse suite à prise en charge dans les temps</section-header>
          <br/>
          <div class="flex">
            <div class="diag">
              <radial-progress value1=20 value2=20></radial-progress>
              <div>reste %80 du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=20 value2=51></radial-progress>
              <div>reste %49 du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=20 value2=76></radial-progress>
              <div>reste 24% du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=20 value2=110></radial-progress>
              <div>Dépassement première réponse</div>
            </div>
            <div class="diag random">
              <radial-progress value1=20 value2=0></radial-progress>
              <div>Aléatoire</div>
            </div>
          </div>
          <br/><br/>

          <section-header size="medium">En attente première réponse suite à prise en charge un peu tardive</section-header>
          <br/>
          <div class="flex">
            <div class="diag">
              <radial-progress value1=80 value2=20></radial-progress>
              <div>reste %80 du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=80 value2=51></radial-progress>
              <div>reste %49 du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=80 value2=76></radial-progress>
              <div>reste 24% du temps imparti</div>
            </div>
            <div class="diag">
              <radial-progress value1=80 value2=110></radial-progress>
              <div>Dépassement première réponse</div>
            </div>
            <div class="diag random">
              <radial-progress value1=80 value2=0></radial-progress>
              <div>Aléatoire</div>
            </div>
          </div>
          <br/><br/>
        </sl-tab-panel>

      </sl-tab-group>
      
    `;
  }
}

customElements.define('page-tests-radial-progress', RadialProgressPage);