import SelectSimple from 'Components/common/select-simple.js';
import { Lang } from 'Utils';

class SelectContactType extends SelectSimple {

  constructor() {
    super();
    this.items = [
      { _id:'contact',        name:{ fr:'Contact simple (pas d\'accès plateforme)', en:'Simple contact (no platform access)' } },
      { _id:'account',        name:{ fr:'Compte utilisateur (accès plateforme)', en:'User account (platform access)' } },
    ]
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-contact-type', SelectContactType);