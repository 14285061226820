import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';
import 'Components/business/cve-score.js';

class CveScorePage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
        }

        .flex > * {
          width:100%;
        }

        b {
          font-size:2em;
          height:40px;
        }
      `
    ]
  }

  constructor() {
    super();
    this.debug = false;
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;cve-score&gt;</h3>
        <div>Afficheur de score CVE</div>
      </div>

      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>

        <sl-tab-panel name="component">
          <div>
            <cve-score score="x" version="3.1"></cve-score>
            <cve-score score="0" version="3.1"></cve-score>
            <cve-score score="1" version="3.1"></cve-score>
            <cve-score score="2" version="3.1"></cve-score>
            <cve-score score="3" version="3.1"></cve-score>
            <cve-score score="4" version="3.1"></cve-score>
            <cve-score score="5" version="3.1"></cve-score>
            <cve-score score="6" version="3.1"></cve-score>
            <cve-score score="7" version="3.1"></cve-score>
            <cve-score score="8" version="3.1"></cve-score>
            <cve-score score="9" version="3.1"></cve-score>
            <cve-score score="10" version="3.1"></cve-score>
          </div>
        </sl-tab-panel>

      </sl-tab-group>
      
    `;
  }
}

customElements.define('page-tests-cve-score', CveScorePage);