import { html, css } from 'Elements';
import DrawerForm from 'Components/abstract/DrawerForm.js';
import config from './config';

class GrantProfilEdit extends DrawerForm {
  
  static get styles() {
    return [
      super.styles,
      css`
        box-styled {
          font-size:0.9em;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            add:'Create a permission profile  ',
            name:'Name',
            general:'General',
            id:'ID',
          },
        },
        french:{
          translation: {
            add:'Créer un profil de permission',
            name:'Nom',
            general:'Général',
            id:'ID',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.eventUpdated = config.eventUpdated;
    this.getTranslatePayload = this.getTranslatePayload.bind(this);
    this.itemTitleField = 'title';
    this._logEnable = false;
  }

  getTranslatePayload() {
    const payload = {
      name: this._lookupField('name').value
    }
    return payload;
  }

  onTranslated(ev) {
    this._lookupField('name', 'en').value = ev.detail.title;
  }

  handleSubmitError(input, response) {
    if (!input) {
      console.warn('Field not found', response.field);
      return;
    }

    let msg = 'Valeur obligatoire';
    if (response.reason === 'MIN_LENGTH') {
      msg = `Longueur minimum de ${response.minValue} caractères`;
    }

    if (input.setCustomValidity) input.setCustomValidity(msg);
    if (input.reportValidity) input.reportValidity();
  }

  render() {    
    if (!this.item) return '';

    return html`
      <modal-drawer @close=${this.onClose}>
        <style>${this.constructor.styles}</style>
        <form>
          <tab-group level="tl" class="main">
            <sl-tab slot="nav" panel="text">${this._tl('general')}</sl-tab>

            <sl-tab-panel name="text">

              <tab-group-lg level="lg" .item=${this.item} .getTranslatePayload=${this.getTranslatePayload} @translated=${this.onTranslated}>
                <sl-input size="small" name="name" class="labol" label="${this._tl('name')}"></sl-input><br/>
              </tab-group-lg>
              <br/><br/>

            </sl-tab-panel>
          </tab-group>
        </form>

        <sl-button slot="bt1" variant="primary" @click="${this.handleSubmit}">${this._tl('save')}</sl-button>
        <sl-button slot="bt2" variant="text" close="true">${this._tl('cancel')}</sl-button>
        
      </modal-drawer>

    `;
  }

}

customElements.define('grant-profile-edit', GrantProfilEdit);