import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';
import 'Components/business/toast-editor.js';

class ToastEditorPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        pre {
          padding:20px;
          margin:10px;
          font-size:0.9em;
          border:1px solid var(--sl-color-neutral-300)
        }

        p {
          font-size:1.2em;
          margin-left:40px;
        }

        .flex {
          display:flex;
          align-items:center
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String }
    };
  }

  constructor() {
    super();
    this.value = '**test**';
  }

  onChange(e) {
    this.value = e.target.value;
  }

  render() {
    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>

      <div class="title">
        <h3>&lt;toast-editor&gt;</h3>
        <div>Un wrapper on top of <a href="https://nhn.github.io/tui.editor/latest/" target="toast">ToastUI Editor</a>.</div>
      </div>
   
      <sl-tab-group>
        
        <sl-tab slot="nav" panel="tab1">Composant</sl-tab>
        <sl-tab slot="nav" panel="tab2">Status</sl-tab>
        <sl-tab-panel name="tab1">
          <toast-editor height="50vh" value=${this.value} placeholder="Placeholder" @input=${this.onChange}></toast-editor>
          <toast-viewer height="50vh"></toast-viewer>
        </sl-tab-panel>

        <sl-tab-panel name="tab2">
          Toast UI est un éditeur markdown.<br/><br/>
        </sl-tab-panel>

      </sl-tab-group>

      <br/><br/>
    `;
  }

}

customElements.define('page-tests-toast-editor', ToastEditorPage);