import { BaseElement, html, css } from 'Elements';

class CveScore extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          --border-width:1px;
        }

        sl-badge::part(base) {
          border:var(--border-width) solid !important;
          border-radius:0px;
          padding:3px 4px;
          text-shadow:1px 1px 2px #222222;
          min-width:2.5em;
          color:white;
          font-size:1.05em;
          font-weight:bold;
        }

        .none::part(base) {
          background: linear-gradient(180deg, #24b667 0%, #199552 100%);
          border:var(--border-width) solid #199552 !important;
        }

        .low::part(base) {
          background: linear-gradient(180deg, #ffd154 0%, #dda202 100%);
          border:var(--border-width) solid #dda202 !important;
        }

        .medium::part(base) {
          background: linear-gradient(180deg, #ffa253 0%, #fd7500 100%);
          border:var(--border-width) solid #fd7500 !important;
        }

        .high::part(base) {
          background: linear-gradient(180deg, #f83838 0%, #a21313 100%);
          border:var(--border-width) solid #a21313 !important;
        }

        .critical::part(base) {
          background: linear-gradient(180deg, #95000e 0%, #4d0108 100%);
          color:white;
          border:var(--border-width) solid #4d0108 !important;
        }

        .unknow::part(base) {
          background-color:#495154;
          border:var(--border-width) solid #495154 !important;
        }

        .animated {
          margin-right:0px;
          width:0px;
          overflow:hidden;
          transition:width 0.2s, margin-right 0.2s;
        }

        sl-badge:hover .animated {
          margin-right:4px;
          width:4em;
        }

        sl-tooltip::part(body) {
          font-size:0.8em;
        }

        .text {
          text-transform:uppercase;
        }
        
      `
    ];
  }

  static get properties() {
    return {
      score: { type: Number },
      version: { type: String },
      variant: { type: String },
    };
  }

  static get translations() {
    return [
      {
        english:{
          translation: {
            unknow:'N/A',
            none:'N/A',
            low:'low',
            medium:'medium',
            high:'high',
            critical:'critical',
            criticity:'Severity',
          },
        },
        french:{
          translation: {
            unknow:'N/A',
            none:'N/A',
            low:'low',
            medium:'medium',
            high:'high',
            critical:'critical',
            criticity:'Sévérité',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.score = undefined;
    this.variant = '';
  }

  getCvssLevel(score) {
    let css = '';
    if (score === 0) {
      css = 'none';
    } else if (score>=0 && score <= 3.9) {
      css = 'low';
    } else if (score >= 4.0 && score <= 6.9) {
      css = 'medium';
    } else if (score >= 7.0 && score <= 8.9) {
      css = 'high';
    } else if (score > 8.9) {
      css = 'critical';
    }
    return css;
  }


  render() {
    if (typeof this.score === 'undefined') return;

    const css = this.getCvssLevel(this.score);
    const title = `${this._tl('criticity')}: ${this._tl(css)} ${this.version ? `(CVSS v${this.version})` : ''}`;
    
    return html`
      <sl-badge class="${css}" title="${title}">
        ${this.variant ==='text' 
          ? html`<span class="text">${this._tl(css)}</span><span class="animated">${this.score}</span>`
          : html`${this.score}`
        }
        
      </sl-badge>
    `;
  }

}

customElements.define('cve-score', CveScore);