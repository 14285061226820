import { PageElement, unsafeHTML, html, css } from 'Elements';
import { Session } from 'Utils';

import './user';
import './admin';
import './soc';
import './doc';
import './dev';
import './probe';
import './my';

class Page extends PageElement {
  static get styles() {
    return [
      super.styles,
      css`
        .grid {
          display:grid;
          grid-template-columns: repeat(5, 1fr); /* Crée 3 colonnes de taille égale */
          margin:10px;
          gap:20px;
        }

        @media (max-width: 670px) {
          .grid {
            display:grid;
            grid-template-columns: repeat(2, 1fr); /* Crée 3 colonnes de taille égale */
            margin:10px;
          }
        }

        box-styled[variant="warning"] {
          font-size:0.8rem;
          text-transform:initial !important;
          padding:0px !important;
          height:initial !important;
          overflow:hidden;
          opacity:0;
          transition:opacity 0.5s;
          margin-bottom:20px;
        }

        box-styled[variant="warning"] div {
          height:initial !important;
          padding:0px;
          overflow:hidden;
        }

        box-styled[variant="warning"].expanded {
          opacity:1;
        }

        a-route {
          color:var(--sl-color-primary-800);
          font-weight:bold;
        }
      `
    ];
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation: {
            title: 'My Space',
            admin: 'Administration',
            soc: 'SOC space',
            dev: 'Developer space',
            investigation:'Investigation space',
            doc: 'Documentations',
            com:'Communications',
            low_auth: 'You have authenticated using your email and password. <a-route href="/private/user/account#security_auth">Activate two-factor authentication</a-route> for more security.',
            my_tickets: 'My tickets',
            my_draft: 'My drafts',
            my_security_events: 'My security events'
          },
        },
        french: {
          translation: {
            title: 'Mon espace',
            admin: 'Administration',
            soc: 'SOC',
            dev: 'Développeur',
            investigation:'Investigations',
            com:'Communication',
            doc: 'Documentations',
            low_auth: 'Vous vous êtes authentifié à l\'aide de votre email et mot de passe. <a-route href="/private/user/account#security_auth">Activez la double authentification</a-route> pour plus de sécurité.',
            my_tickets: 'Mes tickets',
            my_draft: 'Mes brouillons',
            my_security_events: 'Mes événements de sécurité'
          }
        }
      }
    ];
  }

  constructor() {
    super();
    this.layout = '';
  }

  renderAuthLevel() {
    if (Session.authMethod === 'form') {
      setTimeout(() => {
        this.qs('box-styled[variant="warning"]').classList.add('expanded');
      }, 10);
      return html`<box-styled slot="right" micon="warning" variant="warning"><div>${unsafeHTML(this._tl('low_auth'))}</div></box-styled>`;
    }
  }

  renderMySecurity() {
    //if (!Session.isCus()) return;
    return html`
      <section-header size="medium" micon="security">${this._tl('my_security_events')}</section-header>
      <br/>
      <div class="grid">
        <!-- <box-styled layout="${this.layout}" micon="bar_chart_4_bars" aroute="./my/tickets/dashboard">Tableau de bord</box-styled> -->
        <box-styled layout="${this.layout}" micon="event_note" aroute="./my/tickets">${this._tl('my_tickets')}</box-styled>
        <!-- <box-styled layout="${this.layout}" micon="edit_square" aroute="./my/tickets/draft">${this._tl('my_draft')}</box-styled> -->
      </div>
      <br/>
    `
  }

  renderSpaces() {
    if (Session.isCus()) return;
    return html`
      <section-header size="medium" micon="linked_services">Espaces internes</section-header><br/>
      <div class="grid">      
        ${Session.isBoss() 
          ? html`<box-styled layout="${this.layout}" micon="admin_panel_settings" aroute="/private/admin">${this._tl('admin')}</box-styled>` 
          : '' 
        }
        
        ${Session.isSoc() 
          ? html`<box-styled layout="${this.layout}" micon="shield" aroute="/private/soc">${this._tl('soc')}</box-styled>` 
          : '' 
        }

        ${Session.isCom() 
          ? html`<box-styled layout="${this.layout}" micon="text_to_speech" aroute="/private/com">${this._tl('com')}</box-styled>` 
          : ''
        }

        ${Session.isDev() 
          ? html`<box-styled layout="${this.layout}" micon="developer_board" aroute="/private/dev">${this._tl('dev')}</box-styled>` 
          : '' 
        }

        ${Session.isSoc() 
          ? html`
            <box-styled layout="${this.layout}" micon="policy_alert" aroute="/private/probe">
              ${this._tl('investigation')}
              <sl-badge slot="badge" variant="neutral">beta</sl-badge>
            </box-styled>
            `
          : ''
        }
      </div>
      <br/>
    `
  }

  renderHelp() {
    return html`
      <section-header size="medium" micon="help">Aide</section-header><br/>
      <div class="grid">
        <box-styled layout="${this.layout}" micon="quiz" aroute="/private/doc">${this._tl('doc')}</box-styled>
        <!--
        <box-styled layout="${this.layout}" micon="contact_support" aroute="/private/help/contact">Contactez-nous</box-styled>
        <box-styled layout="${this.layout}" micon="feedback" aroute="/private/help/feedback">Feedback</box-styled>
        -->
      </div>
    `
  }
  
  render() {

    return html`
      <section-header img="assets/img/monespace.png">${this._tl('title')}</section-header>
      <br/>
      ${this.renderAuthLevel()}
      ${this.renderMySecurity()}
      ${this.renderSpaces()}
      ${this.renderHelp()}
      <br/><br/>
    `;
  }

}

customElements.define('page-user-home', Page);