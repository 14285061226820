import { BaseElement, html, css } from 'Elements';
import App from 'App';

class SoundPlayer extends BaseElement {
  static get styles() {
    return [
      css`
        a-route {
          color: var(--sl-color-primary-500);
        }
      `
    ];
  }

  constructor() {
    super();
    this.playSound = this.playSound.bind(this);
    this.playAudioBuffer = this.playAudioBuffer.bind(this);
    this.resumeAudioContext = this.resumeAudioContext.bind(this);

    // Initialiser l'AudioContext et l'état
    this.audioContext = null;
    this.audioBuffers = {};
    this.soundQueue = [];
    this.isProcessing = false;

    // Écouter l'événement personnalisé 'playsound'
    window.addEventListener('playsound', this.playSound);
    window.addEventListener('pointerdown', this.resumeAudioContext);
  }

  connectedCallback() {
    super.connectedCallback();
    this.modal = this.qs('modal-dialog');
    this.createAudioContext();
  }

  createAudioContext() {
    try {
      // Créer l'AudioContext
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

      // Charger les fichiers audio
      this.audioSources = {
        info: 'assets/sounds/1.mp3',
        warn: 'assets/sounds/2.mp3',
        error: 'assets/sounds/3.mp3',
        debug: 'assets/sounds/3.mp3'
      };

      // Précharger tous les sons
      this.preloadAudio();
    } catch (e) {
      console.error('Erreur lors de la création de l\'AudioContext:', e);
    }
  }

  async preloadAudio() {
    for (const [name, url] of Object.entries(this.audioSources)) {
      this.audioBuffers[name] = await this.loadAudioBuffer(url);
    }
  }

  async loadAudioBuffer(url) {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return await this.audioContext.decodeAudioData(arrayBuffer);
  }

  async playSound(ev) {
    const enabled = localStorage.getItem(App.config.localKeys.sound) || false;
    if (enabled && this.audioContext) {
      const soundName = ev.detail || 'info';
      if (this.audioBuffers[soundName]) {
        // Ajouter le son à la file d'attente
        this.soundQueue.push(soundName);
        this.processQueue();
      } else {
        console.warn(`Son "${soundName}" non trouvé.`);
      }
    }
  }

  async processQueue() {
    if (this.isProcessing) return;

    this.isProcessing = true;

    while (this.soundQueue.length > 0) {
      const soundName = this.soundQueue.shift();
      const audioBuffer = this.audioBuffers[soundName];

      if (this.audioContext.state === 'suspended') {
        this.modal = this.modal || this.qs('modal-dialog');
        if (this.modal) this.modal.show();
        this.isProcessing = false;
        return;
      }

      // Jouer le son sans attendre sa fin
      this.playAudioBuffer(audioBuffer);

      // Délai de 100 ms avant de lancer le prochain son
      await new Promise(resolve => setTimeout(resolve, 30));
    }

    this.isProcessing = false;
  }

  playAudioBuffer(audioBuffer) {
    const source = this.audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(this.audioContext.destination);

    try {
      source.start(0);
    } catch (e) {
      if (this.audioContext.state === 'suspended') {
        this.modal = this.modal || this.qs('modal-dialog');
        if (this.modal) this.modal.show();
      }
      console.error(e);
    }
  }

  async resumeAudioContext() {
    if (!this.audioContext) {
      this.createAudioContext();
      return;
    }

    this.modal = this.modal || this.qs('modal-dialog');
    if (this.audioContext.state === 'running') {
      this.modal.hide();
      return;
    }

    try {
      await this.audioContext.resume();
      console.log('AudioContext repris avec succès.');
    } catch (e) {
      console.error('Erreur lors de la reprise de l\'AudioContext:', e);
    }
  }

  render() {
    return html`
      <modal-dialog>
        Le son ne peut pas être joué, car une interaction utilisateur est nécessaire.
        <br /><br />
        Veuillez cliquer sur le bouton ci-dessous pour activer le son.
        <br /><br />
        <sl-button @click=${this.resumeAudioContext} close="true" variant="primary">
          Activer le son
        </sl-button>
      </modal-dialog>
    `;
  }
}

customElements.define('sound-player', SoundPlayer);
