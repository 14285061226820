import { FullPageElement, html, css } from 'Elements';

//import 'Components/ticket/ticket-edit.js';

class CtiEditPage extends FullPageElement {
  render() {
    return html`
      <tickets-edit-page></tickets-edit-page>
      <br/>
    `;
  }
}

customElements.define('page-cti-edit', CtiEditPage);