import { BaseElement, html, css, formCss } from 'Elements';
import { Lang, Fetcher } from 'Utils';

class HardfangThreats extends BaseElement {
  static get styles() {
    return [
      formCss,
      css`        
        .loading {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          padding-top:20px;
        }

        sl-spinner {
          font-size: 7rem;
        }
      
        table {
          width: 100%;
          border-collapse: collapse;
          background-color: var(--sl-panel-background-color);
          border-color: var(--sl-panel-border-color);
        }

        td {
          font-size: 0.9em;
        }

        .selectors {
          width: 100%;
          display:flex;
        }

        .selectors div {
          display: flex;
          width:100%;
          gap: 10px;
        }

        .selectors div:last-child {
          padding-top: 5px;
          text-align: right;
          display: block;
          width: 100%;
        }

        m-icon[name="sync"] {
          margin-right: 10px;
        }

        #numberOfResultsSelector {
          max-width: 100px;
        }

        .count {
          padding-left: 5px;
          padding-top:10px;
          padding-bottom:10px;
          font-size: 0.9em;
        }

        @media (max-width: 1000px) {
          .selectors {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
          }

          .selectors div {
            flex-direction: column;
          }
        }

      `
    ];
  }

  static get properties() {
    return {
      loading: { type: Boolean },
      nodeId: { type: String },
      url: { type: String },
      status: { type: String },
      level: { type: String },
      numberOfResults: { type: Number },
    };
  }

  static get translations() {
    return [
      super.translations,
      {
        english: {
          translation:{
            refresh: 'Refresh',
            select_all: 'Select all',
            no_data: 'No results',
            create_tickets: 'Create corresponding tickets',
            create_tickets_confirm: 'Create {{count}} ticket(s)?',
            cancel: 'Cancel',
            create: 'Create',
          }
        },
        french: {
          translation:{
            refresh: 'Rafraîchir',
            select_all: 'Tout sélectionner',
            no_data: 'Aucun résultat',
            create_tickets: 'Créer les tickets correspondants',
            create_tickets_confirm: 'Créer {{count}} ticket(s) ?',
            cancel: 'Annuler',
            create: 'Créer',
          }
        }
      }
    ]
  }

  constructor() {
    super();
    this.nodeId = null;
    this.threats = [];
    this.status = '';
    this.level = '';
    this.loading = true;
    this.numberOfResults = 1;

    this.selectAll = this.selectAll.bind(this);
  }

  async visibleCallback() {
    super.visibleCallback();
    await this.refreshThreats();
  }

  async refreshThreats() {
    const bt = this.qs('#refreshThreats');
    if (bt.loading) return;
    bt.loading = true;

    const url = `private/registry/tree/${this.nodeId}/edr/refresh/threats`;
    const payload = {
      status: this.status.replace(/ /g, ','),
      level: this.level.replace(/ /g, ','),
      numberOfResults: this.numberOfResults,
    };

    const response = await Fetcher.put(url, payload);
    this.loading = false;
    bt.loading = false;
    if (response && response.ok) {
      this.threats = response.data;
      this.threatsCount = response.count;
      //window.dispatchEvent(new CustomEvent(config.eventUpdated, { detail: response.data }));
    }
  }

  renderLoading() {
    if (!this.loading) return '';
    return html`
      <div class="loading">
        <sl-spinner></sl-spinner>
      </div>
    `;
  }

  renderStatus(status) {
    const statusColors = {
      'new': 'danger',
      'investigating': 'primary',
      'false_positive': 'warning',
      'closed': 'success',
    }

    return html`<sl-badge variant="${statusColors[status]}">${status}</sl-badge>`;
  }

  renderLevel(level) {
    // @TODO: use ticket colors
    const levelColors = {
      'low': 'neutral',
      'medium': 'warning',
      'high': 'danger',
      'critical': 'danger',
    }

    return html`<sl-badge variant="${levelColors[level]}">${level}</sl-badge>`;
  }

  renderThreats() {
    if (this.loading) return '';

    if (this.threats.length === 0) {
      return html`<div class="empty">${this._tl('no_data')}</div>`;
    }

    return html`
      <div class="count">${this.threatsCount} threats</div>

      ${this.threats.map(t => {

        const rulesTitle = [];
        t.top_rules.map(r => rulesTitle.push(r.rule_msg));

        return html`
          <table border="1" cellpadding="5" cellspacing="0">
            <tbody>
            <tr>
              <td width="70">
                <sl-checkbox value="${t.id}" @click=${this.refreshCreateTicketButton}></sl-checkbox>&nbsp;
                <b><a href="${this.url}/threat/${t.id}/summary" target="_blank">#${t.id}</a></b>
              </td>
              <td width="50">${this.renderStatus(t.status)}</td>
              <td width="70">${this.renderLevel(t.level)}</td>
              <td width="150">${Lang.formatDate(t.creation_date, 'fulls')}</td>
              <td>Security Events: ${t.total_security_event_count}</td>
              <td width="55">Agents: ${t.agent_count}</td>
            </tr>
            <tr>
              <td colspan="6">
                <ul>
                  ${rulesTitle.map(r => html`<li>${r}</li>`)}
                </ul>
              </td>
            </tr>
          </tbody>
        </table><br/>
        `
      })}
      <br/><br/><br/>
    `;
  }

  async onStatusChange(e) {
    this.status = e.target.value.join(' ');
    await this.refreshThreats();
    this.requestUpdate();
  }

  async onLevelChange(e) {
    this.level = e.target.value.join(' ');
    await this.refreshThreats();
    this.requestUpdate();
  }

  async onResultsNumberChange(e) {
    this.numberOfResults = e.target.value;
    await this.refreshThreats();
    this.requestUpdate();
  }

  selectAll() {
    const cks = this.qs('sl-checkbox', true);
    this.selectMe = !this.selectMe;
    if (cks) cks.forEach(cb => cb.checked = this.selectMe);
    this.refreshCreateTicketButton();
  }

  refreshCreateTicketButton() {
    const cks = this.qs('sl-checkbox', true);
    const bt = this.qs('#createTicket');
    if (!cks || !cks.length) {
      bt.disabled = true;
      return;
    }

    let disabled = true;
    for (const cb of cks) {
      if (cb.checked) {
        disabled = false;
        break;
      }
    }

    bt.disabled = disabled;
  }

  renderStatusSelector() {
    return html`
      <sl-select size="small"
        id="statusSelector"
        max-options-visible=4
        placeholder="Status"
        clearable
        value="${this.status}"
        multiple @sl-change=${this.onStatusChange}
        style="min-width:435px"
      >
        <sl-option value="new">New</sl-option>
        <sl-option value="investigating">Investigating</sl-option>
        <sl-option value="false_positive">False Positive</sl-option>
        <sl-option value="closed">Closed</sl-option>
      </sl-select>
    `;
  }

  renderLevelSelector() {
    return html`
      <sl-select size="small"
        id="levelSelector"
        max-options-visible=3
        placeholder="Level"
        clearable
        value="${this.level}"
        multiple
        @sl-change=${this.onLevelChange}
        style="min-width:280px"
      >
        <sl-option value="low">Low</sl-option>
        <sl-option value="medium">Medium</sl-option>
        <sl-option value="high">High</sl-option>
      </sl-select>
    `;
  }

  renderResultsNumber() {
    return html`
      <sl-select size="small" id="numberOfResultsSelector" value="${this.numberOfResults}" @sl-change=${this.onResultsNumberChange}>
        <sl-option value="1">1</sl-option>
        <sl-option value="10">10</sl-option>
        <sl-option value="100">100</sl-option>
      </sl-select>
    `;
  }

  createTicketsConfirm() {

    const cks = this.qs('sl-checkbox', true);
    this.selected = [];
    for (const cb of cks) {
      if (cb.checked) this.selected.push(cb.value);
    }
    if (!this.selected.length) return;

    const modal = this.qs('#modal-dialog-create-tickets');
    modal.show();

    const p = modal.qs('p');
    p.innerHTML = this._tl('create_tickets_confirm', { count:this.selected.length });
  }

  async createTickets() {

    const bt = this.qs('#createTicket');
    if (bt.loading) return;
    bt.loading = true;

    const url = `private/registry/tree/${this.nodeId}/edr/refresh/threats`;
    const response = await Fetcher.put(url, { createTickets:true, threatsIds:this.selected });
    bt.loading = false;
    console.log(response);
  }



  render() {
    return html`
      <div class="selectors">
        <div>
          ${this.renderStatusSelector()}
          ${this.renderLevelSelector()}
          ${this.renderResultsNumber()}
        </div>
        <div>
          <m-icon variant="button" disabled name="event_note" id="createTicket" title="${this._tl('create_tickets')}" @click="${this.createTicketsConfirm}"></m-icon>
          <m-icon variant="button" name="select_all" id="selectAll" title="${this._tl('select_all')}" @click="${this.selectAll}"></m-icon>
          &nbsp;
          <m-icon variant="button" name="sync" id="refreshThreats" title="${this._tl('refresh')}" @click="${this.refreshThreats}"></m-icon>
        </div>
      </div>
      ${this.renderLoading()}
      <scrollable-component scrollbar-visibility="always">
        ${this.renderThreats()}
      </scrollable-component>
      <br/><br/><br/>
      <modal-dialog id="modal-dialog-create-tickets">
        <p></p>
        <sl-button slot="bt1" variant="text" close="true">${this._tl('cancel')}</sl-button>
        <sl-button slot="bt2" variant="primary" close="true" @click=${this.createTickets}>${this._tl('create')}</sl-button>
      </modal-dialog>

    `;
  }
}

customElements.define('harfang-threats', HardfangThreats);