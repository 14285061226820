import { BaseElement, html, css, highlight } from 'Elements';

class SelectClassification extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          font-size:0.9em;
          display:flex;
          align-items:center;
          gap:20px;
          width:100%;
        }

        div.label {
          white-space:nowrap;
        }

        .form-control {
          display:flex;
          flex:1;
        }

        .input {
          display:flex;
          align-items:center;
          gap:5px;
          padding:1px;
          margin:1px;
          width:100%;
          flex:1;
        }


      `
    ];
  }

  static get properties() {
    return {
      value: { type: String },
      name: { type: String },
      placeholder: { type: String },
      label: { type: String },
      readonly: { type: Boolean },
      search: { type: String },
    };
  }

  constructor() {
    super();
    this.name = '';
    this.label = '';
    this.placeholder = '';
    this.search = '';
    this.readonly = false;
  }

  updated(changedProperties) {
    if (changedProperties.has('value')) {
      this.qs('select-search-tree').value = this.value;
    }
  }

  setCustomValidity(msg) {
    this.qs('select-search-tree').setCustomValidity(msg);
  }

  reportValidity() {
    this.qs('select-search-tree').reportValidity();
  }

  onChange(ev) {
    this.value = ev.detail.value;
    this.dispatchEvent(new CustomEvent('change', { detail: this.value }));
  }
  
  _renderTag(item) {
    return html`
      <sl-tag 
        type="primary" 
        data-id=${item[this.primaryKey]} 
        ${this.multiple ? 'removable ': ''}
        size="small" 
        @sl-remove=${this.onRemoveTag}
      >
       ${this.getLabel(this.itemsById[item.parentId])} / ${this.getLabel(this.itemsById[item[this.primaryKey]])}
      </sl-tag>
    `;
  }

  _renderTreeItems(items) {
    const iconCss = 'float:left;font-size:1em;margin-top:2px;margin-right:3px;';
    return items
      .map(item => {
        
        const icon = item.parentId 
          ? html`<m-icon name="sell" style="${iconCss};color:${item.color}"></m-icon>` 
          : html`<m-icon name="label"  style="${iconCss};color:${item.color}"></m-icon>`;

        return html`
          <sl-tree-item expanded class="tree-item ${item.hidden ? 'hidden' : ''}" ?selected=${this.isSelected(item)} value="${item[this.primaryKey]}" .item=${item}>
            <span class="tree_label">
              <span>
                ${icon}
                ${highlight(this.getLabel(item), this.q)}
              </span>
            </span>            
            ${item.children?.length ? this.renderTreeItems(item.children) : ''}
          </sl-tree-item>
        `
      });
  }

  renderReadOnly() {
    return html`
      <select-search-tree
        value="${this.value}"
        ?readonly=${this.readonly}
        display-key="title"
        name="${this.name}"
        placeholder="${this.placeholder}"
        separator=","
        parent-show
        parent-separator=": "
        parent-include
        api="public/tickets/classifications"
        primary-key="_id"
        .renderTreeItems=${this._renderTreeItems}
        .renderTag=${this._renderTag}
        @change=${this.onChange}
      >
      </select-search-tree>
    `;
  }

  render() {

    if (this.readonly) {
      return this.renderReadOnly();
    }

    return html`
      <div part="form-control" class="form-control form-control--small form-control--has-label">
        ${this.label 
          ? html`
            <label id="label" part="form-control-label" class="form-control__label" aria-hidden="false">
              <slot name="label">${this.label}</slot>
            </label>
            `
          : ''
        }
        <div class="input">
          <select-search-tree
            value="${this.value}"
            ?readonly=${this.readonly}
            selection="leaf"
            display-key="title"
            name="${this.name}"
            placeholder="${this.placeholder}"
            clearable
            hide-on-change
            separator=","
            search="${this.search}"
            parent-show
            parent-separator=": "
            parent-include
            api="public/tickets/classifications"
            primary-key="_id"
            .renderTreeItems=${this._renderTreeItems}
            .renderTag=${this._renderTag}
            @change=${this.onChange}
          >
          </select-search-tree> 
        </div>
      </div>
    `;
  }

}

customElements.define('select-classification', SelectClassification);