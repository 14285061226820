import SelectSimple from 'Components/common/select-simple.js';
import { Lang } from 'Utils';

class SelectStructureType extends SelectSimple {

  constructor() {
    super();
    this.items = [
      { _id:'organization',   name:{ fr:'Organisation', en:'Organizarion' } },
      { _id:'affiliate',      name:{ fr:'Affilié', en:'Affilié' } },
      { _id:'company',        name:{ fr:'Entreprise', en:'Company' } },
      { _id:'bl',             name:{ fr:'Business Line', en:'Business Line' } },
      { _id:'bu',             name:{ fr:'Business Unit', en:'Business Unit' } },
    ]
    this.itemTitleField = 'name';
  }

  displayFieldsValue(item) {
    return Lang.lookup(item,'name');
  }

}

customElements.define('select-structure-type', SelectStructureType);