import Fetcher from './Fetcher';
import Sleep from './Sleep.js';
import Socket from './Socket.js';
import dayjs from 'dayjs';

class SessionManager {
  constructor() {
    this.session = null;
    this.previousSession = null;
    this.loading = false;
    this.loaded = false;
    this.refreshTimeoutId = null;
    this.sockets = {};

    this.reload = this.reload.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);

    window.addEventListener('session-force-refresh', this.reload);
    window.addEventListener('blur', this.onVisibilityChange);
    window.addEventListener('focus', this.onVisibilityChange);
    document.addEventListener('visibilitychange', this.onVisibilityChange);

    this.permissions = {
      ticket:{
        'view':['BOSS','IOADM','SOC','SOCMGR','SOCANA', 'CUS'],
        'manage':['BOSS','IOADM','SOC', 'SOCMGR','SOCANA'],
        'edit':['BOSS','IOADM','SOC', 'SOCMGR','SOCANA', 'CUS'],
        'editDeclarant':['BOSS','IOADM','SOCMGR','SOC', 'SOCANA'],
        'delete':[]
      }
    }
  }

  onVisibilityChange() {
    Fetcher.sendBeacon('beacon/status', { hidden: document.hidden || !document.hasFocus() });
  }

  async get() {
    if (this.session) {
      return this.session;
    }

    if (this.loading) {
      console.log('Session is already loading, waiting... 10000 ??');
      await Sleep(10000);
    }

    this.loading = true;
    const response = await Fetcher.get('public/user/session');
    if (!response && this.previousSession?.email) {
      const event = new CustomEvent('session-expired', { detail: this.session });
      window.dispatchEvent(event);
      return;
    }
    this.session = response?.data || {};
    this.loading = false;
    this.loaded = true;
    
    const event = new CustomEvent('session-refreshed', { detail: this.session });
    window.dispatchEvent(event);
    this.scheduleSessionRefresh();
    this.checkSockets();
    return this.session;
  }

  checkSockets() {
    if (!this.session) return;

    if (this.isAuthenticated()) {

      this.sockets.me = this.sockets.me || Socket.subscribe('/main');

      this.sockets.me.onAny((event, data) => {
        if (event === 'room-joined') {
          console.log('room-joined', data.room);
          return;
        }

        if (!event.startsWith('system')) {
          //console.log('socket event', event, data);
        }

        // send custom event
        const customEvent = new CustomEvent(event, { detail: data });
        window.dispatchEvent(customEvent);
      });

      this.sockets.me.emit('join-rooms');
    }
  }

  scheduleSessionRefresh() {
    if (this.session?.exp) {
      
      const currentTime = dayjs().unix();
      const delay = (this.session.exp - currentTime + 2) * 1000; // add 2 secs to be sure

      if (this.refreshTimeoutId) {
        clearTimeout(this.refreshTimeoutId);
      }

      // setup new refresh
      this.refreshTimeoutId = setTimeout(() => this.reload(), delay);

      //const refreshDate = dayjs.unix(this.session.exp + 2).format('YYYY-MM-DD HH:mm:ss');
      //console.log(`Le prochain rafraîchissement de la session est prévu pour : ${refreshDate}`, delay);
    }
  }

  async reload() {
    this.previousSession = { ...this.session };
    delete this.session;
    return await this.get();
  }

  getLogoutUrl() {
    const realm = this.session?.realm_url || this.previousSession?.realm_url;
    if (!realm) {
      document.location.href = '/';
      return;
    }
    let url = realm+'/protocol/openid-connect/logout';
    url+= '?post_logout_redirect_uri='+encodeURIComponent(HOME_URL);
    return url;
  }

  isAuthenticated() {
    return this.session?.email?.length > 0;
  }

  email() {
    return this.session?.email;
  }

  hasGrants(grant) {
    if (Array.isArray(grant)) {
      return grant.some(g => this.hasGrants(g));
    }

    if (grant === 'authenticated') {
      return this.isAuthenticated();
    }
    
    return this.session?.roles?.includes(grant);
  }

  isBoss() {
    return this.hasGrants(['BOSS','IOADM']);
  }

  isDev() {
    return this.hasGrants(['BOSS','IOADM','DEV']);
  }

  isSoc() {
    return this.hasGrants(['BOSS','IOADM','SOC', 'SOCMGR', 'SOCANA']);
  }

  isPrivileged() {
    return this.hasGrants(['BOSS','IOADM','SOC','SOCANA']);
  }

  isCom() {
    return this.hasGrants(['BOSS','IOADM','COM']);
  }

  isCus() {
    return this.hasGrants(['CUS']);
  }

  hasPermission(topic, action) {
    if (!this.permissions[topic]) {
      throw new Error(`hasPermission: unknown topic "${topic}"`);
    }

    const actions = this.permissions[topic];
    if (!actions[action]) {
      throw new Error(`hasPermission: topic "${topic}": unknown action "${action}"`);
    }

    const grants = actions[action];
    if (!grants.length) return false;

    return grants.some(g => this.hasGrants(g));
  }


  get authMethod() {
    if (!this.session.amr) return;
    return this.session.amr[0];
  }
}

const sessionManager = new SessionManager();

export default sessionManager;