import { Session } from 'Utils';
import i18next from 'i18next';
import App from 'App';

function getNodeTypes() {

  const _i18nInstance = i18next.createInstance();
  _i18nInstance.init({
    resources: {
      english:{
        translation: {
          root:'Root',
          general: 'General',
          permissions: 'Permissions',
          contract: 'Contract',
          contract_availability:'Availability and SLA',
          contract_sla:'SLA',
          contract_sla_help:'Service Level Agreement (SLA) in minutes',
          contract_bhours:'Business / non-business hours',
          properties: 'Properties',
          debug: 'Debug',
          contacts: 'Contacts',
          contact: 'Contact',
          pgp: 'PGP',
          pgp_primary_public_key: 'Primary PGP public key',
          validation: 'Validation',
          nodeType: 'Node type',
          parent: 'Parent',
          services: 'Services',
          files_exchange: 'Files exchange',
          monitoring:'Monitoring',
          newsletter:'Newsletter',
          mail_leaks:'Mail leaks',
          emails_validation: 'Emails validation',
          authorization: 'Platform authorizations',
          roles: 'Roles',
          name: 'Name',
          address: 'Address',
          code_siret: 'SIRET',
          code_naf: 'NAF code',
          notes: 'Notes',
          favicon_url: 'Favicon URL',
          enable: 'Enabled',
          url: 'URL',
          token: 'Token',
          group: 'Group',
          template_primary_email: 'Primary email template',
          template_secondary_email: 'Secondary email template',
          renew_interval: 'Renew interval',
          follow_up_interval: 'Follow up interval',
          follow_up_max_retry: 'Follow up max retry',
          mobiles_validation: 'Mobiles validation',
          template_primary_sms: 'Primary SMS mobile template',
          template_secondary_sms: 'Secondary SMS mobile template',
          email_addresses: 'Email addresses',
          email_primary: 'Primary email',
          email_secondary: 'Secondary email',
          mobiles_phones: 'Mobile phones',
          mobile_primary: 'Primary mobile',
          mobile_secondary: 'Secondary mobile',
          landlines: 'Landlines',
          landline_primary: 'Primary landline',
          landline_secondary: 'Secondary landline',
          label:'Label',
          structure_name: 'Name',
          structure: 'Structure',
          structure_type: 'Type',
          images: 'Images',
          affiliation:'Affiliation',
          entity:'Entity',
          user_accounts:'User accounts',
          user_account:'User account',
          account_type:'Contact type',
          groups:'Groups',
          group_name:'Group name',
          folder:'Folder',
          folder_name:'Folder name',
          users:'Users',
          gender:'Gender',
          firstname:'Firstname',
          lastname:'Lastname',
          job:'Positions',
          identity:'Identity',
          domain_name:'Domain name',
          assets:'Assets',
          assets_technical:'Max number of technical assets',
          assets_human:'Max number of human assets',
          network:'Network',
          ip_addr:'IP address',
          network_as:'Autonomous System (AS)',
          brand:'Brand',
          edr:'EDR',
          monitoring_email_leaks:'Email leaks monitoring',
          monitoring_email_leaks_domains:'Domains',
          monitoring_email_leaks_help:'List of email domains, one domain per line. Example: \'example.com example.org\'',
          business_hours:'Business hours',
        }
      },
      french:{
        translation: {
          root:'Racine',
          general: 'Général',
          permissions: 'Permissions',
          properties: 'Propriétés',
          contract: 'Contrat',
          contract_availability:'Disponibilité et SLA',
          contract_sla:'SLA',
          contract_sla_help:'Service Level Agreement (SLA) en minutes',
          contract_bhours:'Périodes ouvrées / non ouvrées',
          debug: 'Debug',
          contacts: 'Contacts',
          contact: 'Contact',
          pgp: 'PGP',
          pgp_primary_public_key: 'Clé publique PGP principale',
          validation: 'Validation',
          nodeType: 'Type de noeud',
          parent: 'Parent',
          newsletter:'Newsletter',
          mail_leaks:'Fuite emails',
          services: 'Services',
          files_exchange: 'Echange de fichiers',
          emails_validation: 'Validation des emails',
          monitoring:'Surveillance',
          authorization: 'Autorisations plateforme',
          roles: 'Rôles',
          name: 'Nom',
          address: 'Adresse',
          code_siret: 'SIRET',
          code_naf: 'Code NAF',
          notes: 'Notes',
          favicon_url: 'URL Favicon',
          enable: 'Activé',
          url: 'URL',
          token: 'Token',
          group: 'Groupe',
          template_primary_email: 'Template email principal',
          template_secondary_email: 'Template email secondaire',
          renew_interval: 'Intervalle de renouvellement',
          follow_up_interval: 'Intervalle de relance',
          follow_up_max_retry: 'Nombre maximum de relance',
          mobiles_validation: 'Validation des mobiles',
          template_primary_sms: 'Template SMS mobile principal',
          template_secondary_sms: 'Template SMS mobile secondaire',
          email_addresses: 'Adresses email',
          email_primary: 'Email principal',
          email_secondary: 'Email secondaire',
          mobiles_phones: 'Téléphones mobiles',
          mobile_primary: 'Mobile principal',
          mobile_secondary: 'Mobile secondaire',
          landlines: 'Lignes fixes',
          landline_primary: 'Ligne fixe principale',
          landline_secondary: 'Ligne fixe secondaire',
          label:'Libellé',
          structure_name: 'Nom',
          structure_type: 'Type',
          structure: 'Structure',
          images: 'Images',
          affiliation:'Affiliation',
          entity:'Entité',
          user_accounts:'Comptes utilisateur',
          user_account:'Compte utilisateur',
          account_type:'Type de contact',
          groups:'Groupes',
          group_name:'Nom du groupe',
          folder:'Dossier',
          folder_name:'Nom du dossier',
          users:'Utilisateurs',
          gender:'Genre',
          firstname:'Prénom',
          lastname:'Nom',
          job:'Métiers / Fonctions',
          identity:'Identité',
          domain_name:'Nom de domaine',
          assets:'Actifs sous contrat',
          assets_technical:'Nombre d\'actifs techniques',
          assets_human:'Nombre d\'actifs humains',
          network:'Réseau',
          ip_addr:'Adresse IP',
          network_as:'Système Autonome (AS)',
          brand:'Marque',
          edr:'EDR',
          monitoring_email_leaks:'Surveillance des leaks email',
          monitoring_email_leaks_domains:'Domaines',
          monitoring_email_leaks_help:'Liste des domaines emails, un domaine par ligne. Exemple: \'example.com example.org\'',
          business_hours:'Heures ouvrées',
        }
      }
    },
    fallbackLng: App.config.language,
    interpolation: { escapeValue: false }
  });

  function _tl(key) {
    if (!_i18nInstance) return key;
    const txt = _i18nInstance.t(key);
    if (txt === key) {
      console.warn(`translation not found for key '${key}' '${App.config.language}'`);
      //console.log(JSON.stringify(this._i18nResources, null, 4));
    }
    return txt;
  }

  //const hidden = ENV.match(/prod/) ? 'hidden' : '';
  const hidden = 'hidden';

  const commonNodeTypeField = { component:'sl-input',   label:_tl('nodeType'),  name:'nodeType',    sameDefaultValue: true, css: `${hidden}` };
  const commonParentField =   { component:'sl-input',   label:_tl('parent'),    name:'parentId',    sameDefaultValue: true, css: `${hidden}` };
  const commonEnabledField =  { component:'sl-switch',  label:_tl('enable'),    name:'enabled',     defaultValue:true };

  const commonDebugProperties = {
    name:'debug',
    title:_tl('debug')+' <m-icon name="lock"></m-icon>',
    hidden:() => !Session.isBoss(),
    fields:[
      { component:'box-json' },
    ]
  }

  const commonPermissions = {
    name:'permissions',
    title:_tl('permissions'),
    hidden:() => !Session.isBoss(),
    css:'labol_medium',
    fields:[
      { component:'section-header',     label:_tl('authorization'),           size:'small', bold:true },
      { component:'select-io-roles',    label:_tl('roles'),                   name:'iora_roles' },

      /*
      { component:'section-header',     label:_tl('services'),                size:'small', bold:true },
      { component:'select-permissions', label:_tl('newsletter'),              name:'iora_service_newsletter' },
      { component:'select-permissions', label:_tl('files_exchange'),          name:'iora_service_files_exchange' },

      { component:'section-header',     label:_tl('monitoring'),              size:'small', bold:true },
      { component:'select-permissions', label:'Ddosia',                       name:'iora_monitoring_ddosia' },
      { component:'select-permissions', label:'Telegram',                     name:'iora_monitoring_telegram' },
      { component:'select-permissions', label:'Afnic',                        name:'iora_monitoring_afnic' },
      { component:'select-permissions', label:_tl('mail_leaks'),              name:'iora_monitoring_email_leaks' },
      */
    ]
  }

  const commonUserValidation = {
    name:'userValidation',
    title:_tl('validation'),
    css:'labol_large',
    fields:[
      { component:'section-header',                         label:_tl('emails_validation'),           size:'small', bold:true },

      { component:'select-enabler',                         label:_tl('enable'),                      name:'iora_email_validation_required' },
      { component:'select-validation-email-template',       label:_tl('template_primary_email'),      name:'iora_email_primary_validation_templateId' },
      { component:'select-validation-email-template',       label:_tl('template_secondary_email'),    name:'iora_email_secondary_validation_templateId' },
      { component:'select-validation-period-interval',      label:_tl('renew_interval'),              name:'iora_email_validation_renew_interval' },
      { component:'select-validation-period-interval',      label:_tl('follow_up_interval'),          name:'iora_email_validation_retry_interval' },
      { component:'select-validation-followup',             label:_tl('follow_up_max_retry'),         name:'iora_email_validation_retry_max' },
      { component:'input-range-numbers',                    label:_tl('business_hours'),              name:'iora_email_validation_business_hours' },
      { component:'blank' },

      { component:'section-header',                         label:_tl('mobiles_validation'),          size:'small', bold:true  },

      { component:'select-enabler',                         label:_tl('enable'),                      name:'iora_mobile_validation_required' },
      { component:'select-validation-sms-template',         label:_tl('template_primary_sms'),        name:'iora_mobile_primary_validation_templateId' },
      { component:'select-validation-sms-template',         label:_tl('template_secondary_sms'),      name:'iora_mobile_secondary_validation_templateId' },
      { component:'select-validation-period-interval',      label:_tl('renew_interval'),              name:'iora_mobile_validation_renew_interval' },
      { component:'select-validation-period-interval',      label:_tl('follow_up_interval'),          name:'iora_mobile_validation_retry_interval' },
      { component:'select-validation-followup',             label:_tl('follow_up_max_retry'),         name:'iora_mobile_validation_retry_max' },
      { component:'input-range-numbers',                    label:_tl('business_hours'),              name:'iora_mobile_validation_business_hours' },
    ]
  }

  const commonTickets = {
    name:'ticket',
    title:'Tickets',
    css:'labol_large',
    fields:[
      { component:'section-header',                         label:'Emails de notification ',          size:'normal', bold:true },
      { component:'sl-input',                               label:'Adresse email du SOC',             name:'iora_ticket_soc_email' },
      { component:'section-header',                         label:'Nouveau ticket',                   size:'small', bold:true },
      
      { component:'select-validation-email-template',       label:'@ déclarant',                      name:'iora_ticket_declarant_new_templateId' },
      { component:'select-validation-email-template',       label:'@ superviseurs',                   name:'iora_ticket_supervisors_new_templateId' },

      { component:'section-header',                         label:'Mise à jour ticket',               size:'small', bold:true },
      { component:'select-validation-email-template',       label:'@ déclarant',                      name:'iora_ticket_declarant_update_templateId' },
      { component:'select-validation-email-template',       label:'@ superviseurs',                   name:'iora_ticket_supervisors_update_templateId' },

      { component:'section-header',                         label:'Incident terminé',                 size:'small', bold:true },
      { component:'select-validation-email-template',       label:'@ déclarant',                      name:'iora_ticket_declarant_pendingclose_templateId' },
      { component:'select-validation-email-template',       label:'@ superviseurs',                   name:'iora_ticket_supervisors_pendingclose_templateId' },

      { component:'section-header',                         label:'Clôture ticket',                   size:'small', bold:true },
      { component:'select-validation-email-template',       label:'@ déclarant',                      name:'iora_ticket_declarant_closed_templateId' },
      { component:'select-validation-email-template',       label:'@ superviseurs',                   name:'iora_ticket_supervisors_closed_templateId' },
    ]
  }

  const commonUserTabs = [
    {
      name:'contacts', 
      title:_tl('contacts'),
      css:'labol_large',
      fields:[
        { component:'section-header',         label:_tl('email_addresses'),         size:'small',                     bold:true  },
        { component:'sl-input',               label:_tl('email_primary'),           name:'email_primary',             type:'email' },
        { component:'sl-input',               label:_tl('email_secondary'),         name:'email_secondary',           type:'email' },

        { component:'section-header',         label:_tl('mobiles_phones'),          size:'small',                     bold:true  },
        { component:'sl-input',               label:_tl('mobile_primary'),          name:'mobile_primary',            type:'phone',  },
        { component:'sl-input',               label:_tl('mobile_secondary'),        name:'mobile_secondary',          type:'phone',  },

        { component:'section-header',         label:_tl('landlines'),               size:'small',                     bold:true  },
        { component:'sl-input',               label:_tl('landline_primary'),        name:'office_number_primary',     type:'phone'  },
        { component:'sl-input',               label:_tl('landline_secondary'),      name:'office_secondary_number',   type:'phone'  },
        
      ]      
    },
    {
      name:'pgp', 
      title:'PGP',
      fields:[
        { component:'sl-textarea',            label:_tl('pgp_primary_public_key'),  name:'pgp_primary_public_key',    rows:28 },
      ]
    }
  ]

  const commonStructureMonitoringTab = {
    name:'monitoring', 
    title:_tl('monitoring'),
    fields:[
      { component:'section-header',         label:_tl('monitoring_email_leaks'),         size:'small',                     bold:true  },
      { component:'sl-textarea',            label:_tl('monitoring_email_leaks_domains'), name:'email_domains_leaks',       help:_tl('monitoring_email_leaks_help'), rows:5 },
    ]      
  }

  const commonStructureContractTab = {
    name:'contract', 
    title:_tl('contract'),
    css:'labol_large',
    fields:[
      /*
      { component:'section-header',         label:_tl('assets'),                size:'small',                             bold:true  },
      { component:'sl-input',               label:_tl('assets_technical'),      name:'contract_assets_technical_count',   type:'number' },
      { component:'sl-input',               label:_tl('assets_human'),          name:'contract_assets_human_count',       type:'number' },
      */
      { component:'section-header',         label:_tl('contract_availability'), size:'small',                             variant:'bold'  },
      { component:'contract-sla',           label:_tl('contract_sla'),          name:'iora_contract_sla',                 style:'font-size:0.9em' },
    ]
  }

  const definitions = {
    root:{
      name:_tl('root'),
      micon:'home',
      isKcGroup:true,
      tabs:[
        {
          name:'general', 
          title:_tl('general'),
          fields:[
            { ...commonNodeTypeField },
            { component:'sl-input',           label:_tl('label'),                  name:'name' },
          ]
        },
        commonPermissions
      ]
    },
    folder:{
      name:_tl('folder'),
      micon:'folder',
      tabs:[
        {
          name:'general', 
          title:_tl('general'),
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { component:'sl-input',           label:_tl('folder_name'),           name:'name' },
          ]
        },
        { ...commonPermissions },
        { ...commonUserValidation }
      ]
    },
    structure: {
      name:_tl('structure'),
      micon:'apartment',
      isKcGroup:true,
      //validationSetup:true,
      tabs:[
        {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { component:'section-header',                     label:'Propriétés',                       size:'small',               bold: true  },
            { component:'sl-input',                           label:_tl('structure_name'),              name:'name' },
            { component:'select-structure-type',              label:_tl('structure_type'),              name:'type' },
            { component:'sl-textarea',                        label:_tl('address'),                     name:'address', },
            { component:'sl-input',                           label:_tl('code_siret'),                  name:'code_siret' },
            { component:'select-naf',                         label:_tl('code_naf'),                    name:'code_naf' },
            { component:'sl-textarea',                        label:_tl('notes'),                       name:'notes' },

            { component:'section-header',                     label:'Comptes utilisateurs & notification email', size:'small',      bold:true  },
            { component:'select-validation-email-template',   label:'Template création de compte',      name:'iora_account_created_templateId' },

            { component:'section-header',                     label:'Contacts principaux',              size:'small',               bold: true  },
            { component:'select-registry',                    label:'Contact opérationnel',             name:'iora_contact_soc',        help:'Notifié pour les mises à jours alertes / incidents et discussions' },
            { component:'select-registry',                    label:'Contact RSSI',                     name:'iora_contact_rssi',       help:'Pré-séléctionné si un incident a un impact sur la production' },
            { component:'select-registry',                    label:'Contact DSI',                      name:'iora_contact_dsi',        help:'Pré-séléctionné si un incident a un impact sur la production' },
            { component:'select-registry',                    label:'Contact DPO',                      name:'iora_contact_dpo',        help:'Pré-séléctionné si un incident a un impact sur les données à caractère personnel' },
          ]
        },
        commonStructureContractTab,
        commonPermissions,
        commonUserValidation,
        commonTickets,
        commonStructureMonitoringTab,
        {
          name:'images', 
          title:_tl('images'),
          fields:[
            { component:'section-header',     label:'Favicon',                    size:'small',       bold:true  },
            { component:'sl-input',           label:_tl('favicon_url'),           name:'favicon_url' },
          ]
        },
      ]
    },
    contact:{
      name:_tl('contact'),
      nodeTitle:['firstname', 'lastname'],
      micon:'person',
      noChilds:true,
      validationSetup:true,
      tabs:[
          {
          name:'identity', 
          title:_tl('identity'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { component:'select-contact-type',  label:_tl('account_type'),      name:'contact_type',      defaultValue:'contact' },
            { component:'select-gender',        label:_tl('gender'),            name:'gender',            defaultValue:'male' },
            { component:'sl-input',             label:_tl('firstname'),         name:'firstname' },
            { component:'sl-input',             label:_tl('lastname'),          name:'lastname' },
            { component:'select-jobs',          label:_tl('job'),               name:'job' ,              help:'* sert uniquement de repère visuel' }
          ]
        },
        { ...commonUserTabs[0] },
        { ...commonUserTabs[1] },
        commonUserValidation,
        { ...commonPermissions }
      ]
    },
    asset_domain_name:{
      name:_tl('domain_name'),
      subgroup:_tl('assets'),
      micon:'globe',
      noChilds:true,
      tabs:[
          {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { ...commonEnabledField },
            { component:'sl-input',           label:_tl('domain_name'),       name:'value' },
          ]
        }
      ]
    },
    asset_url:{
      name:'URL',
      subgroup:_tl('assets'),
      micon:'http',
      noChilds:true,
      tabs:[
          {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { ...commonEnabledField },
            { component:'sl-input',           label:_tl('url'),               name:'value' },
          ]
        }
      ]
    },
    asset_network_range:{
      name:_tl('network'),
      subgroup:_tl('assets'),
      micon:'lan',
      noChilds:true,
      tabs:[
          {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { ...commonEnabledField },
            { component:'sl-input',           label:_tl('network'),           name:'value', placeholder:'XXX.XXX.XXX.XXX/YY' },
          ]
        }
      ]
    },
    asset_network_ip_address:{
      name:_tl('ip_addr'),
      subgroup:_tl('assets'),
      micon:'page_control', // 
      noChilds:true,
      tabs:[
          {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { ...commonEnabledField },
            { component:'sl-input',           label:_tl('ip_addr'),           name:'value', placeholder:'IPv4/IPv6' },
          ]
        }
      ]
    },
    asset_network_as: {
      name:_tl('network_as'),
      subgroup:_tl('assets'), 
      micon:'network_node',
      noChilds:true,
      tabs:[
          {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { ...commonEnabledField },
            { component:'sl-input',           label:_tl('network_as'),        name:'value', placeholder:'' },
          ]
        }
      ]
    },
    asset_brand:{
      name:_tl('brand'),
      subgroup:_tl('assets'),
      micon:'brand_family',
      tabs:[
          {
          name:'general', 
          title:_tl('general'),
          css:'labol_medium',
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { component:'sl-input',           label:_tl('brand'),        name:'value', placeholder:'' },
          ]
        }
      ]
    },
    edr_harfang:{
      name:'HarfangLab',
      nodeTitle:['name'],
      micon:'sensors',
      subgroup:_tl('edr'),
      noChilds:true,
      tabs:[
        {
          name:'general', 
          title:_tl('general'),
          fields:[
            { ...commonNodeTypeField },
            { ...commonParentField },
            { component:'sl-switch',          label:_tl('enable'),            name:'enable' },
            { component:'sl-input',           label:'Nom',                    name:'name',        placeholder:'SocShield' },
            { component:'sl-input',           label:'URL',                    name:'url',         placeholder:'https://a1z2e3r4t5y6u7i8.hurukai.io:8443' },
            { component:'sl-input',           label:'Token',                  name:'token',       placeholder: 'x1z2e3r4t5y6u7i3o9q1s2d3f4g5h6j7k8l9m0w1' },
          ]
        },
      ]
    },
    trash:{
      name:null, // do not display in the menu
      micon:'folder_delete',
      isKcGroup:true,
      menuHide:true,
      displayOnlyIfHasChildren:true,
      displayChildrenCount:true
    }
  }


  for (const key in definitions) {
    definitions[key].tabs?.push({ ...commonDebugProperties })
  }

  return definitions;
}

export default { getNodeTypes };